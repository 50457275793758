/* eslint-disable react/prop-types */
import React from "react";
import { STATIC_URL } from "../Common/Common";
import "./MessageList.css";

function PhotoMessage({ conversation, handleImgClickOpen, isReplySource }) {
  const srcID = isReplySource ? conversation.replyid : conversation.messageid;

  return (
    <img
      src={STATIC_URL + `/downloadImage?messageID=${srcID}&size=min`}
      //height="300"
      alt=""
      className="chat-message-image"
      onClick={() => handleImgClickOpen()}
      //style={isCurrent ? { border: "1px solid white" } : null}
    />
  );
}

export default PhotoMessage;
