/* eslint-disable react/prop-types */
import React, { useMemo } from "react";
//import "./MessageList.css";
import StampMessage from "./StampMessage";
import TextMessage from "./TextMessage";
import VideoMessage from "./VideoMessage";
import PhotoMessage from "./PhotoMessage";
import FileMessage from "./FileMessage";
import ReplyMessage from "./ReplyMessage";

import { STATIC_URL } from "../Common/Common";
import ShowUserInfo from "../Common/ShowUserInfo";
import ShowImageInfo from "../Common/ShowImageInfo";
import Tooltip from "@material-ui/core/Tooltip";
import styled, { css } from "styled-components";
const StyledLeftRight = styled.div`
  ${(props) =>
    props.leftmessage
      ? css`
          border-style: solid;
          border-color: #c7c7d3;
          border-width: 3px;
          color: #000000;
          box-shadow: grey -1px 7px 13px -4px;
          &::before {
            content: "";
            border: 10px solid transparent;
            border-right-color: #c7c7d3;
            position: absolute;
            left: -20px;
          }
          &::after {
            content: "";
            border: 10px solid transparent;
            border-right-color: rgb(247, 248, 252);
            position: absolute;
            top: 38px;
            left: -17px;
          }
        `
      : css`
          border-style: solid;
          border-color: ${(props) => props.theme.palette.primary.main};
          border-width: 3px;
          color: #000000;
          box-shadow: grey -1px 7px 13px -4px;

          &::before {
            content: "";
            border: 10px solid transparent;
            border-left-color: ${(props) => props.theme.palette.primary.main};
            position: absolute;
            top: 13px;
            right: -20px;
          }
          &::after {
            content: "";
            border: 10px solid transparent;
            border-left-color: rgb(247, 248, 252);
            position: absolute;
            top: 13px;
            right: -17px;
          }
        `}
`;

function ReplyMessageOnDialog({
  conversation,
  setInitMessageid,
  setImgOpen,
  setVideoOpen,
  setOpenReplyid,
  setOpenReply,
}) {
  const [open, setOpen] = React.useState(false);
  const [imgopen, setInfoImgOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleImgClose = () => {
    setInfoImgOpen(false);
  };

  const handleVideoClickOpen = () => {
    setInitMessageid(conversation.messageid);
    setVideoOpen(true);
  };

  const handleImgClickOpen = () => {
    setInitMessageid(conversation.messageid);
    setImgOpen(true);
  };

  const me = localStorage.getItem("honbuid");
  const sent = conversation.honbuid === me;
  ////console.log("Message sent", sent);
  ////console.log("conversation", conversation);
  const MemoAvatar = useMemo(() => {
    console.log("test log4");
    return (
      <ShowUserInfo open={open} honbuid={conversation.honbuid} handleClose={handleClose} handleMeClose={() => {}} />
    );
  }, [open, conversation.honbuid]);

  return (
    <div className={`message-in-avatar-${sent ? "sent" : "received"}`}>
      {/* <ShowUserInfo open={open} honbuid={conversation.honbuid} handleClose={handleClose} /> */}
      {MemoAvatar}
      <ShowImageInfo open={imgopen} messageid={conversation.timelinecommentid} handleClose={handleImgClose} />
      {!sent && (
        <Tooltip title={conversation.name} aria-label={conversation.name}>
          <img
            src={
              STATIC_URL + `/downloadAvatar?honbuid=${conversation.honbuid}&fileno=${conversation.fileno}&size=small`
            }
            width="40"
            height="40"
            alt=""
            className="chat-avatar-image"
            onClick={() => handleClickOpen()}
          />
        </Tooltip>
      )}
      <div className={`message-options-wrapper-${sent ? "sent" : "received"}`}>
        {!sent && <div>{conversation.name}</div>}

        {conversation.messagetype === "stamp" ? (
          <StampMessage conversation={conversation} isReplySource={false}></StampMessage>
        ) : (
          <StyledLeftRight leftmessage={!sent} className={`message`}>
            {conversation.messagetype === "replymessage" ? (
              <ReplyMessage
                conversation={conversation}
                setOpenReplyid={setOpenReplyid}
                setOpenReply={setOpenReply}
                sent={sent}
              ></ReplyMessage>
            ) : conversation.messagetype === "message" ? (
              <TextMessage conversation={conversation} type="message"></TextMessage>
            ) : conversation.messagetype === "video" ? (
              <VideoMessage
                conversation={conversation}
                handleVideoClickOpen={handleVideoClickOpen}
                isReplySource={false}
              ></VideoMessage>
            ) : conversation.messagetype === "photo" ? (
              <PhotoMessage
                conversation={conversation}
                handleImgClickOpen={handleImgClickOpen}
                isReplySource={false}
              ></PhotoMessage>
            ) : conversation.messagetype === "file" ? (
              <FileMessage conversation={conversation} sent={sent} isReplySource={false}></FileMessage>
            ) : (
              <div>{conversation.message}</div>
            )}
          </StyledLeftRight>
        )}

        <div className={`message-options-time-${sent ? "sent" : "received"}`}>
          <div>{conversation.time}</div>
        </div>
      </div>
    </div>
  );
}
export default ReplyMessageOnDialog;
