/* eslint-disable react/prop-types */
import React from "react";
import "./MessageList.css";
import Linkify from "react-linkify";

function TextMessage({ conversation, type }) {
  return (
    <>
      {type === "replymessage" ? (
        <Linkify
          componentDecorator={(decoratedHref, decoratedText, key) => (
            <a target={`blank${conversation.messageid}`} href={decoratedHref} key={key}>
              {decoratedText}
            </a>
          )}
        >
          {conversation.replymessage}
        </Linkify>
      ) : type === "message" ? (
        <Linkify
          componentDecorator={(decoratedHref, decoratedText, key) => (
            <a target={`blank${conversation.messageid}`} href={decoratedHref} key={key}>
              {decoratedText}
            </a>
          )}
        >
          {conversation.message}
        </Linkify>
      ) : (
        <div>{conversation.message}</div>
      )}
    </>
  );
}

export default TextMessage;
