import * as firebase from "firebase/app";
import "firebase/messaging";

const initializedFirebaseApp = firebase.initializeApp({
  apiKey: "AIzaSyDqoimVRCwhAXgMaWPpqx2-0in-4OwxgNg",
  authDomain: "chatapp-16a64.firebaseapp.com",
  databaseURL: "https://chatapp-16a64.firebaseio.com",
  projectId: "chatapp-16a64",
  storageBucket: "chatapp-16a64.appspot.com",
  messagingSenderId: "126435111795",
  appId: "1:126435111795:web:3edc9cc5791f9fa6444b06",
  measurementId: "G-8D5CXRZH6M",
});
let messaging = null;
if (firebase.messaging.isSupported()) {
  messaging = initializedFirebaseApp.messaging();
  messaging.getToken({
    vapidKey: "BFSJGHPmlC46UkYhVX1R-KgA-e5LBRGyw-UeZh_wac4xNi8WZUJdM1kN9pf4wUCGFAxZOUFVdnG8ofWQW27p_70",
  });
}

export { messaging };
