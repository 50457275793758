/* eslint-disable react/prop-types */
import React, { useEffect } from "react";
import styled from "styled-components";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

const StyledRoot = styled.div`
  border: 1px solid;
  border-radius: 5px;
  padding: 5px;
  margin-top: 5px;
  margin-bottom: 10px;
`;
const StyledButtonDiv = styled.div`
  margin-top: 8px;
  margin-bottom: 8px;
`;

const StyledTopMarginDiv = styled.div`
  margin-top: 8px;
`;

function ShowAnpiVoteDetail({ anpidetail, inputNoValue, inputTextValue }) {
  useEffect(() => {
    //console.log("anpidetail2", anpidetail);
  }, []);

  return (
    <StyledTopMarginDiv>
      <Typography variant="h6" gutterBottom>
        {anpidetail.title}
      </Typography>

      <StyledRoot>
        {anpidetail.kbn != "text" ? (
          anpidetail.list.map((e, index) => {
            return (
              <StyledButtonDiv key={index}>
                <Button
                  variant="contained"
                  onClick={() => {
                    inputNoValue(anpidetail.seq, e.no, e.value);
                  }}
                  fullWidth
                  color={e.value == "1" ? "primary" : "default"}
                >
                  {e.text}
                </Button>
              </StyledButtonDiv>
            );
          })
        ) : (
          <TextField
            id="outlined-multiline-static"
            InputLabelProps={{
              shrink: true,
            }}
            multiline
            rows={3}
            variant="outlined"
            value={anpidetail.value}
            onChange={(ev) => inputTextValue(anpidetail.seq, ev.target.value)}
            margin="dense"
            style={{ width: "100%" }}
          />
        )}
      </StyledRoot>
    </StyledTopMarginDiv>
  );
}
export default ShowAnpiVoteDetail;
