/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
//import React from "react";
// eslint-disable-next-line react/prop-types
// function ShowCall({ open, handleClose, channel, uid, name, flag }) {
//   return <></>;
// }

import "./ShowUserInfo.css";
import React, { useEffect, useState, useRef } from "react";
import ReactDOM from "react-dom";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import { TOKEN_URL, APP_ID } from "../Common/Common";
import axios from "axios";
import ShowCommon from "../Common/ShowCommon";
import AgoraRTC from "agora-rtc-sdk-ng";
import styled from "styled-components";

const StyledDialogTitle = styled(DialogTitle)`
  color: ${(props) => props.theme.palette.primary.contrastText};
  background-color: ${(props) => props.theme.palette.primary.main};
`;
const StyledRoot = styled.div`
  width: 100%;
`;
const StyledButton = styled(Button)`
  color: ${(props) => props.theme.palette.primary.contrastText};
  background-color: ${(props) => props.theme.palette.primary.main};
`;
function ShowCall({ open, handleClose, channel, uid, name, flag }) {
  const [openModal, setOpenModal] = useState(false);
  const handleModalClose = () => {
    setOpenModal(false);
  };

  //const [joined, setJoined] = useState(false);

  const remoteRef = useRef("");
  const rtc = {
    // For the local client
    client: null,
    // For the local audio and video tracks
    localAudioTrack: null,
    localVideoTrack: null,
  };

  const options = {
    // Pass your app ID here
    appId: APP_ID,
    channel: channel,
    // Pass a token if your project enables the App Certificate
    token: null,
  };

  const initialize = async () => {
    rtc.client = AgoraRTC.createClient({ mode: "rtc", codec: "h264" });
    // Create an audio track from the audio captured by a microphone
    rtc.localAudioTrack = await AgoraRTC.createMicrophoneAudioTrack();
    // Create a video track from the video captured by a camera
    rtc.localVideoTrack = await AgoraRTC.createCameraVideoTrack();
    // Play localStream
    rtc.localVideoTrack.play("local-stream");
    rtc.client.on("user-published", async (user, mediaType) => {
      // Subscribe to a remote user
      await rtc.client.subscribe(user, mediaType);
      console.log("subscribe success");
      // console.log(user);

      if (mediaType === "video" || mediaType === "all") {
        // Get `RemoteVideoTrack` in the `user` object.
        const remoteVideoTrack = user.videoTrack;
        console.log(remoteVideoTrack);

        // Dynamically create a container in the form of a DIV element for playing the remote video track.
        const PlayerContainer = React.createElement("div", {
          id: user.uid,
          className: "stream",
        });
        ReactDOM.render(PlayerContainer, document.getElementById("remote-stream"));

        user.videoTrack.play(`${user.uid}`);
        console.log("subscribe uid", user.uid);
      }

      if (mediaType === "audio" || mediaType === "all") {
        // Get `RemoteAudioTrack` in the `user` object.
        const remoteAudioTrack = user.audioTrack;
        // Play the audio track. Do not need to pass any DOM element
        remoteAudioTrack.play();
      }
    });
    rtc.client.on("user-unpublished", (user) => {
      console.log("unsubscribe uid", user.uid);
      // Get the dynamically created DIV container
      const playerContainer = document.getElementById(user.uid);
      console.log(playerContainer);
      // Destroy the container
      if (playerContainer != null) {
        playerContainer.remove();
      }
    });
    const body = { channel: channel, uid: Number(uid), name: name, flag: flag };
    const result = await axios.post(TOKEN_URL, body);
    const status = result.status;
    if (status !== 200) {
      //setJoined(false);
      alert("通話に失敗しました。");
    } else {
      //setJoined(true);
      options.token = result.data.token;
      console.log("result.data", result.data);
      await rtc.client.join(options.appId, options.channel, options.token, Number(uid));
      await rtc.client.publish([rtc.localAudioTrack, rtc.localVideoTrack]);
    }
  };

  useEffect(() => {
    if (open) {
      initialize();
    }
    return () => {
      const finishproc = async () => {
        //console.log("showCallのリターン処理--開始");
        if (rtc.client == null) return;
        try {
          const localContainer = document.getElementById("local-stream");

          rtc.localAudioTrack.close();
          rtc.localVideoTrack.close();

          //setJoined(false);
          localContainer.textContent = "";

          // Traverse all remote users
          rtc.client.remoteUsers.forEach((user) => {
            // Destroy the dynamically created DIV container
            const playerContainer = document.getElementById(user.uid);
            playerContainer && playerContainer.remove();
          });

          // Leave the channel
          await rtc.client.leave();
        } catch (err) {
          console.error(err);
        }
      };
      finishproc();
      //console.log("showCallのリターン処理--終了");
    };
  }, [open]);

  return (
    <Dialog open={open} onClose={() => {}} fullWidth={true} maxWidth={"xl"}>
      <StyledDialogTitle id="max-width-dialog-title">
        <div className="show-member-search">
          <div className="show-member-search">通話画面</div>
          <div className="question-title-actions">
            <StyledButton onClick={() => setOpenModal(true)} variant="outlined">
              閉じる (Esc)
            </StyledButton>
          </div>
        </div>
      </StyledDialogTitle>

      <DialogContent>
        <StyledRoot>
          {/* <Paper className={classes.paper}></Paper> */}

          <div className="video-div">
            <div id="local-stream" className="stream local-stream"></div>
            <div id="remote-stream" ref={remoteRef} className="stream remote-stream"></div>
          </div>

          <ShowCommon
            open={openModal}
            title="終了しますか？"
            handleClose={handleModalClose}
            handleCallBack={() => {
              handleModalClose();
              handleClose();
            }}
          ></ShowCommon>
        </StyledRoot>
      </DialogContent>
    </Dialog>
  );
}

export default ShowCall;
