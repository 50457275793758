import React, { useContext, useState, useMemo } from "react";
import { Store } from "../Store/store";
import { STATIC_URL } from "../Common/Common";
import "./MessageList.css";
import ShowSearchConversation from "../Common/ShowSearchConversation";

// eslint-disable-next-line react/prop-types
function MessageSearchList() {
  const { state } = useContext(Store);

  const [searchOpen, setSearchOpen] = useState({ open: false, messageid: 0 });
  const handleImgClose = () => {
    setSearchOpen({ open: false, messageid: 0 });
  };

  const MemoSearch = useMemo(() => {
    return (
      <ShowSearchConversation
        open={searchOpen.open}
        messageid={searchOpen.messageid}
        roomid={state.currentRoomid}
        handleClose={handleImgClose}
      />
    );
  }, [searchOpen, state.currentRoomid]);

  return (
    <div className="search-message-list">
      {MemoSearch}
      {state.filteredMessages.map((e) => {
        return (
          <div
            key={e.messageid}
            className={`member-wrapper`}
            onClick={async () => {
              setSearchOpen({ open: true, messageid: e.messageid });
            }}
          >
            <div className="member-content">
              <img
                src={STATIC_URL + `/downloadAvatar?honbuid=${e.honbuid}&fileno=${e.fileno}&size=small`}
                width="30"
                height="30"
                alt=""
                className="member-image"
                //style={isCurrent ? { border: "1px solid white" } : null}
              />
              <div className="member-text-wrapper">
                <div className="member-name-wrapper">
                  <div className="">{e.name}</div>
                  <div className="search-message-time">{e.time}</div>
                </div>

                <div className="member-subname">{e.message}</div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default MessageSearchList;
