/* eslint-disable react/prop-types */
import "./ShowUserInfo.css";
import React, { useEffect, useState, useRef, useContext } from "react";
import useReactRouter from "use-react-router";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import { API_URL, custom_axios, custom_axios_post } from "../Common/Common";
import styled from "styled-components";
import MultiSelect from "react-multi-select-component";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import { Store } from "../Store/store";

const StyledDialogTitle = styled(DialogTitle)`
  color: ${(props) => props.theme.palette.primary.contrastText};
  background-color: ${(props) => props.theme.palette.primary.main};
  padding: 5px 10px;
`;
const StyledButton = styled(Button)`
  color: ${(props) => props.theme.palette.primary.contrastText};
  background-color: ${(props) => props.theme.palette.primary.main};
`;

function ShowBellDetail({ open, openBellId, handleClose, isEnabled, afterProc }) {
  const { history } = useReactRouter();

  const [title, setTitle] = useState("");
  const [textmessage, setTextMessage] = useState("");
  const [textlink, setTextLink] = useState("");
  const [ispopup, setIsPopup] = useState(false);
  const [ispush, setIsPush] = useState(false);

  const [options, setOptions] = useState([]);
  const [selectedhp, setSelectedHp] = useState([]);

  const processingRef = useRef(false);

  const { state } = useContext(Store);

  const mainproc = async () => {
    if (open) {
      let honbuid = localStorage.getItem("honbuid");

      var sqlstring = API_URL + `/GetBellDetail?honbuid=${honbuid}&bellid=${openBellId}`;
      const result = await custom_axios(sqlstring);
      const status = result.status;
      if (status === 401) {
        history.replace("/loginError");
      }
      if (status === 200) {
        setOptions(
          result.data.hps.map((e) => {
            return { label: e.hpname, value: e.hpcode };
          })
        );

        setTitle(result.data.title);
        setTextMessage(result.data.textmessage);
        setTextLink(result.data.link);
        setIsPopup(result.data.ispopup == "1" ? true : false);
        setIsPush(result.data.ispush == "1" ? true : false);
        if (isEnabled) {
          setSelectedHp([{ label: state.hpcode + ":" + state.hpname, value: state.hpcode }]);
        } else {
          setSelectedHp(result.data.selecthplist);
        }
      }
    }
  };
  useEffect(() => {
    if (open) {
      mainproc();
    }
  }, [open]);

  const handleSave = async () => {
    /* 多重クリック防止 */
    if (processingRef.current) return;
    processingRef.current = true;

    let hplist = selectedhp.map((v) => {
      return v.value;
    });

    const body = {
      //bellid: isEnabled ? openBellId : "",
      title: title,
      textmessage: textmessage,
      textlink: textlink,
      hplist: hplist, //Json型をそのまま投げる。
      ispopup: ispopup ? "1" : "0",
      ispush: ispush ? "1" : "0",
    };

    //console.log("post前", body);
    const result = await custom_axios_post(API_URL + "/updatebell", body);

    processingRef.current = false;

    afterProc();

    if (result.status === 401) {
      history.replace("/loginError");
    }
    handleClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth={"sm"} fullWidth>
      <StyledDialogTitle id="max-width-dialog-title">
        <div className="show-member-search">
          <div className="show-member-search">重要なお知らせ内容</div>
          <div className="question-title-actions">
            <StyledButton onClick={handleClose} variant="outlined">
              閉じる (Esc)
            </StyledButton>
          </div>
        </div>
      </StyledDialogTitle>

      <DialogContent>
        <div className="question-create-wrap">
          <div className="anpi-subarea">
            対象施設
            <div className="multi-select">
              <MultiSelect
                disabled={isEnabled ? false : true}
                options={options}
                value={selectedhp}
                onChange={(e) => {
                  setSelectedHp(e);
                }}
                labelledBy={"Select"}
                overrideStrings={{
                  selectSomeItems: "選択...",
                  allItemsAreSelected: "全選択",
                  selectAll: "全選択",
                  search: "検索",
                  clearSearch: "検索クリア",
                }}
                filterOptions={(options, filter) => {
                  if (!filter) {
                    return options;
                  }
                  const re = new RegExp(filter, "i");
                  return options.filter(({ label }) => label && label.match(re));
                }}
              />
            </div>
          </div>

          <TextField
            label="タイトル"
            variant="outlined"
            value={title}
            disabled={isEnabled ? false : true}
            onChange={(e) => {
              setTitle(e.target.value);
            }}
            style={{ margin: "8px", minWidth: "300px" }}
          />
          <TextField
            label="本文"
            variant="outlined"
            value={textmessage}
            multiline
            rows={4}
            disabled={isEnabled ? false : true}
            onChange={(e) => {
              setTextMessage(e.target.value);
            }}
            style={{ margin: "8px", minWidth: "300px" }}
          />
          <div className="sending-area-buttons">(文字:{textmessage.length} / 400)</div>
          {/* <TextField
            label="リンク"
            variant="outlined"
            value={textlink}
            disabled={isEnabled ? false : true}
            onChange={(e) => {
              setTextLink(e.target.value);
            }}
            style={{ margin: "8px", minWidth: "300px" }}
          /> */}

          <FormControlLabel
            control={
              <Checkbox
                checked={ispopup}
                disabled={isEnabled ? false : true}
                onChange={(e) => {
                  setIsPopup(e.target.checked);
                }}
                name="checkedB"
                color="primary"
              />
            }
            label="ポップアップ有り"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={ispush}
                disabled={isEnabled ? false : true}
                onChange={(e) => {
                  setIsPush(e.target.checked);
                }}
                name="checkedB"
                color="primary"
              />
            }
            label="プッシュ通知有り"
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          style={{ margin: "0 auto" }}
          variant="contained"
          color="primary"
          onClick={() => handleSave()}
          startIcon={<MailOutlineIcon />}
          disabled={isEnabled ? false : true}
        >
          送信
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ShowBellDetail;
