/* eslint-disable react/prop-types */
import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import styled from "styled-components";
import { STATIC_URL } from "../Common/Common";

const StyledDialogTitle = styled(DialogTitle)`
  color: ${(props) => props.theme.palette.primary.contrastText};
  background-color: ${(props) => props.theme.palette.primary.main};
`;
const StyledID = styled.div`
  font-weight: bold;
  font-size: medium;
`;

const StyledCenter = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
function ShowNewUser({ open, id, handleClose }) {
  return (
    <Dialog open={open} onClose={handleClose} maxWidth={"lg"}>
      <StyledDialogTitle id="max-width-dialog-title">ユーザー作成完了</StyledDialogTitle>

      <DialogContent>
        <StyledCenter>
          <img
            src={STATIC_URL + `/downloadTempAvatar`}
            width="150"
            height="150"
            alt=""
            className="show-user-image"
            //style={isCurrent ? { border: "1px solid white" } : null}
          />

          <StyledID>ID:{id}</StyledID>
          <div>で新規追加しました。</div>
          <div>※初期パスワードはIDと同じです。</div>
        </StyledCenter>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} variant="outlined" color="secondary">
          閉じる (Esc)
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ShowNewUser;
