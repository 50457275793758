/* eslint-disable react/prop-types */
import "./ShowUserInfo.css";
import { withStyles } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import useReactRouter from "use-react-router";
import MuiDialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import { API_URL, custom_axios } from "../Common/Common";
import TextField from "@material-ui/core/TextField";
import SaveOutlinedIcon from "@material-ui/icons/SaveOutlined";
import styled from "styled-components";

const Dialog = withStyles(() => ({
  paperWidthSm: {
    maxWidth: "700px",
  },
}))(MuiDialog);
const StyledDialogTitle = styled(DialogTitle)`
  color: ${(props) => props.theme.palette.primary.contrastText};
  background-color: ${(props) => props.theme.palette.primary.main};
`;
const StyledDialogContent = styled(DialogContent)`
  padding: 0px;
  overflow-y: hidden;
`;

function ShowChangeName({ open, handleClose, afterSaveNameProc, honbuid, username }) {
  const { history } = useReactRouter();
  const [name, setName] = useState("");

  const handleSave = async () => {
    let urlstr = API_URL + `/changeAccount?honbuid=${honbuid}&type=name&text=${name}`;
    const result = await custom_axios(urlstr);
    const status = result.status;

    if (status === 401) {
      history.replace("/loginError");
    }
    if (result.status === 200) {
      setName("");
      afterSaveNameProc(honbuid, name);
      handleClose(); /* 自身も閉じる */
    }
  };
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    //let ret = false;
    let okCnt = 0;

    if (name && name.length > 0) {
      okCnt++;
    }
    if (name && name != username) {
      okCnt++;
    }

    /* 必須入力箇所（3個）が入力できた場合は登録ボタンを押せるようにする */
    setIsVisible(okCnt == 2);
  }, [name]);

  useEffect(() => {
    //console.log("GetUser前", honbuid);
    const proc = async () => {
      setName(username);
    };
    proc();
  }, [open]);

  return (
    <Dialog open={open} onClose={handleClose}>
      <StyledDialogTitle id="max-width-dialog-title">
        <div className="show-member-search">
          <div className="show-member-search">名前変更</div>
        </div>
      </StyledDialogTitle>

      <StyledDialogContent>
        <div className="question-create-wrap">
          <TextField
            label="名前"
            value={name}
            id="outlined-size-normal"
            variant="outlined"
            onChange={(e) => {
              setName(e.target.value);
            }}
            style={{ margin: "8px", width: "200px" }}
          />
        </div>
      </StyledDialogContent>

      <DialogActions>
        <Button
          disabled={isVisible ? false : true}
          variant="contained"
          color="primary"
          onClick={() => handleSave()}
          startIcon={<SaveOutlinedIcon />}
        >
          修正
        </Button>
        <Button onClick={handleClose} variant="outlined" color="secondary">
          閉じる (Esc)
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ShowChangeName;
