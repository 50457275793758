/* eslint-disable react/prop-types */
import "./ShowUserInfo.css";
import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/styles";
import { createTheme } from "@material-ui/core/styles";

import { API_URL, custom_axios } from "../Common/Common";
import useReactRouter from "use-react-router";
//import { Store } from "../Store/store";
import Bell from "../Bell/Bell";
import Slide from "@material-ui/core/Slide";
import { useInView } from "react-intersection-observer";
import styled from "styled-components";

// <Dialog>の中身のcssを直接いじる
const theme = createTheme({
  overrides: {
    MuiDialog: {
      paperFullScreen: { width: "80%" },
      container: { "margin-left": "-11.1%" },
    },
  },
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const StyledGridListroot = styled.div`
  overflow-y: auto;
  height: calc(100vh - 47px);
`;
const StyledDialogTitle = styled(DialogTitle)`
  color: ${(props) => props.theme.palette.primary.contrastText};
  background-color: ${(props) => props.theme.palette.primary.main};
  padding: 5px 10px;
`;
const StyledDialogContent = styled(DialogContent)`
  padding: 0px;
`;
const StyledButton = styled(Button)`
  color: ${(props) => props.theme.palette.primary.contrastText};
`;

function ShowBell({ open, handleClose }) {
  const { history } = useReactRouter();
  //const { state } = useContext(Store);

  const [localList, setLocalList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [ref, inView] = useInView({
    rootMargin: "0px 0px",
  });

  const mainproc = async () => {
    let honbuid = localStorage.getItem("honbuid");
    const result = await custom_axios(API_URL + `/GetNotificationList?honbuid=${honbuid}`);
    const status = result.status;
    if (status === 401) {
      history.replace("/loginError");
    } else if (status === 200) {
      setLocalList(result.data.list);
    }
  };

  const addproc = async () => {
    if (localList.length > 1) {
      var lastid = localList[localList.length - 1].noticeid;
      let honbuid = localStorage.getItem("honbuid");
      //console.log("addproc lastid", localList);
      const result = await custom_axios(API_URL + `/GetNotificationList?honbuid=${honbuid}&noticeid=${lastid}`);
      const status = result.status;
      //console.log("addproc", status);
      if (status === 401) {
        history.replace("/loginError");
      } else if (status === 200) {
        setLocalList([...localList, ...result.data.list]);
      }
    }
  };

  useEffect(() => {
    setLoading(true);
    if (open) {
      mainproc();
    }
    setLoading(false);
  }, [open]);
  useEffect(() => {
    //console.log("inView", inView);
    if (inView && loading == false) {
      setLoading(true);
      addproc();
      setLoading(false);
    }
  }, [inView]);

  return (
    <MuiThemeProvider theme={theme}>
      <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
        <StyledDialogTitle id="max-width-dialog-title">
          <div className="show-member-search">
            <div className="show-member-search">お知らせ</div>
            <div className="question-title-actions">
              <StyledButton onClick={handleClose} variant="outlined">
                閉じる (Esc)
              </StyledButton>
            </div>
          </div>
        </StyledDialogTitle>
        <StyledDialogContent>
          {loading ? (
            <StyledGridListroot>
              {[...Array(20)].map((_, i) => {
                return (
                  <div key={i} className="">
                    <Bell kbn={""} message={""} link={""} time={""} isskeleton={loading} />
                  </div>
                );
              })}
            </StyledGridListroot>
          ) : (
            <StyledGridListroot>
              {localList.map((e) => {
                return (
                  <div key={e.noticeid} className="">
                    <Bell
                      //noticeid={e.noticeid}
                      kbn={e.kbn}
                      message={e.message}
                      link={e.link}
                      time={e.time}
                      isskeleton={loading}
                    />
                  </div>
                );
              })}
              <div ref={ref}></div>
            </StyledGridListroot>
          )}
        </StyledDialogContent>
      </Dialog>
    </MuiThemeProvider>
  );
}

export default ShowBell;
