const registerServiceWorker = () => {
  if ("serviceWorker" in navigator) {
    navigator.serviceWorker
      .register("./firebase-messaging-sw.js")
      .then(function (registration) {
        registration.onupdatefound = function () {
          //console.log("アップデートがあります！");
          registration.update();
        };
        //console.log("Registration successful, scope is:", registration.scope);
      })
      .catch(function () {
        //console.log("Service worker registration failed, error:", err);
      });
  }
};

const unregisterServiceWorker = () => {
  //console.log("serviceWorkderの削除処理前");
  if ("serviceWorker" in navigator) {
    navigator.serviceWorker.getRegistrations().then((registrations) => {
      for (let i = 0; i < registrations.length; i++) {
        if (registrations[i]) {
          //console.log(registrations[i]);
          registrations[i].unregister();
          //console.log("serviceWorkder削除！");
        }
      }
    });
  }
};

export { registerServiceWorker, unregisterServiceWorker };
