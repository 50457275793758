/* eslint-disable react/prop-types */
import "./News.css";
import React, { useContext } from "react";
import { Store } from "../Store/store";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import IconButton from "@material-ui/core/IconButton";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import ShowMoreText from "react-show-more-text";
import { STATIC_URL } from "../Common/Common";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ShowCommon from "../Common/ShowCommon";
//import Link from "@material-ui/core/Link";
//import Icon from "@material-ui/core";
import styled from "styled-components";
import Linkify from "react-linkify";

// eslint-disable-next-line react/prop-types
const StyledCard = styled(Card)`
  width: 400px;
`;
const StyledMediaDiv = styled.div`
  height: 200px;
  width: 330px;
`;
const StyledCardMedia = styled(CardMedia)`
  object-fit: cover;
  height: 100%;
  width: 100%;
  cursor: pointer;
`;

export default function News({
  //isskeleton,
  timelineid,
  //honbuid,
  //name,
  title,
  message,
  time,
  //fileno,
  filecnt,
  //openhps,
  //url,
  callback,
  setInitTimelineid,
  setMaxFileid,
  setImgOpen,
}) {
  const { state } = useContext(Store);

  const [openModal, setOpenModal] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  //const { history } = useReactRouter();

  const executeOnClick = (ex) => {
    console.log(ex);
  };
  const handleMenuView = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  // const preventDefault = (event) => {
  //   event.preventDefault();
  //   window.open(url, "_blank");
  // };
  return (
    <div>
      <StyledCard>
        <CardHeader
          action={
            ["1", "2"].includes(state.isadmin) && (
              <IconButton aria-label="settings" onClick={handleMenuView}>
                <MoreVertIcon />
              </IconButton>
            )
          }
          title={title}
          subheader={time}
        />
        {filecnt > 0 ? (
          <StyledMediaDiv>
            <StyledCardMedia
              //className={classes.media}
              image={STATIC_URL + `/downloadTimeline?timelineid=${timelineid.toString()}&fileid=0&size=small`}
              title="Paella dish"
              onClick={() => {
                setInitTimelineid(timelineid.toString());
                setMaxFileid(Number(filecnt) - 1);
                setImgOpen(true);
              }}
            />
          </StyledMediaDiv>
        ) : (
          <StyledMediaDiv>
            <StyledCardMedia
              //className={classes.media}
              image={STATIC_URL + `/downloadTimelineMaster?size=small`}
              title="Paella dish"
              onClick={() => {
                setInitTimelineid(null);
                setMaxFileid(null);
                setImgOpen(true);
              }}
            />
          </StyledMediaDiv>
        )}

        <CardContent>
          <div>
            <ShowMoreText
              lines={3}
              more="もっと見る"
              less="閉じる"
              className="content-css"
              anchorClass="my-anchor-css-class"
              onClick={executeOnClick}
              expanded={false}
              width={0}
            >
              <Linkify
                componentDecorator={(decoratedHref, decoratedText, key) => (
                  <a target={`blank${timelineid.toString()}`} href={decoratedHref} key={key}>
                    {decoratedText}
                  </a>
                )}
              >
                {message}
              </Linkify>
            </ShowMoreText>
            {/* {url != "" ? <Link onClick={preventDefault}>リンク先へ飛ぶ</Link> : <div></div>} */}
          </div>
        </CardContent>
      </StyledCard>

      <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleMenuClose}>
        <MenuItem
          onClick={() => {
            setOpenModal(true);
            handleMenuClose();
          }}
        >
          削除する
        </MenuItem>
      </Menu>

      <ShowCommon
        open={openModal}
        title="削除しますか？"
        handleClose={() => setOpenModal(false)}
        handleCallBack={() => callback(timelineid)}
      ></ShowCommon>
    </div>
  );
}
