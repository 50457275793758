/* eslint-disable react/display-name */
/* eslint-disable react/prop-types */
import "./ShowUserInfo.css";
import React, { useEffect, useState, useMemo, useContext } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import { API_URL, custom_axios } from "../Common/Common";
import useReactRouter from "use-react-router";
import { Store } from "../Store/store";
import Slide from "@material-ui/core/Slide";
import styled from "styled-components";
import ShowReservationCreate from "../Common/ShowReservationCreate";
import IconButton from "@material-ui/core/IconButton";
import SettingsIcon from "@material-ui/icons/Settings";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ShowCommon from "../Common/ShowCommon";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const StyledDialogContent = styled(DialogContent)`
  padding: 0px;
`;
const StyledDialogTitle = styled(DialogTitle)`
  color: ${(props) => props.theme.palette.primary.contrastText};
  background-color: ${(props) => props.theme.palette.primary.main};
  padding: 5px 10px;
`;
const StyledButton = styled(Button)`
  color: ${(props) => props.theme.palette.primary.contrastText};
  background-color: ${(props) => props.theme.palette.primary.main};
`;

const StyledRootDiv = styled.div`
  min-height: 100px;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-flow: column;
`;
const StyledMarginDiv = styled.div`
  margin-right: 50px;
`;

const StyledRowDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 10px;
`;

function ShowReservationList({ open, handleClose }) {
  const { history } = useReactRouter();
  const { state } = useContext(Store);

  const [selectedid, setSelectedid] = useState({ open: false, rs: "" });
  const [reservationid, setReservationid] = useState(null);
  const [localList, setLocalList] = useState([]);

  const mainproc = async () => {
    let honbuid = localStorage.getItem("honbuid");
    if (open) {
      setSelectedid({ open: false, rs: "" });

      const result = await custom_axios(
        API_URL + `/GetReservationList?honbuid=${honbuid}&roomid=${state.currentRoomid}`
      );
      const status = result.status;

      if (status === 200) {
        setLocalList(result.data.list);
      } else if (status === 401) {
        history.replace("/loginError");
      } else {
        setLocalList([]);
      }
    }
  };

  useEffect(() => {
    mainproc();
  }, [open]);

  const handleCreateClose = () => {
    setSelectedid({ open: false, rs: "" });
  };

  const getLastMessage = (list) => {
    return list.sort(function (a, b) {
      if (b.messageid == "0") {
        return -1;
      }
      if (a.messageid == "0") {
        return 1;
      }
      if (Number(a.messageid) > Number(b.messageid)) {
        return 1;
      }
      if (Number(a.messageid) < Number(b.messageid)) {
        return -1;
      }
      return 0;
    })[list.length - 1].message;
  };

  const MemoCreate = useMemo(() => {
    return (
      <ShowReservationCreate
        open={selectedid.open}
        openrs={selectedid.rs}
        handleClose={handleCreateClose}
        afterProc={mainproc}
      />
    );
  }, [selectedid]);
  const [anchordelEl, setAnchordelEl] = useState(null);

  const handleMenuClose = () => {
    setAnchordelEl(null);
  };
  const [openCommon, setOpenCommon] = useState(false);
  const handleCommonClose = () => {
    setOpenCommon(false);
  };
  const delmessage = async (resid) => {
    setLocalList(localList.filter((f) => f.reservationid != resid));

    var url = API_URL + `/delReservation?reservationid=${resid}`;
    //console.log("urll", url);
    const result = await custom_axios(url);
    const status = result.status;
    if (status === 401) {
      history.replace("/loginError");
    }
  };
  const MemoCommon = useMemo(() => {
    return (
      <ShowCommon
        open={openCommon}
        handleClose={handleCommonClose}
        title="削除しますか？"
        handleCallBack={() => {
          delmessage(reservationid);
          handleCommonClose();
        }}
      ></ShowCommon>
    );
  }, [openCommon, reservationid]);

  const settingClick = (event, e) => {
    setReservationid(e);
    setAnchordelEl(event.currentTarget);
    event.stopPropagation();
  };

  const gettime = (t) => {
    return t.substring(0, 16);
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth={"md"} TransitionComponent={Transition}>
      <StyledDialogTitle id="max-width-dialog-title">
        <div className="show-member-search">
          <StyledMarginDiv>予約メッセージ一覧</StyledMarginDiv>
          <div className="question-title-actions">
            {/* {createOk && ( */}
            <StyledButton
              onClick={async () => {
                let honbuid = localStorage.getItem("honbuid");
                const result = await custom_axios(
                  API_URL + `/getReservationid?roomid=${state.currentRoomid}&honbuid=${honbuid}`
                );
                const status = result.status;
                if (status === 200) {
                  var id = result.data.id;
                  const rs = {
                    time: "",
                    reservationid: id,
                  };
                  setSelectedid({ open: true, rs: rs });
                }
              }}
              variant="outlined"
            >
              作成
            </StyledButton>
            {/* )} */}
            <div style={{ width: "20px" }}></div>
            <StyledButton onClick={handleClose} variant="outlined">
              閉じる (Esc)
            </StyledButton>
          </div>
        </div>
      </StyledDialogTitle>

      <StyledDialogContent>
        {MemoCreate}
        {MemoCommon}
        {localList.length > 0 ? (
          localList.map((e) => {
            return (
              <div
                key={e.reservationid}
                className="read-member-wrapper"
                onClick={() => setSelectedid({ open: true, rs: e })}
              >
                <StyledRowDiv>
                  <div className="">
                    <div className="show-read-member-name">
                      {gettime(e.time)}
                      に送信予定
                    </div>
                    <div className="show-read-member-name">{getLastMessage(e.conversationlist)}</div>
                  </div>
                  <div style={{ marginRight: "5px" }}>
                    <IconButton
                      aria-label="setting"
                      component="span"
                      onClick={(event) => settingClick(event, e.reservationid)}
                    >
                      <SettingsIcon />
                    </IconButton>
                  </div>
                </StyledRowDiv>
              </div>
            );
          })
        ) : (
          <StyledRootDiv>
            <div>予約メッセージはありません。</div>
            <div>指定時刻に送信されるメッセージを作成する場合は</div>
            <div>「作成」ボタンより、予約メッセージを作成してください。</div>
          </StyledRootDiv>
        )}

        <Menu id="simple-menu" anchorEl={anchordelEl} keepMounted open={Boolean(anchordelEl)} onClose={handleMenuClose}>
          <MenuItem
            onClick={() => {
              setOpenCommon(true);
              handleMenuClose();
            }}
          >
            削除
          </MenuItem>
        </Menu>
      </StyledDialogContent>
    </Dialog>
  );
}

export default ShowReservationList;
