import React, { useContext, useState, useEffect, useMemo } from "react";
import "./ChatRooms.css";
import Chip from "@material-ui/core/Chip";
import { Store } from "../Store/store";
import { STATIC_URL } from "../Common/Common";
import ShowMember from "../Common/ShowMember";
import ShowCallMini from "../Common/ShowCallMini";
// import ShowCall from "../Common/ShowCall";
import Search from "@material-ui/icons/Search";
import { Tooltip } from "@material-ui/core";
import Badge from "@material-ui/core/Badge";
import NotificationsOffIcon from "@material-ui/icons/NotificationsOff";
import { withStyles } from "@material-ui/core/styles";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import GroupIcon from "@material-ui/icons/Group";
import PersonIcon from "@material-ui/icons/Person";
import { RiPushpin2Line } from "react-icons/ri";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";
import styled from "styled-components";
const Accordion = withStyles({
  root: {
    border: "1px solid rgba(0, 0, 0, .125)",
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles((theme) => ({
  root: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    borderBottom: "1px solid rgba(0, 0, 0, .125)",
    marginBottom: -1,
    padding: "0px 5px",
    minHeight: 30,
    "&$expanded": {
      minHeight: 30,
    },
  },
  content: {
    margin: "8px 0",
    "&$expanded": {
      margin: "8px 0",
    },
  },
  expanded: {},
}))(MuiAccordionSummary);

const AccordionDetails = withStyles(() => ({
  root: {
    padding: "0px",
    // padding: theme.spacing(2),
  },
}))(MuiAccordionDetails);

const StyledSearch = styled(Search)`
  color: ${(props) => props.theme.palette.primary[300]};
`;
const StyledChip = styled(Chip)`
  margin-right: 5px;
  height: 15px;
  font-size: 8px;
`;
const StyledFlex = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
`;

const StyledWrapper = styled.div`
  display: flex;
  /* flex-direction: column;
  justify-content: center; */
  margin-top: 2px;
  background: ${(props) => (props.bigfont ? props.theme.palette.primary[100] : "#f7f8fc")};
  cursor: pointer;
  -webkit-transition: width 0.3s;
  transition: width 0.3s;
  width: 250px;
  color: #9d9d9d;
  position: relative;
  z-index: 0;
  //font-weight: ${(props) => (props.bigfont ? "700" : "normal")};
  & .chat-name {
    font-weight: ${(props) => (props.bigfont ? 700 : "normal")};
  }
  &:hover {
    background-color: ${(props) => props.theme.palette.primary[100]};

    & .chat-name {
      font-weight: 700;
    }
    & .chat-image {
      border: 1px solid white;
    }
  }
`;

const StyledFlexibleDiv = styled.div`
  display: flex;
`;

//import Icon from "@material-ui/core";
function ChatRooms() {
  const { state, dispatch } = useContext(Store);
  const [username, setUsername] = useState("");
  // 人名検索
  const [chatsFilter, setChatsFilter] = useState("");
  const [localChatrooms, setLocalChatrooms] = useState([]);
  const handleChange = (e) => {
    setChatsFilter(e.target.value);
  };
  const [openFa, setOpenFa] = useState(false);

  const handleFaClose = () => {
    setOpenFa(false);
  };

  const [openCallMini, setOpenCallMini] = useState(false);
  const handleCallMiniBack = () => {
    setOpenCallMini(false);
  };

  const handleCallMiniClose = () => {
    setOpenCallMini(false);
  };

  // useEffect(() => {
  //   console.log("showcall", state, state.callroomid, state.callname);
  //   if (state.callroomid != undefined && state.callroomid !== "" && state.callhonbuid != state.honbuid) {
  //     setOpenCallMini(true);
  //   }
  // }, [state.callroomid, state.callname, state.callhonbuid]);
  useEffect(() => {
    // dispatch({
    //   type: "UPDATE_ISAROUNDBUTTOMSCROLL",
    //   payload: { changeVal: state.isAroundButtomScroll+1 },
    // });
    // console.log("chatsFilterの値", chatsFilter);
    // 人名検索ボックス入力有無
    if (chatsFilter !== "") {
      // 人名検索
      setUsername(state.username);
      const updateList = state.chatrooms.filter((item) => {
        if (item.ishide == "1") {
          return false;
        }
        if (chatsFilter && String(item.roomname).toLowerCase().indexOf(chatsFilter) !== -1) {
          return true;
        }
        if (chatsFilter) {
          const updateListdetail = item.memberlist.filter((item) => {
            if (String(item.name).toLowerCase().indexOf(chatsFilter) !== -1) {
              return true;
            }
            if (String(item.subname).toLowerCase().indexOf(chatsFilter) !== -1) {
              return true;
            }
            return false;
          });
          if (updateListdetail.length > 0) {
            return true;
          } else {
            return false;
          }
        }
        return false;
      });
      setLocalChatrooms(updateList);
    } else {
      // スター（ピン）、最終送信時間
      // console.log("sortした");
      const updateList = state.chatrooms.filter((item) => {
        if (item.ishide == "1") {
          return false;
        }
        return true;
      });
      setLocalChatrooms(
        updateList.sort(function (a, b) {
          if (a.isstar < b.isstar) {
            return 1;
          }
          if (a.isstar > b.isstar) {
            return -1;
          }
          if (a.latestsending < b.latestsending) {
            return 1;
          }
          if (a.latestsending > b.latestsending) {
            return -1;
          }
          return 0;
        })
      );
    }
    let sum = 0;
    state.chatrooms.forEach((e) => {
      if (e.ishide == "0") {
        sum += Number(e.unreadcnt);
      }
      sum += Number(e.unvotecnt);
    });
    if (sum > 0) {
      document.title = "Chatis ＊";
    } else {
      if (state.isActive) {
        document.title = "Chatis";
      }
      /* if (typeof document.hidden !== "undefined") {
        // Opera 12.10 や Firefox 18 以降でサポート
        console.log("document.hidden", document.hidden);
        if (!document.hidden) document.title = "Chatis";
      } else if (typeof document.msHidden !== "undefined") {
        if (!document.msHidden) document.title = "Chatis";
        console.log("document.msHidden", document.msHidden);
      } else if (typeof document.webkitHidden !== "undefined") {
        if (!document.webkitHidden) document.title = "Chatis";
        console.log("document.webkitHidden", document.webkitHidden);
      } */
    }
  }, [chatsFilter, state]);

  const getRoomName = (chatroom) => {
    //console.log("chatroomの名前", chatroom.roomname);
    if (chatroom.roomname !== "") {
      const solonum = chatroom.memberlist.length + 1;
      return chatroom.roomname + (solonum <= 2 ? "" : "(" + String(solonum) + ")");
    } else {
      //console.log("空っぽの場合", chatroom.memberlist);
      let roomname = chatroom.memberlist.length > 2 ? username : "";
      for (let i = 0; i < chatroom.memberlist.length; i++) {
        roomname = roomname + chatroom.memberlist[i].name + ",";
        //console.log("chatroom.memberlist[i].name", chatroom.memberlist[i].name);
      }
      roomname = roomname.slice(0, -1);
      const groupnum = chatroom.memberlist.length + 1;
      return roomname + (groupnum === 2 ? "" : "(" + String(groupnum) + ")");
    }
  };

  const chatMemberToolTipText = (chatroom, isOneOnOne) => {
    if (isOneOnOne) {
      return (
        <div style={{ whiteSpace: "pre-line" }}>
          {`${getRoomName(chatroom)}(${chatroom.memberlist.length > 0 ? chatroom.memberlist[0].subname : ""})\n${
            chatroom.latestmessage
          }`}
        </div>
      );
    } else {
      return <div style={{ whiteSpace: "pre-line" }}>{`${getRoomName(chatroom)}\n${chatroom.latestmessage}`}</div>;
    }
  };

  const [groupexpanded, setGroupExpanded] = React.useState(true);
  const [expanded, setExpanded] = React.useState(true);
  const MemoMember = useMemo(() => {
    return <ShowMember open={openFa} handleClose={handleFaClose} isadduser={false} roomid="" />;
  }, [openFa]);

  const MemoCallMini = useMemo(() => {
    return (
      <ShowCallMini
        open={openCallMini}
        name={state.callname}
        handleClose={handleCallMiniClose}
        handleCallBack={handleCallMiniBack}
      />
    );
  }, [openCallMini, state.callname]);
  return (
    <div>
      <div className="chat-search">
        <div className="chat-search-icon">
          <StyledSearch />
        </div>
        <input
          placeholder="個人検索"
          value={chatsFilter}
          name="chatsFilter"
          onChange={handleChange}
          className="chat-search-field"
        />
      </div>

      <div className="chats-list">
        <Accordion square expanded={groupexpanded} onChange={() => setGroupExpanded(!groupexpanded)}>
          <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
            <div className="ac-group-between">
              <div className="ac-group">
                <GroupIcon></GroupIcon>
                <div>グループ</div>
              </div>
              {groupexpanded ? <FaAngleUp /> : <FaAngleDown />}
            </div>
          </AccordionSummary>

          {localChatrooms &&
            localChatrooms.length > 0 &&
            localChatrooms
              .filter((f) => f.memberlist.length > 1 || f.isofficial == 1)
              .map((e) => {
                return (
                  <AccordionDetails key={e.roomid}>
                    <Tooltip
                      title={chatMemberToolTipText(e, false)}
                      enterDelay={3000}
                      placement="right"
                      arrow
                      interactive
                    >
                      <StyledWrapper
                        key={e.roomid}
                        bigfont={state.currentRoomid === e.roomid}
                        // className={`chat-wrapper${state.currentRoomid === e.roomid ? "-user" : ""}`}
                        //className={state.currentRoomid === e.roomid ? classes.chatWrapperUser : classes.chatWrapper}
                        onClick={() => {
                          //let currentroomid = localStorage.getItem("currentRoomid");
                          if (e.roomid == state.currentRoomid) {
                            dispatch({ type: "CHANGE_ROOM", payload: { to: "", from: "" } });
                            //localStorage.setItem("currentRoomid", e.roomid);
                          } else {
                            dispatch({ type: "CHANGE_ROOM", payload: { to: e.roomid, from: state.currentRoomid } });
                            localStorage.setItem("currentRoomid", e.roomid);
                          }
                          // dispatch({ type: "CHANGE_ROOM", payload: { to: e.roomid, from: state.currentRoomid } });
                          // localStorage.setItem("currentRoomid", e.roomid);
                        }}
                      >
                        <img
                          src={
                            STATIC_URL +
                            (e.memberlist.length > 1 || e.isofficial == 1
                              ? `/downloadRoomAvatar?roomid=${e.roomid}&fileno=${e.fileno}`
                              : `/downloadAvatar?honbuid=${e.memberlist[0].honbuid}&size=small`)
                          }
                          width="32"
                          height="32"
                          alt=""
                          className="chat-image"
                          //style={isCurrent ? { border: "1px solid white" } : null}
                        />

                        <div className="chat-name-wrapper">
                          <div className="chat-name">
                            <StyledFlex>
                              {e.isofficial == "1" && (
                                <StyledChip
                                  variant="outlined"
                                  color="primary"
                                  size="small"
                                  label="人事連携"
                                ></StyledChip>
                              )}
                              {e.isdirection == "1" && (
                                <StyledChip variant="outlined" size="small" label="一方向"></StyledChip>
                              )}
                              {getRoomName(e)}
                            </StyledFlex>
                          </div>

                          <Badge
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "right",
                            }}
                            color="secondary"
                            badgeContent={Number(e.unreadcnt) + Number(e.unvotecnt)}
                            invisible={Number(e.unreadcnt) + Number(e.unvotecnt) === 0}
                          ></Badge>

                          {/* <div className="chat-last-message">{e.latestmessage}</div> */}
                        </div>

                        {e.ispush == "0" && (
                          <div className="pinicon-wrapper">
                            <NotificationsOffIcon></NotificationsOffIcon>
                          </div>
                        )}

                        {e.isstar == "1" && (
                          <div className="pinicon-wrapper">
                            <RiPushpin2Line></RiPushpin2Line>
                          </div>
                        )}
                      </StyledWrapper>
                    </Tooltip>
                  </AccordionDetails>
                );
              })}
        </Accordion>

        <Accordion square expanded={expanded} onChange={() => setExpanded(!expanded)}>
          <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
            <div className="ac-group-between">
              <div className="ac-group">
                <PersonIcon></PersonIcon>
                <div>個人</div>
              </div>
              {expanded ? <FaAngleUp /> : <FaAngleDown />}
            </div>
          </AccordionSummary>

          {localChatrooms &&
            localChatrooms.length > 0 &&
            localChatrooms
              .filter((f) => f.memberlist.length <= 1 && f.isofficial == 0 && f.ismyroom == 0)
              .map((e) => {
                return (
                  <AccordionDetails key={e.roomid}>
                    <Tooltip
                      title={chatMemberToolTipText(e, true)}
                      enterDelay={3000}
                      placement="right"
                      arrow
                      interactive
                    >
                      <StyledWrapper
                        key={e.roomid}
                        bigfont={state.currentRoomid === e.roomid}
                        // className={`chat-wrapper${state.currentRoomid === e.roomid ? "-user" : ""}`}
                        //className={state.currentRoomid === e.roomid ? classes.chatWrapperUser : classes.chatWrapper}
                        onClick={() => {
                          //let currentroomid = localStorage.getItem("currentRoomid");
                          if (e.roomid == state.currentRoomid) {
                            dispatch({ type: "CHANGE_ROOM", payload: { to: "", from: "" } });
                            //localStorage.setItem("currentRoomid", e.roomid);
                          } else {
                            dispatch({ type: "CHANGE_ROOM", payload: { to: e.roomid, from: state.currentRoomid } });
                            localStorage.setItem("currentRoomid", e.roomid);
                          }
                          // dispatch({ type: "CHANGE_ROOM", payload: { to: e.roomid, from: state.currentRoomid } });
                          // localStorage.setItem("currentRoomid", e.roomid);
                        }}
                      >
                        <img
                          src={
                            STATIC_URL +
                            (e.memberlist.length > 1 || e.isofficial == 1
                              ? `/downloadRoomAvatar?roomid=${e.roomid}&fileno=${e.fileno}`
                              : e.memberlist.length == 0
                              ? `/downloadRoomAvatar?roomid=${e.roomid}&fileno=${e.fileno}`
                              : `/downloadAvatar?honbuid=${e.memberlist[0].honbuid}&size=small`)
                          }
                          width="32"
                          height="32"
                          alt=""
                          className="chat-image"
                          //style={isCurrent ? { border: "1px solid white" } : null}
                        />

                        <div className="chat-name-wrapper">
                          {e.memberlist.length == 0 ? (
                            <div className="chat-name">空部屋</div>
                          ) : (
                            <div className="chat-name">
                              <StyledFlexibleDiv>
                                {e.memberlist[0].isvip == "1" && (
                                  <StyledChip variant="outlined" color="primary" size="small" label="VIP"></StyledChip>
                                )}
                                {e.memberlist[0].isadmin == "1" ? (
                                  <StyledChip
                                    variant="outlined"
                                    color="secondary"
                                    size="small"
                                    label="システム管理"
                                  ></StyledChip>
                                ) : e.memberlist[0].isadmin == "2" ? (
                                  <StyledChip
                                    variant="outlined"
                                    color="secondary"
                                    size="small"
                                    label="施設管理"
                                  ></StyledChip>
                                ) : e.memberlist[0].isadmin == "3" ? (
                                  <StyledChip
                                    variant="outlined"
                                    color="secondary"
                                    size="small"
                                    label="部屋管理"
                                  ></StyledChip>
                                ) : e.memberlist[0].isadmin == "6" ? (
                                  <StyledChip
                                    variant="outlined"
                                    color="secondary"
                                    size="small"
                                    label="施設横断部屋"
                                  ></StyledChip>
                                ) : e.memberlist[0].isadmin == "4" ? (
                                  <StyledChip
                                    variant="outlined"
                                    color="secondary"
                                    size="small"
                                    label="ユーザー管理"
                                  ></StyledChip>
                                ) : e.memberlist[0].isadmin == "5" ? (
                                  <StyledChip
                                    variant="outlined"
                                    color="secondary"
                                    size="small"
                                    label="部屋・ユーザー管理"
                                  ></StyledChip>
                                ) : (
                                  <div></div>
                                )}
                              </StyledFlexibleDiv>
                              {getRoomName(e)}
                              <span style={{ fontSize: "80%", marginLeft: "5px" }}>({e.memberlist[0].subname})</span>
                            </div>
                          )}

                          <Badge
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "right",
                            }}
                            color="secondary"
                            badgeContent={Number(e.unreadcnt) + Number(e.unvotecnt)}
                            invisible={Number(e.unreadcnt) + Number(e.unvotecnt) === 0}
                          ></Badge>
                        </div>
                        {/* <div className="chat-last-message">{e.latestmessage}</div> */}

                        {e.ispush == "0" && (
                          <div className="pinicon-wrapper">
                            <NotificationsOffIcon></NotificationsOffIcon>
                          </div>
                        )}

                        {e.isstar == "1" && (
                          <div className="pinicon-wrapper">
                            <RiPushpin2Line></RiPushpin2Line>
                          </div>
                        )}
                      </StyledWrapper>
                    </Tooltip>
                  </AccordionDetails>
                );
              })}
        </Accordion>
      </div>
      {MemoMember}
      {MemoCallMini}
      {/* <ShowCall
        open={openCall}
        handleClose={handleCallClose}
        channel={state.callroomid}
        uid={localStorage.getItem("honbuid")}
        name={state.user_name}
        flag="join"
      ></ShowCall> */}
    </div>
  );
}

export default ChatRooms;
