/* eslint-disable react/prop-types */
import React from "react";
import { STATIC_URL } from "../Common/Common";
import "./MessageList.css";

import PlayCircleOutlineIcon from "@material-ui/icons/PlayCircleOutline";

function VideoMessage({ conversation, handleVideoClickOpen, isReplySource }) {
  const srcID = isReplySource ? conversation.replyid : conversation.messageid;

  return (
    <div className="chat-message-video-wrap">
      <img
        src={STATIC_URL + `/downloadVideo?messageID=${srcID}&size=min`}
        //   width="100"

        alt=""
        className="chat-message-video"
        onClick={() => handleVideoClickOpen()}
        //style={isCurrent ? { border: "1px solid white" } : null}
      />
      <div className="chat-message-video-icon" onClick={() => handleVideoClickOpen()}>
        <PlayCircleOutlineIcon fontSize="large" style={{ color: "white" }} />
      </div>
      {!isReplySource && <div>{conversation.message}</div>}
    </div>
  );
}

export default VideoMessage;
