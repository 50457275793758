/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import Switch from "@material-ui/core/Switch";

function ShowRoomSwitch({ initvalue, callBack, roomid }) {
  const [check, setCheck] = useState(false);
  useEffect(() => {
    setCheck(initvalue == "1" ? true : false);
  }, [initvalue]);
  return (
    <Switch
      checked={check}
      color="primary"
      onChange={(v) => {
        setCheck(v.target.checked);
        callBack(roomid);
      }}
      name="checkedA"
    />
  );
}

export default ShowRoomSwitch;
