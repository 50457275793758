/* eslint-disable react/prop-types */
import React from "react";
import "./App.css";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import Login from "./Login/Login";
import LoginMail from "./Login/LoginMail";
import LoginInitPass from "./Login/LoginInitPass";
import LoginInitPhone from "./Login/LoginInitPhone";
import LoginError from "./Login/LoginError";
import Main from "./Main/Main";
import { Provider } from "./Store/store";

const PrivateRoute = ({ component, ...rest }) => {
  let loginStatus = localStorage.getItem("login");
  //console.log("privateroute", loginStatus);
  return (
    <Route
      exact
      {...rest}
      render={(props) =>
        loginStatus === "true" ? (
          <div>{React.createElement(component, props)}</div>
        ) : (
          <Redirect
            to={{
              pathname: "/login",
            }}
          />
        )
      }
    />
  );
};

const PrivateRouteLogin = ({ component, ...rest }) => {
  let loginStatus = localStorage.getItem("login");
  //console.log("privateroute", loginStatus);
  return (
    <Route
      exact
      {...rest}
      render={(props) =>
        loginStatus === "true" ? (
          <Redirect
            to={{
              pathname: "/",
            }}
          />
        ) : (
          <div>{React.createElement(component, props)}</div>
        )
      }
    />
  );
};

function App() {
  return (
    <BrowserRouter>
      <Provider>
        {/* <ThemeProvider theme={theme}> */}
        <Switch>
          {/* <Auth>
              <Switch>
                <Route exact path="/" component={Main}></Route>
              </Switch>
            </Auth> */}

          <PrivateRouteLogin exact path="/Login" component={Login}></PrivateRouteLogin>
          <Route exact path="/chekLogin" component={LoginMail}></Route>
          <Route exact path="/initpass" component={LoginInitPass}></Route>
          <Route exact path="/initphone" component={LoginInitPhone}></Route>
          <Route exact path="/loginError" component={LoginError}></Route>
          {/* <Route exact path="/note/:roomid/:honbuid/:apikey" component={Note}></Route>
            <Route exact path="/noteme/:honbuid/:apikey" component={NoteMe}></Route> */}
          <PrivateRoute path="/" component={Main}></PrivateRoute>
        </Switch>
        {/* </ThemeProvider> */}
      </Provider>
    </BrowserRouter>
  );
}

export default App;
