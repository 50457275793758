/* eslint-disable react/prop-types */
import "./ShowUserInfo.css";
import React, { useEffect, useState, useContext, useMemo } from "react";
import useReactRouter from "use-react-router";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import { API_URL, STATIC_URL, custom_axios } from "../Common/Common";
import { Store } from "../Store/store";
import ShowRoomAccountPhoto from "../Common/ShowRoomAccountPhoto";
import TextField from "@material-ui/core/TextField";
import SaveOutlinedIcon from "@material-ui/icons/SaveOutlined";
import CameraAltIcon from "@material-ui/icons/CameraAlt";
import IconButton from "@material-ui/core/IconButton";
import styled from "styled-components";

const StyledDialogTitle = styled(DialogTitle)`
  color: ${(props) => props.theme.palette.primary.contrastText};
  background-color: ${(props) => props.theme.palette.primary.main};
`;

const StyledDialogContent = styled(DialogContent)`
  padding: 0px;
`;

function ShowRoomAccount({ open, handleClose }) {
  const { history } = useReactRouter();
  const { state } = useContext(Store);
  const currentRoom = state.chatrooms.filter((f) => f.roomid === state.currentRoomid)[0];
  const [name, setName] = useState();

  const handleSave = async () => {
    let urlstr = API_URL + `/changeRoomName?roomid=${state.currentRoomid}&roomname=${name}`;

    const result = await custom_axios(urlstr);
    const status = result.status;
    if (status === 401) {
      history.replace("/loginError");
    }
    // if (result.status === 200) {
    //   dispatch({
    //     type: "RECEIVE_ROOMNAME",
    //     payload: { roomid: state.currentRoomid, roomname: name },
    //   });
    // }
    handleClose();
  };
  const [isVisible, setIsVisible] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const handleModalClose = () => {
    setModalOpen(false);
  };
  useEffect(() => {
    let ret = false;
    if (name && name.length > 0 && name != state.user_name) {
      ret = true;
    }

    setIsVisible(ret);
  }, [name]);

  const MemoPhoto = useMemo(() => {
    return <ShowRoomAccountPhoto open={modalOpen} handleClose={handleModalClose}></ShowRoomAccountPhoto>;
  }, [modalOpen]);

  return (
    <Dialog open={open} onClose={handleClose} maxWidth={"sm"}>
      <StyledDialogTitle id="max-width-dialog-title">
        <div className="show-member-search">
          <div className="show-member-search">部屋変更</div>
        </div>
      </StyledDialogTitle>

      <StyledDialogContent>
        {MemoPhoto}
        <div className="question-create-wrap">
          <div className="show-user-wrap">
            <div className="show-user-image-back">
              <img
                src={STATIC_URL + `/downloadRoomAvatar?roomid=${currentRoom.roomid}&fileno=${currentRoom.fileno}`}
                alt=""
                className="show-user-image-back-image"
                //style={isCurrent ? { border: "1px solid white" } : null}
              />
            </div>

            <div className="show-user-image-wraper">
              <img
                src={STATIC_URL + `/downloadRoomAvatar?roomid=${currentRoom.roomid}&fileno=${currentRoom.fileno}`}
                width="150"
                height="150"
                alt=""
                className="show-user-image"
                //style={isCurrent ? { border: "1px solid white" } : null}
              />
            </div>

            <div className="show-user-information-camera">
              <IconButton style={{ color: "white" }} onClick={() => setModalOpen(true)}>
                <CameraAltIcon></CameraAltIcon>
              </IconButton>
            </div>
          </div>

          <TextField
            label="RoomID"
            variant="outlined"
            defaultValue={state.currentRoomid}
            disabled
            style={{ margin: "8px", minWidth: "300px" }}
          />
          <TextField
            label="名前"
            variant="outlined"
            defaultValue={currentRoom.roomname}
            onChange={(e) => {
              setName(e.target.value);
            }}
            style={{ margin: "8px", minWidth: "300px" }}
          />
        </div>
      </StyledDialogContent>

      <DialogActions>
        <Button
          disabled={isVisible ? false : true}
          variant="contained"
          color="primary"
          onClick={handleSave}
          startIcon={<SaveOutlinedIcon />}
        >
          変更
        </Button>
        <Button onClick={handleClose} variant="outlined" color="secondary">
          閉じる (Esc)
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ShowRoomAccount;
