import React from "react";
import "./EmptyChat.css";
import Lottie from "react-lottie";
import animationData from "./34611-chat-bubbles.json";

const EmptyChat = () => (
  <div className="empty-chat">
    <Lottie
      //width={200}
      //height={200}
      width={250}
      height={200}
      options={{
        loop: true,
        autoplay: true,
        animationData,
        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice",
        },
      }}
    />
    <div>Chatisへようこそ！</div>
  </div>
);

export default EmptyChat;
