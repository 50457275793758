/* eslint-disable react/prop-types */
import "./ShowUserInfo.css";
import React, { useEffect, useState, useContext } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { API_URL, custom_axios } from "../Common/Common";
import useReactRouter from "use-react-router";
import { Store } from "../Store/store";
import ShowCreateNews from "../Common/ShowCreateNews";
import ShowImageTimelineInfo from "../Common/ShowImageTimelineInfo";
import News from "../News/News";
import Slide from "@material-ui/core/Slide";
import { useInView } from "react-intersection-observer";
import Snackbar from "@material-ui/core/Snackbar";
import styled from "styled-components";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const StyledGridListroot = styled.div`
  overflow-y: auto;
  height: calc(100vh - 47px);
  display: flex;
  flex-wrap: wrap;
`;

const StyledDialogTitle = styled(DialogTitle)`
  color: ${(props) => props.theme.palette.primary.contrastText};
  background-color: ${(props) => props.theme.palette.primary.main};
  padding: 5px 10px;
`;

const StyledButton = styled(Button)`
  color: ${(props) => props.theme.palette.primary.contrastText};
  background-color: ${(props) => props.theme.palette.primary.main};
  padding: 5px 10px;
`;
const StyledDialogContent = styled(DialogContent)`
  padding: 0px;
`;

function ShowNews({ open, handleClose, hpcode }) {
  const { history } = useReactRouter();
  const { state } = useContext(Store);

  const [localList, setLocalList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [ref, inView] = useInView({
    rootMargin: "0px 0px",
  });

  const mainproc = async () => {
    let honbuid = localStorage.getItem("honbuid");
    const result = await custom_axios(API_URL + `/GetTimelineList?honbuid=${honbuid}&hpcode=${hpcode}`);
    const status = result.status;
    if (status === 401) {
      history.replace("/loginError");
    } else if (status === 200) {
      setLocalList(result.data.timelines);
    } else if (status === 204) {
      setLocalList([]);
    }
  };

  const addproc = async () => {
    if (localList.length > 1) {
      var lastid = localList[localList.length - 1].timelineid;
      let honbuid = localStorage.getItem("honbuid");
      //console.log("addproc lastid", localList);
      const result = await custom_axios(
        API_URL + `/GetTimelineList?honbuid=${honbuid}&hpcode=${hpcode}&timelineid=${lastid}`
      );
      const status = result.status;
      console.log("addproc result", result);
      if (status === 401) {
        history.replace("/loginError");
      } else if (status === 200) {
        setLocalList([...localList, ...result.data.timelines]);
      }
    }
  };

  useEffect(() => {
    setLoading(true);
    if (open) {
      mainproc();
    }
    setLoading(false);
  }, [open]);
  useEffect(() => {
    //console.log("inView", inView);
    if (inView && loading == false) {
      setLoading(true);
      addproc();
      setLoading(false);
    }
  }, [inView]);

  const callback = (tid) => {
    custom_axios(API_URL + `/delTimeline?timelineid=${tid}`).then((red) => {
      const status = red.status;
      if (status === 401) {
        history.replace("/loginError");
      }
      if (status === 200) {
        setLocalList(localList.filter((f) => f.timelineid != tid));
        setSnackOpen(true);
      }
    });
  };
  const [snackopen, setSnackOpen] = useState(false);
  const [openFa, setOpenFa] = useState(false);
  const handleFaClickOpen = () => {
    setOpenFa(true);
  };
  const handleFaClose = () => {
    setOpenFa(false);
  };

  const [imgopen, setImgopen] = useState(false);
  const handleImgClose = () => {
    setImgopen(false);
  };
  const [initTimelineid, setInitTimelineid] = useState(null);
  const [maxFileid, setMaxFileid] = useState(0);

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleClose}
      fullWidth={true}
      maxWidth={"xl"}
      TransitionComponent={Transition}
    >
      <Snackbar open={snackopen} autoHideDuration={3000} onClose={() => setSnackOpen(false)} message="削除しました。" />
      <StyledDialogTitle id="max-width-dialog-title">
        <div className="show-member-search">
          <div className="show-member-search">ニュース</div>
          <div className="question-title-actions">
            {["1", "2"].includes(state.isadmin) && (
              <StyledButton
                onClick={() => {
                  handleFaClickOpen();
                }}
                //className={classes.textcolor}
                variant="outlined"
              >
                ニュース作成
              </StyledButton>
            )}

            <div style={{ width: "20px" }}></div>
            <StyledButton onClick={handleClose} variant="outlined">
              閉じる (Esc)
            </StyledButton>
          </div>
        </div>
      </StyledDialogTitle>
      <StyledDialogContent>
        <div>
          {loading ? (
            <StyledGridListroot>
              {[...Array(20)].map((_, i) => {
                return (
                  <div key={i} className="news-margin">
                    <News
                      timelineid={""}
                      honbuid={""}
                      name={""}
                      message={""}
                      time={""}
                      fileno={""}
                      filecnt={""}
                      openhps={""}
                      //url={""}
                      isskeleton={loading}
                      callback={() => {}}
                      setInitTimelineid={() => {}}
                      setMaxFileid={() => {}}
                      setImgOpen={() => {}}
                    />
                  </div>
                );
              })}
            </StyledGridListroot>
          ) : (
            <StyledGridListroot>
              {localList.map((e) => {
                return (
                  <div key={e.timelineid} className="news-margin">
                    <News
                      timelineid={e.timelineid}
                      honbuid={e.honbuid}
                      name={e.name}
                      title={e.title}
                      message={e.message}
                      time={e.time}
                      fileno={e.fileno}
                      filecnt={e.filecnt}
                      openhps={e.openhps}
                      //url={e.url}
                      isskeleton={loading}
                      callback={callback}
                      setInitTimelineid={setInitTimelineid}
                      setMaxFileid={setMaxFileid}
                      setImgOpen={setImgopen}
                    />
                  </div>
                );
              })}
              <div ref={ref}></div>
            </StyledGridListroot>
          )}
          <ShowCreateNews open={openFa} handleClose={handleFaClose} afterSaveProc={mainproc}></ShowCreateNews>
          <ShowImageTimelineInfo
            open={imgopen}
            timelineid={initTimelineid}
            fileid="0"
            maxFileid={maxFileid}
            handleClose={handleImgClose}
          />
        </div>
      </StyledDialogContent>
    </Dialog>
  );
}

export default ShowNews;
