/* eslint-disable react/prop-types */
import "./ShowUserInfo.css";
import { withStyles } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import useReactRouter from "use-react-router";
import MuiDialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import { API_URL, custom_axios, custom_axios_post } from "../Common/Common";
import TextField from "@material-ui/core/TextField";
import SaveOutlinedIcon from "@material-ui/icons/SaveOutlined";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import styled from "styled-components";
import ShowCommon from "../Common/ShowCommon";

const Dialog = withStyles(() => ({
  paperWidthSm: {
    maxWidth: "700px",
  },
}))(MuiDialog);
const StyledDialogTitle = styled(DialogTitle)`
  color: ${(props) => props.theme.palette.primary.contrastText};
  background-color: ${(props) => props.theme.palette.primary.main};
`;
const StyledDialogContent = styled(DialogContent)`
  padding: 0px;
  overflow-y: hidden;
`;
const StyledFormControl = styled(FormControl)`
  margin: ${(props) => props.theme.spacing(1) + "px"};
  width: 200px;
`;

function ShowCreateAccount({ open, handleClose, afterSaveProc, setNewUser, hpcode, hpname }) {
  const { history } = useReactRouter();
  const [name, setName] = useState("");
  const [unitoptions, setUnitOptions] = useState([]);
  const [selectedunit, setSelectedUnit] = useState([]);
  const [options, setOptions] = useState([]);
  const [selectedhp, setSelectedHp] = useState(hpcode + ":" + hpname);

  const [hpopen, setHpOpen] = useState(false);
  const [unitopen, setUnitOpen] = useState(false);

  const [openModal, setOpenModal] = useState(false);
  const handleModalClose = () => {
    setOpenModal(false);
  };

  const handleSave = async () => {
    handleModalClose();

    const body = {
      name: name,
      hp: selectedhp.split(":")[0],
      unit: selectedunit.split(":")[0],
    };
    const result = await custom_axios_post(API_URL + "/addAccount", body);
    const status = result.status;

    if (status === 401) {
      history.replace("/loginError");
    }
    if (result.status === 200) {
      setName("");
      setSelectedHp([]);
      setSelectedUnit([]);
      afterSaveProc();
      handleClose(); /* 自身も閉じる */
      setNewUser({ open: true, honbuid: result.data.honbuid });
    }
  };
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    //let ret = false;
    let okCnt = 0;

    if (name && name.length > 0) {
      okCnt++;
    }
    if (selectedhp && selectedhp.length > 0) {
      okCnt++;
    }
    if (selectedunit && selectedunit.length > 0) {
      okCnt++;
    }

    /* 必須入力箇所（3個）が入力できた場合は登録ボタンを押せるようにする */
    setIsVisible(okCnt == 3);
  }, [name, selectedhp, selectedunit]);

  useEffect(() => {
    //console.log("GetUser前", honbuid);
    const proc = async () => {
      let honbuid = localStorage.getItem("honbuid");
      //console.log("GetMultiSelect");
      if (open) {
        const result = await custom_axios(API_URL + `/GetMultiSelect?honbuid=${honbuid}&hpcodes=${hpcode}&hascode=1`);
        const status = result.status;
        if (status === 401) {
          history.replace("/loginError");
        } else if (status === 200) {
          setOptions(
            result.data.hps.map((e) => {
              return (
                <MenuItem key={e.hpcode} value={e.hpname}>
                  {e.hpname}
                </MenuItem>
              );
            })
          );
          setUnitOptions(
            result.data.units.map((e) => {
              return (
                <MenuItem key={e.unitcode} value={e.unitname}>
                  {e.unitname}
                </MenuItem>
              );
            })
          );
        }
      }
    };
    proc();
  }, [open]);

  const hpchange = async (localhpcode) => {
    const result = await custom_axios(API_URL + `/GetOneUnitSelect?hpcodes=${localhpcode.split(":")[0]}&hascode=1`);
    const status = result.status;
    if (status === 401) {
      history.replace("/loginError");
    } else if (status === 200) {
      setUnitOptions(
        result.data.units.map((e) => {
          return (
            <MenuItem key={e.unitcode} value={e.unitname}>
              {e.unitname}
            </MenuItem>
          );
        })
      );
      setSelectedUnit([]);
    }
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <StyledDialogTitle id="max-width-dialog-title">
        <div className="show-member-search">
          <div className="show-member-search">ユーザー作成</div>
        </div>
      </StyledDialogTitle>

      <StyledDialogContent>
        <div className="question-create-wrap">
          <StyledFormControl required>
            <InputLabel>施設</InputLabel>
            <Select
              error={selectedhp.length == 0}
              open={hpopen}
              onClose={() => {
                setHpOpen(false);
              }}
              onOpen={() => {
                setHpOpen(true);
              }}
              value={selectedhp}
              onChange={(e) => {
                //console.log("e.target.value", e.target.value);
                setSelectedHp(e.target.value);
                hpchange(e.target.value);
              }}
            >
              {options}
            </Select>
          </StyledFormControl>

          <StyledFormControl required>
            <InputLabel>部署</InputLabel>
            <Select
              error={selectedunit.length == 0}
              open={unitopen}
              onClose={() => {
                setUnitOpen(false);
              }}
              onOpen={() => {
                setUnitOpen(true);
              }}
              value={selectedunit}
              onChange={(e) => {
                //console.log("e.target.value", e.target.value);
                setSelectedUnit(e.target.value);
              }}
            >
              {unitoptions}
            </Select>
          </StyledFormControl>
          <TextField
            required
            error={name == ""}
            label="名前"
            id="outlined-size-normal"
            variant="outlined"
            onChange={(e) => {
              setName(e.target.value);
            }}
            style={{ margin: "8px", width: "200px" }}
          />
        </div>
        <ShowCommon
          open={openModal}
          title="作成しますか？"
          handleClose={handleModalClose}
          handleCallBack={handleSave}
        ></ShowCommon>
      </StyledDialogContent>

      <DialogActions>
        <Button
          disabled={isVisible ? false : true}
          variant="contained"
          color="primary"
          onClick={() => setOpenModal(true)}
          startIcon={<SaveOutlinedIcon />}
        >
          作成
        </Button>
        <Button onClick={handleClose} variant="outlined" color="secondary">
          閉じる (Esc)
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ShowCreateAccount;
