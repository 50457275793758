/* eslint-disable react/prop-types */
/* eslint-disable react/react-in-jsx-scope */
import "./ShowUserInfo.css";
import React, { useEffect, useState, useReducer, useContext } from "react";
import { API_URL, custom_axios, custom_axios_post } from "../Common/Common";
import useReactRouter from "use-react-router";
import { Store } from "../Store/store";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Search from "@material-ui/icons/Search";
import ShowMemberDetail from "./ShowMemberDetail";
import MultiSelect from "react-multi-select-component";
import styled from "styled-components";
import Snackbar from "@material-ui/core/Snackbar";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import IconButton from "@material-ui/core/IconButton";
import Close from "@material-ui/icons/Close";

const StyledDialogTitle = styled(DialogTitle)`
  color: ${(props) => props.theme.palette.primary.contrastText};
  background-color: ${(props) => props.theme.palette.primary[500]};
  padding: 5px 10px;
`;
const StyledFcolor = styled.div`
  color: ${(props) => props.theme.palette.primary.contrastText};
`;

const StyledSearch = styled(Search)`
  color: ${(props) => props.theme.palette.primary[300]};
`;

function ShowMember({ open, handleClose, isadduser, roomid, iswithme = false, callback }) {
  const { state, hubConnection, dispatch } = useContext(Store);
  const { history } = useReactRouter();
  const [errorsnackopen, setErrorSnackOpen] = useState(false);
  const [list, listdispatch] = useReducer((state, action) => {
    //console.log("action.type", action.type);
    //console.log("action.payload", action.payload);
    switch (action.type) {
      case "INIT":
        var ids = state.filter((f) => f.flag === "selected").map((v) => v.honbuid);
        if (ids.length > 0) {
          return action.payload.map((item) => {
            if (ids.includes(item.honbuid)) {
              return { ...item, flag: "selected", search: "" };
            } else {
              return { ...item, flag: "", search: "" };
            }
          });
        } else {
          return action.payload.map((item) => {
            return { ...item, search: "" };
          });
        }
      case "SEARCH":
        var temp = state.map((item) => {
          if (action.payload === "") {
            return { ...item, search: "" };
          } else if (
            (action.payload && String(item.name).indexOf(action.payload) !== -1) ||
            (action.payload && String(item.subname).indexOf(action.payload) !== -1) ||
            (action.payload && String(item.hpname).indexOf(action.payload) !== -1)
          ) {
            return { ...item, search: "hit" };
          } else {
            return { ...item, search: "" };
          }
        });
        return temp;
      case "CHANGE":
        return state.map((item) => {
          if (item.honbuid === action.payload) {
            if (item.flag === "selected") {
              return { ...item, flag: "" };
            } else {
              return { ...item, flag: "selected" };
            }
          }
          return item;
        });
      case "MARK":
        return state.map((item) => {
          if (item.honbuid === action.payload) {
            if (item.mark === "selected") {
              return { ...item, mark: "" };
            } else {
              return { ...item, mark: "selected" };
            }
          }
          return item;
        });
      default:
        return state;
    }
  }, []);

  const [searchText, setSearchText] = useState("");
  const [searchName, setSearchName] = useState("");
  const [sroomname, setSroomname] = useState("");

  const proc = async (localhps, localunits, localsearchText) => {
    let joinhps = localhps
      .map((e) => {
        return e.value;
      })
      .join("_");

    let joinunits = localunits
      .map((e) => {
        return e.value;
      })
      .join("_");

    if (joinhps.length == 0 || joinunits.length == 0) {
      setSearchName("");
      listdispatch({ type: "INIT", payload: [] });
      return;
    }
    let honbuid = localStorage.getItem("honbuid");
    if (open) {
      var ids = list
        .filter((f) => f.flag === "selected")
        .map((v) => v.honbuid)
        .join("_");
      const body = {
        honbuid: honbuid,
        roomid: isadduser ? roomid : "",
        iswithme: iswithme ? "1" : "",
        hascode: "1",
        ids: ids,
        hpcodes: joinhps,
        unitcodes: joinunits,
        searchone: localsearchText,
      };
      const result = await custom_axios_post(API_URL + "/GetUsers", body);

      // const reqapi =
      //   API_URL +
      //   `/GetUsers?honbuid=${honbuid}${
      //     (isadduser ? "&roomid=" + roomid : "") + (iswithme ? "&iswithme=1" : "")
      //   }&hascode=1&hpcodes=${joinhps}&unitcodes=${joinunits}&ids=${ids}`;
      // const result = await custom_axios(reqapi);

      const status = result.status;
      if (status === 401) {
        history.replace("/loginError");
      }
      if (status === 200) {
        setOptions(
          result.data.hps.map((e) => {
            return { label: e.hpname, value: e.hpcode };
          })
        );
        setUnitOptions(
          result.data.units.map((e) => {
            return { label: e.unitname, value: e.unitcode };
          })
        );
        setSearchName("");
        listdispatch({ type: "INIT", payload: result.data.users });
      } else {
        setSearchName("");
        listdispatch({ type: "INIT", payload: result.data.users });
      }
    }
  };

  // useEffect(() => {
  //   const updateList = list.map((item) => {
  //     if (sword === "" && item.flag != "selected") {
  //       return { ...item, flag: "" };
  //     } else if (item.flag === "selected") {
  //       return item;
  //     } else if (
  //       (sword && String(item.name).indexOf(sword) !== -1) ||
  //       (sword && String(item.subname).indexOf(sword) !== -1) ||
  //       (sword && String(item.hpname).indexOf(sword) !== -1)
  //     ) {
  //       return { ...item, flag: "target" };
  //     } else {
  //       return { ...item, flag: "none" };
  //     }
  //   });
  //   listdispatch({ type: "SEARCH", payload: updateList });
  // }, [sword]);

  const handleCreate = async () => {
    let resturl = "";

    const strhonbuid = localStorage.getItem("honbuid");
    const listhonbuid = list.filter((f) => f.flag === "selected").map((v) => v.honbuid);
    const listmarkhonbuid = list.filter((f) => f.mark === "selected").map((v) => v.honbuid);
    const listhonbuid2name = list.filter((f) => f.flag === "selected").map((v) => v.name);
    //console.log("listhonbuid", listhonbuid);

    if (isadduser) {
      /* 既存ルームにユーザー追加時 */
      resturl = `/addUserToRoom?roomid=${roomid}&honbuids=${listhonbuid.join("_")}`;
      const result = await custom_axios(API_URL + resturl);
      const status = result.status;
      //console.log("addUserToRoom", result);
      if (status === 401) {
        history.replace("/loginError");
      }
      if (status === 200) {
        for (let m = 0; m < listhonbuid.length; m++) {
          hubConnection.invoke(
            "SendMessage",
            roomid,
            listhonbuid[m],
            state.user_name,
            listhonbuid2name[m] + " を追加しました",
            "information"
          );
        }
        if (iswithme) {
          callback();
        }
      }
    } else if (iswithme) {
      /* ルーム作成時(管理者画面から作成) */
      // resturl = `/CreateAdminRoom?mainhonbuids=${listhonbuid.join("_")}&roomname=${sroomname}&role=${role}`;
      // const result = await custom_axios(API_URL + resturl);

      const body = {
        mainhonbuids: listhonbuid.join("_"),
        roomname: sroomname,
        role: role,
        markmainhonbuids: listmarkhonbuid.join("_"),
      };
      const result = await custom_axios_post(API_URL + "/CreateAdminRoom", body);

      const status = result.status;
      if (status === 401) {
        history.replace("/loginError");
      }
      //console.log("createRoom", result);
      if (status === 200) {
        hubConnection.invoke(
          "SendMessage",
          result.data.roomid,
          strhonbuid,
          state.user_name,
          "部屋が作成されました。",
          "information"
        );
        // fetchData("").then((r) => {
        //   dispatch({ type: "INIT_CONVERSASTION", payload: r });
        //   addChatConnection(result.data.roomid);
        //   dispatch({ type: "CHANGE_ROOM", payload: { to: result.data.roomid, from: state.currentRoomid } });
        //   localStorage.setItem("currentRoomid", result.data.roomid);
        // });

        callback();
      }
    } else {
      /* ルーム作成時(一般ユーザーが作成) */

      if (list.filter((f) => f.flag === "selected").length > 1) {
        resturl = `/createRoom?honbuid=${strhonbuid}&mainhonbuids=${listhonbuid.join("_")}&roomname=${sroomname}`;
      } else {
        const targetroom = state.chatrooms.filter(
          (f) => f.memberlist.length === 1 && f.memberlist[0].honbuid === listhonbuid[0]
        );
        //console.log("一人部屋チェック", targetroom);
        if (targetroom.length > 0) {
          //let currentroomid = localStorage.getItem("currentRoomid");
          dispatch({ type: "CHANGE_ROOM", payload: { to: targetroom[0].roomid, from: state.currentRoomid } });
          localStorage.setItem("currentRoomid", targetroom[0].roomid);
          handleClose();
          return;
        }

        resturl = `/createRoom?honbuid=${strhonbuid}&mainhonbuids=${listhonbuid.join("_")}`;
      }
      const result = await custom_axios(API_URL + resturl);
      const status = result.status;
      if (status === 401) {
        history.replace("/loginError");
      }
      //console.log("createRoom", result);
      if (status === 200) {
        hubConnection.invoke(
          "SendMessage",
          result.data.roomid,
          strhonbuid,
          state.user_name,
          "部屋が作成されました。",
          "information"
        );
        fetchData("").then((r) => {
          //console.log("INIT_CONVERSASTION", r);
          dispatch({ type: "INIT_CONVERSASTION", payload: r });
          //addChatConnection(result.data.roomid);
          hubConnection.invoke("addGroup", roomid);
          //let currentroomid = localStorage.getItem("currentRoomid");
          dispatch({ type: "CHANGE_ROOM", payload: { to: result.data.roomid, from: state.currentRoomid } });
          localStorage.setItem("currentRoomid", result.data.roomid);
        });
      }
    }

    handleClose();
  };

  const fetchData = async (roomid) => {
    let honbuid = localStorage.getItem("honbuid");
    const httpstring =
      roomid !== ""
        ? `/getConversationList?honbuid=${honbuid}`
        : `/getConversationListOne?honbuid=${honbuid}&roomid=${roomid}`;
    const result = await custom_axios(API_URL + httpstring);
    const status = result.status;
    if (status === 401) {
      history.replace("/loginError");
    }
    if (status === 200) {
      return result.data;
    }
  };

  // const [joboptions, setJobOptions] = useState([]);
  const [unitoptions, setUnitOptions] = useState([]);
  const [options, setOptions] = useState([]);

  // const [selectedjob, setSelectedJob] = useState([]);
  const [selectedunit, setSelectedUnit] = useState([]);
  const [selected, setSelected] = useState([]);

  useEffect(() => {
    if (open) {
      setSelected([{ label: state.hpcode + ":" + state.hpname, value: state.hpcode }]);
      setSelectedUnit([{ label: state.unitcode + ":" + state.unitname, value: state.unitcode }]);
      proc(
        [{ label: state.hpname, value: state.hpcode }],
        [{ label: state.unitname, value: state.unitcode }],
        searchText
      );
    } else {
      setSearchName("");
      listdispatch({ type: "INIT", payload: [] });
    }
  }, [open]);

  const [role, setRole] = React.useState("0");

  const handleRoleChange = (event) => {
    setRole(event.target.value);
  };

  const handleKeyDown = (e) => {
    //e.preventDefault();
    if (e.which === 13 && !e.shiftKey) {
      e.preventDefault();
      if (searchText != "") {
        handleSearchClick();
      }
    }
  };
  const handleCloseClick = async () => {
    setSearchText("");
    proc(selected, selectedunit, "");
  };
  const handleSearchClick = async () => {
    proc(selected, selectedunit, searchText);
  };
  const handleChange = (e) => {
    setSearchText(e.target.value);
  };
  const handleNameChange = (e) => {
    setSearchName(e.target.value);
  };
  useEffect(() => {
    if (searchName !== "") {
      // 人名検索
      listdispatch({ type: "SEARCH", payload: searchName });
    }
  }, [searchName]);
  return (
    <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth={"sm"}>
      <StyledDialogTitle id="max-width-dialog-title">
        <div className="show-member-search">
          <StyledFcolor>{isadduser ? "メンバー追加" : "部屋作成"}</StyledFcolor>
          {/*<div className="show-chat-search">
             <div className="show-chat-search-icon">
              <Search />
            </div>
            <input
              placeholder="メンバー 検索"
              name="chatsFilter"
              onChange={(e) => {
                setSword(e.target.value);
              }}
              className="chat-search-field"
            /> 
          </div>*/}
          {["2", "3", "5"].includes(state.isadmin) && (
            <div className="chat-search">
              <div className="chat-search-icon">
                <StyledSearch />
              </div>
              <input
                placeholder="名前検索"
                value={searchName}
                name="chatsFilter"
                onChange={handleNameChange}
                className="chat-search-field"
              />
            </div>
          )}
          {["1", "6"].includes(state.isadmin) && (
            <div className="chat-search">
              <div className="chat-search-icon">
                <IconButton color="primary" aria-label="send message" onClick={handleSearchClick}>
                  <Search />
                </IconButton>
              </div>
              <input
                placeholder="本部ID検索"
                value={searchText}
                name="chatsFilter"
                onChange={handleChange}
                onKeyDown={handleKeyDown}
                className="chat-search-field"
              />
              <div className="chat-search-icon">
                <IconButton color="primary" aria-label="send message" onClick={handleCloseClick}>
                  <Close />
                </IconButton>
              </div>
            </div>
          )}
        </div>
      </StyledDialogTitle>

      <DialogContent>
        <div className="full-height">
          <div className="multi-select">
            <MultiSelect
              options={options}
              value={selected}
              onChange={(e) => {
                setSelected(e);
                proc(e, selectedunit, searchText);
              }}
              labelledBy={"Select"}
              overrideStrings={{
                selectSomeItems: "選択...",
                allItemsAreSelected: "全選択",
                selectAll: "全選択",
                search: "検索",
                clearSearch: "検索クリア",
              }}
              filterOptions={(options, filter) => {
                if (!filter) {
                  return options;
                }
                const re = new RegExp(filter, "i");
                return options.filter(({ label }) => label && label.match(re));
              }}
            />
          </div>
          <div className="multi-select">
            <MultiSelect
              options={unitoptions}
              value={selectedunit}
              onChange={(e) => {
                setSelectedUnit(e);
                proc(selected, e, searchText);
              }}
              labelledBy={"Select"}
              overrideStrings={{
                selectSomeItems: "選択...",
                allItemsAreSelected: "全選択",
                selectAll: "全選択",
                search: "検索",
                clearSearch: "検索クリア",
              }}
              filterOptions={(options, filter) => {
                if (!filter) {
                  return options;
                }
                const re = new RegExp(filter, "i");
                return options.filter(({ label }) => label && label.match(re));
              }}
            />
          </div>
          {list &&
            list.filter((f) => f.flag !== "none") &&
            list
              .filter((f) => f.flag !== "none")
              .filter((f) => searchName == "" || (searchName != "" && f.search == "hit"))
              .map((e) => {
                //console.log("中の処理", e);
                return (
                  <ShowMemberDetail
                    key={e.honbuid}
                    honbuid={e.honbuid}
                    fileno={e.fileno}
                    name={e.name}
                    hpname={e.hpname}
                    subname={e.subname}
                    listdispatch={listdispatch}
                    initselected={e.flag === "selected"}
                    isvip={e.isvip}
                    setErrorSnackOpen={setErrorSnackOpen}
                    isadmin={e.isadmin}
                    initmarked={e.mark === "selected"}
                    ismark={role == "1"}
                  ></ShowMemberDetail>
                );
              })}
        </div>
      </DialogContent>

      <DialogActions>
        {list && list.filter((f) => f.flag === "selected").length > 0 && (
          <>
            {isadduser === false && list && list.filter((f) => f.flag === "selected").length > 1 && iswithme && (
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={role}
                onChange={handleRoleChange}
              >
                <MenuItem value={"0"}>双方向</MenuItem>
                <MenuItem value={"1"}>一方向</MenuItem>
              </Select>
            )}
            {isadduser === false && list && list.filter((f) => f.flag === "selected").length > 1 && (
              <div className="show-create-room-name">
                <input
                  placeholder="新規部屋名を入力"
                  value={sroomname}
                  name="sroomname"
                  onChange={(e) => {
                    setSroomname(e.target.value);
                  }}
                  //className="show-create-room-field"
                  className={[
                    "show-create-room-field",
                    list.filter((f) => f.flag === "selected").length > 1 && sroomname.length === 0 && "plus-background",
                  ].join(" ")}
                />
              </div>
            )}
            {isadduser ? (
              <Button
                variant="contained"
                color="primary"
                onClick={handleCreate}
                disabled={list.filter((f) => f.flag === "selected").length === 0}
              >
                {`ユーザー追加${
                  list.filter((f) => f.flag === "selected").length > 1
                    ? "(" + list.filter((f) => f.flag === "selected").length + "人)"
                    : ""
                }`}
              </Button>
            ) : iswithme && list.filter((f) => f.flag === "selected").length > 1 ? (
              <Button
                variant="contained"
                color="primary"
                onClick={handleCreate}
                disabled={!sroomname && list.filter((f) => f.flag === "selected").length < 2}
              >
                {`部屋作成${
                  list.filter((f) => f.flag === "selected").length > 1
                    ? "(" + list.filter((f) => f.flag === "selected").length + "人)"
                    : ""
                }`}
              </Button>
            ) : !iswithme ? (
              <Button
                variant="contained"
                color="primary"
                onClick={handleCreate}
                disabled={!sroomname && list.filter((f) => f.flag === "selected").length > 1}
              >
                {`部屋作成${
                  list.filter((f) => f.flag === "selected").length > 1
                    ? "(" + list.filter((f) => f.flag === "selected").length + "人)"
                    : ""
                }`}
              </Button>
            ) : (
              <div></div>
            )}
          </>
        )}
        <Button onClick={handleClose} variant="outlined" color="secondary">
          閉じる (Esc)
        </Button>
        <Snackbar
          open={errorsnackopen}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          autoHideDuration={6000}
          onClose={() => {
            setErrorSnackOpen(false);
          }}
          message="VIPとはコンタクトできません。"
        ></Snackbar>
      </DialogActions>
    </Dialog>
  );
}
export default ShowMember;
