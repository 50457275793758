import React, { useContext, useState } from "react";
//import "./Input.css";
//import { Store } from "../Store/store";

function Anpi() {
  const [inputtext, setInputtext] = useState("");

  return <div className="">Anpi</div>;
}

export default Anpi;
