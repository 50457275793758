/* eslint-disable react/prop-types */
import "./ShowUserInfo.css";
import React, { useEffect, useState, useMemo } from "react";
import useReactRouter from "use-react-router";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import { API_URL, custom_axios } from "../Common/Common";
import styled from "styled-components";
import ReplyMessageOnDialog from "../MessageList/ReplyMessageOnDialog";
import ShowReply from "../Common/ShowReply";
import ShowVideoInfo from "../Common/ShowVideoInfo";
import ShowImageInfo from "../Common/ShowImageInfo";
import Loader from "react-loader-spinner";

const StyledDialogTitle = styled(DialogTitle)`
  color: ${(props) => props.theme.palette.primary.contrastText};
  background-color: ${(props) => props.theme.palette.primary.main};
`;

const StyledDialogContent = styled(DialogContent)`
  //padding: 0px;
`;

function ShowSearchConversation({ open, handleClose, roomid, messageid }) {
  const { history } = useReactRouter();
  const honbuid = localStorage.getItem("honbuid");
  const [localList, setLocalList] = useState([]);
  useEffect(() => {
    setLocalList([]);
  }, []);

  /* 画像オープンとか色々必要なため */
  const [initMessageid, setInitMessageid] = useState(null);
  const [imgopen, setImgOpen] = React.useState(false);
  const [videoopen, setVideoOpen] = React.useState(false);
  const [openReplyid, setOpenReplyid] = useState(0);
  const [openReply, setOpenReply] = useState(false);
  const [enableFrom, setEnableFrom] = useState(true);
  const [enableTo, setEnableTo] = useState(true);
  const handleImgClose = () => {
    setImgOpen(false);
  };
  const handleVideoClose = () => {
    setVideoOpen(false);
  };
  const handleReplyClose = () => {
    setOpenReply(false);
  };
  const MemoImage = useMemo(() => {
    return <ShowImageInfo open={imgopen} messageid={initMessageid} handleClose={handleImgClose} />;
  }, [imgopen, initMessageid]);
  const MemoVideo = useMemo(() => {
    return <ShowVideoInfo open={videoopen} messageid={initMessageid} handleClose={handleVideoClose} />;
  }, [videoopen, initMessageid]);
  const MemoReply = useMemo(() => {
    return (
      <ShowReply
        open={openReply}
        handleClose={handleReplyClose}
        roomid={roomid}
        honbuid={honbuid}
        replyid={openReplyid}
        setInitMessageid={setInitMessageid}
        setImgOpen={setImgOpen}
        setVideoOpen={setVideoOpen}
      />
    );
  }, [openReply, openReplyid]);

  useEffect(() => {
    //console.log("GetUser前", honbuid);
    const proc = async () => {
      var apistr = API_URL + `/GetSearchConversationList?honbuid=${honbuid}&roomid=${roomid}&messageid=${messageid}`;
      const result = await custom_axios(apistr);
      const status = result.status;
      if (status === 401) {
        history.replace("/loginError");
      }
      if (status === 200) {
        setLocalList(result.data.conversations);
      }
    };
    if (open) {
      proc();
    } else {
      setLocalList([]);
      setEnableFrom(true);
      setEnableTo(true);
    }
  }, [open]);

  const handleFrom = async () => {
    var lmessageid = localList[0].messageid;
    const result = await custom_axios(
      API_URL + `/GetSearchConversationFrom?honbuid=${honbuid}&roomid=${roomid}&messageid=${lmessageid}`
    );
    const status = result.status;
    if (status === 401) {
      history.replace("/loginError");
    }
    if (status === 200) {
      setLocalList([...localList, ...result.data.conversations]);
    }
    if (status === 204) {
      setEnableFrom(false);
    }
  };

  const handleTo = async () => {
    var lmessageid = localList[localList.length - 1].messageid;
    const result = await custom_axios(
      API_URL + `/GetSearchConversationTo?honbuid=${honbuid}&roomid=${roomid}&messageid=${lmessageid}`
    );
    const status = result.status;
    if (status === 401) {
      history.replace("/loginError");
    }
    if (status === 200) {
      setLocalList([...localList, ...result.data.conversations]);
    }
    if (status === 204) {
      setEnableTo(false);
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth={"md"}>
      <StyledDialogTitle id="max-width-dialog-title">
        <div className="show-member-search">
          <div className="show-member-search">メッセージ履歴</div>
        </div>
      </StyledDialogTitle>

      <StyledDialogContent>
        {MemoImage}
        {MemoVideo}
        {MemoReply}

        {localList ? (
          <div className="reply-list">
            <div style={{ textAlign: "center", marginBottom: "10px" }}>
              {enableFrom && (
                <Button style={{ width: "80%" }} variant="contained" color="primary" onClick={handleFrom}>
                  前のメッセージ(5件)取得
                </Button>
              )}
            </div>
            {localList
              .sort(function (a, b) {
                if (Number(a.messageid) > Number(b.messageid)) {
                  return 1;
                }
                if (Number(a.messageid) < Number(b.messageid)) {
                  return -1;
                }
                return 0;
              })
              .map((e) => {
                return (
                  <ReplyMessageOnDialog
                    key={e.messageid}
                    conversation={e}
                    setInitMessageid={setInitMessageid}
                    setImgOpen={setImgOpen}
                    setVideoOpen={setVideoOpen}
                    setOpenReply={setOpenReply}
                    setOpenReplyid={setOpenReplyid}
                  ></ReplyMessageOnDialog>
                );
              })}
            <div style={{ textAlign: "center", marginTop: "10px" }}>
              {enableTo && (
                <Button style={{ width: "80%" }} variant="contained" color="primary" onClick={handleTo}>
                  次のメッセージ(5件)取得
                </Button>
              )}
            </div>
          </div>
        ) : (
          <div
            style={{
              width: "100%",
              height: "100",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Loader type="ThreeDots" color="#2BAD60" height="100" width="100" />
          </div>
        )}
      </StyledDialogContent>

      <DialogActions>
        <Button onClick={handleClose} variant="outlined" color="secondary">
          閉じる (Esc)
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ShowSearchConversation;
