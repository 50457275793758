/* eslint-disable react/prop-types */
import "./ShowUserInfo.css";
import { withStyles } from "@material-ui/core/styles";
import React, { useMemo, useEffect, useState } from "react";
import useReactRouter from "use-react-router";
import ShowAnpiVote from "../Common/ShowAnpiVote";
import MuiDialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import { API_URL, STATIC_URL, custom_axios } from "../Common/Common";
import FavoriteIcon from "@material-ui/icons/Favorite";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import styled from "styled-components";
import Link from "@material-ui/core/Link";
import Chip from "@material-ui/core/Chip";
import Search from "@material-ui/icons/Search";
import ShowUserInfo from "../Common/ShowUserInfo";
const Dialog = withStyles(() => ({
  paperWidthSm: {
    height: "100%",
  },
}))(MuiDialog);

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </div>
  );
}
const StyledFlexibleDiv = styled.div`
  display: flex;
`;
const StyledChip = styled(Chip)`
  margin-right: 5px;
  height: 15px;
  font-size: 8px;
`;
const StyledDialogTitle = styled(DialogTitle)`
  color: ${(props) => props.theme.palette.primary.contrastText};
  background-color: ${(props) => props.theme.palette.primary.main};
  padding: 5px 10px;
`;

const StyledLink = styled(Link)`
  cursor: default;
  &:hover {
    cursor: pointer;
  }
`;

const StyledReadedFont = styled.div`
  color: ${(props) => props.theme.palette.primary.main};
`;
const StyledSearch = styled(Search)`
  color: ${(props) => props.theme.palette.primary[300]};
`;
function ShowReadMember({ open, handleClose, messageid, roomid }) {
  const { history } = useReactRouter();

  const [localList, setLocalList] = useState([]);
  useEffect(() => {
    setLocalList([]);
  }, []);
  useEffect(() => {
    let honbuid = localStorage.getItem("honbuid");
    const proc = async () => {
      if (open.open) {
        var sql = "";
        if (open.kbn == "message") {
          sql = API_URL + `/getReadedUser?honbuid=${honbuid}&messageid=${messageid}`;
        } else if (open.kbn == "anpi") {
          sql = API_URL + `/getReadedAnpiUser?honbuid=${honbuid}&messageid=${messageid}&roomid=${roomid}`;
        } else {
          sql = API_URL + `/getReadedNewQueUser?honbuid=${honbuid}&messageid=${messageid}`;
        }
        const result = await custom_axios(sql);
        const status = result.status;
        if (status === 401) {
          history.replace("/loginError");
        }
        if (status === 200) {
          setLocalList(result.data.readedmember);
        }
      }
    };
    proc();
  }, [open, messageid]);
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [openAnpi, setOpenAnpi] = useState({ honbuid: "", isOpen: false });
  const handleAnpiVoteClose = () => {
    setOpenAnpi({ ...openAnpi, isOpen: false });
  };
  const MemoAnpiVote = useMemo(() => {
    return (
      <ShowAnpiVote
        open={openAnpi.isOpen}
        honbuid={openAnpi.honbuid}
        roomid={roomid}
        //openAnpiId={selectedAnpiid}
        handleClose={handleAnpiVoteClose}
        afterProc={() => {}}
        isEnabled={false}
      />
    );
  }, [openAnpi, roomid]);

  const [chatsFilter, setChatsFilter] = useState("");
  const handleFilterChange = (e) => {
    setChatsFilter(e.target.value);
  };
  const [showuser, setShowUser] = useState({ open: false, honbuid: "" });
  const handleMeClose = () => {
    setShowUser({ ...showuser, open: false });
  };
  const handleClickOpen = (honbuid) => {
    setShowUser({ open: true, honbuid: honbuid });
  };
  const MemoAvatar = useMemo(() => {
    return (
      <ShowUserInfo
        open={showuser.open}
        honbuid={showuser.honbuid}
        handleClose={handleMeClose}
        handleMeClose={handleClose}
      />
    );
  }, [showuser]);

  return (
    <Dialog open={open.open} onClose={handleClose} maxWidth={"sm"}>
      <StyledDialogTitle id="max-width-dialog-title">
        <div className="show-member-search">メンバー</div>
      </StyledDialogTitle>

      <DialogContent>
        {MemoAnpiVote}
        {MemoAvatar}
        <Paper square>
          <div className="chat-search-read">
            <div className="chat-search-icon">
              <StyledSearch />
            </div>
            <input
              placeholder="メンバー検索"
              value={chatsFilter}
              name="chatsFilter"
              onChange={handleFilterChange}
              className="chat-filter-search-field"
            />
          </div>
          <Tabs
            value={value}
            indicatorColor="primary"
            textColor="primary"
            onChange={handleChange}
            aria-label="disabled tabs example"
          >
            <Tab
              label={
                "既読(" +
                localList
                  .filter((e) => {
                    if (chatsFilter != "") {
                      if (
                        String(e.name).toLowerCase().indexOf(chatsFilter) !== -1 ||
                        String(e.subname).toLowerCase().indexOf(chatsFilter) !== -1
                      ) {
                        return e.isreaded === "1";
                      } else {
                        return false;
                      }
                    } else {
                      return e.isreaded === "1";
                    }
                  })
                  .length.toString() +
                ")"
              }
            />
            <Tab
              label={
                "未読(" +
                localList
                  .filter((e) => {
                    if (chatsFilter != "") {
                      if (
                        String(e.name).toLowerCase().indexOf(chatsFilter) !== -1 ||
                        String(e.subname).toLowerCase().indexOf(chatsFilter) !== -1
                      ) {
                        return e.isreaded === "0";
                      } else {
                        return false;
                      }
                    } else {
                      return e.isreaded === "0";
                    }
                  })
                  .length.toString() +
                ")"
              }
            />
          </Tabs>
          <TabPanel value={value} index={0}>
            <div className="read-member-list">
              {localList &&
                localList
                  .filter((e) => {
                    if (chatsFilter != "") {
                      if (
                        String(e.name).toLowerCase().indexOf(chatsFilter) !== -1 ||
                        String(e.subname).toLowerCase().indexOf(chatsFilter) !== -1
                      ) {
                        return e.isreaded === "1";
                      } else {
                        return false;
                      }
                    } else {
                      return e.isreaded === "1";
                    }
                  })
                  .map((e) => {
                    return (
                      <div key={e.honbuid} className="read-member-wrapper" onClick={() => handleClickOpen(e.honbuid)}>
                        <div className="read-member-content">
                          <img
                            src={STATIC_URL + `/downloadAvatar?honbuid=${e.honbuid}&fileno=${e.fileno}&size=small`}
                            width="30"
                            height="30"
                            alt=""
                            className="read-member-image"
                          />
                          <div className="read-member-text-wrapper">
                            <StyledFlexibleDiv>
                              {e.isvip == "1" && (
                                <StyledChip variant="outlined" color="primary" size="small" label="VIP"></StyledChip>
                              )}
                              {e.isadmin == "1" ? (
                                <StyledChip
                                  variant="outlined"
                                  color="secondary"
                                  size="small"
                                  label="システム管理"
                                ></StyledChip>
                              ) : e.isadmin == "2" ? (
                                <StyledChip
                                  variant="outlined"
                                  color="secondary"
                                  size="small"
                                  label="施設管理"
                                ></StyledChip>
                              ) : e.isadmin == "3" ? (
                                <StyledChip
                                  variant="outlined"
                                  color="secondary"
                                  size="small"
                                  label="部屋管理"
                                ></StyledChip>
                              ) : e.isadmin == "6" ? (
                                <StyledChip
                                  variant="outlined"
                                  color="secondary"
                                  size="small"
                                  label="施設横断部屋"
                                ></StyledChip>
                              ) : e.isadmin == "4" ? (
                                <StyledChip
                                  variant="outlined"
                                  color="secondary"
                                  size="small"
                                  label="ユーザー管理"
                                ></StyledChip>
                              ) : e.isadmin == "5" ? (
                                <StyledChip
                                  variant="outlined"
                                  color="secondary"
                                  size="small"
                                  label="部屋・ユーザー管理"
                                ></StyledChip>
                              ) : (
                                <div></div>
                              )}
                            </StyledFlexibleDiv>
                            <div className="read-member-name-wrapper">
                              <div className="show-read-member-name">
                                {e.name}
                                <span style={{ fontSize: "80%", marginLeft: "5px" }}>({e.subname})</span>
                              </div>
                            </div>
                            {/* <div className="read-member-name">{e.hpname}</div> */}
                          </div>

                          <div className="read-member-read">
                            {e.isquestion === "1" && <StyledReadedFont>回答済</StyledReadedFont>}
                            {e.isanpi === "1" && (
                              <StyledLink onClick={() => setOpenAnpi({ isOpen: true, honbuid: e.honbuid })}>
                                <StyledReadedFont>回答済</StyledReadedFont>
                              </StyledLink>
                            )}
                          </div>

                          <div className="goodedhonbuid">{e.gooded == "1" && <FavoriteIcon></FavoriteIcon>}</div>
                        </div>
                      </div>
                    );
                  })}
            </div>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <div className="read-member-list">
              {localList &&
                localList
                  .filter((e) => {
                    if (chatsFilter != "") {
                      if (
                        String(e.name).toLowerCase().indexOf(chatsFilter) !== -1 ||
                        String(e.subname).toLowerCase().indexOf(chatsFilter) !== -1
                      ) {
                        return e.isreaded === "0";
                      } else {
                        return false;
                      }
                    } else {
                      return e.isreaded === "0";
                    }
                  })
                  .map((e) => {
                    return (
                      <div key={e.honbuid} className="read-member-wrapper" onClick={() => handleClickOpen(e.honbuid)}>
                        <div className="read-member-content">
                          <img
                            src={STATIC_URL + `/downloadAvatar?honbuid=${e.honbuid}&fileno=${e.fileno}&size=small`}
                            width="30"
                            height="30"
                            alt=""
                            className="read-member-image"
                          />
                          <div className="read-member-text-wrapper">
                            <StyledFlexibleDiv>
                              {e.isvip == "1" && (
                                <StyledChip variant="outlined" color="primary" size="small" label="VIP"></StyledChip>
                              )}
                              {e.isadmin == "1" ? (
                                <StyledChip
                                  variant="outlined"
                                  color="secondary"
                                  size="small"
                                  label="システム管理"
                                ></StyledChip>
                              ) : e.isadmin == "2" ? (
                                <StyledChip
                                  variant="outlined"
                                  color="secondary"
                                  size="small"
                                  label="施設管理"
                                ></StyledChip>
                              ) : e.isadmin == "3" ? (
                                <StyledChip
                                  variant="outlined"
                                  color="secondary"
                                  size="small"
                                  label="部屋管理"
                                ></StyledChip>
                              ) : e.isadmin == "6" ? (
                                <StyledChip
                                  variant="outlined"
                                  color="secondary"
                                  size="small"
                                  label="施設横断部屋"
                                ></StyledChip>
                              ) : e.isadmin == "4" ? (
                                <StyledChip
                                  variant="outlined"
                                  color="secondary"
                                  size="small"
                                  label="ユーザー管理"
                                ></StyledChip>
                              ) : e.isadmin == "5" ? (
                                <StyledChip
                                  variant="outlined"
                                  color="secondary"
                                  size="small"
                                  label="部屋・ユーザー管理"
                                ></StyledChip>
                              ) : (
                                <div></div>
                              )}
                            </StyledFlexibleDiv>
                            <div className="read-member-name-wrapper">
                              <div className="show-read-member-name">
                                {e.name}
                                <span style={{ fontSize: "80%", marginLeft: "5px" }}>({e.subname})</span>
                              </div>
                            </div>
                            {/* <div className="read-member-name">{e.hpname}</div> */}
                          </div>
                          <div className="read-member-read">
                            {e.isquestion === "1" && <StyledReadedFont>回答済</StyledReadedFont>}
                            {e.isanpi === "1" && <StyledReadedFont>回答済</StyledReadedFont>}
                          </div>

                          <div className="goodedhonbuid">{e.gooded == "1" && <FavoriteIcon></FavoriteIcon>}</div>
                        </div>
                      </div>
                    );
                  })}
            </div>
          </TabPanel>
        </Paper>
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose} variant="outlined" color="secondary">
          閉じる (Esc)
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ShowReadMember;
