/* eslint-disable react/prop-types */
import "./ShowUserInfo.css";
import React, { useEffect, useState } from "react";
import useReactRouter from "use-react-router";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import { API_URL, STATIC_URL, custom_axios } from "./Common";
import FavoriteIcon from "@material-ui/icons/Favorite";
import PeopleIcon from "@material-ui/icons/People";
import styled from "styled-components";
const StyledDialogTitle = styled(DialogTitle)`
  color: ${(props) => props.theme.palette.primary.contrastText};
  background-color: ${(props) => props.theme.palette.primary.main};
`;

function ShowFavorMember({ open, handleClose }) {
  const { history } = useReactRouter();

  const [localList, setLocalList] = useState([]);
  useEffect(() => {
    setLocalList([]);
  }, []);
  useEffect(() => {
    let honbuid = localStorage.getItem("honbuid");
    const proc = async () => {
      if (open) {
        const result = await custom_axios(API_URL + `/GetFavorUser?honbuid=${honbuid}`);
        const status = result.status;
        //console.log("GetUser", result);
        if (status === 401) {
          history.replace("/loginError");
        }
        if (status === 200) {
          setLocalList(result.data.favormember);
        }
      }
    };
    proc();
  }, [open]);

  return (
    <Dialog open={open} onClose={handleClose} maxWidth={"sm"}>
      <StyledDialogTitle id="max-width-dialog-title">
        <div className="show-member-search">いいね状況（上位50）</div>
      </StyledDialogTitle>

      <DialogContent>
        <div className="read-member-list">
          {localList &&
            localList.map((e) => {
              return (
                <div key={e.honbuid} className="read-member-wrapper">
                  <div className="read-member-content">
                    <div className="rank-member-name-wrapper">
                      <div>{e.rank}</div>
                      <div>位</div>
                    </div>

                    <img
                      src={STATIC_URL + `/downloadAvatar?honbuid=${e.honbuid}&fileno=${e.fileno}&size=small`}
                      width="30"
                      height="30"
                      alt=""
                      className="read-member-image"
                      //style={isCurrent ? { border: "1px solid white" } : null}
                    />
                    <div className="read-member-text-wrapper">
                      <div className="read-member-name-wrapper">
                        <div className="read-member-name">{e.name}</div>
                      </div>
                    </div>
                    <div>
                      <div className="favor-member-text-wrapper">
                        <div className="favor-icon">
                          <FavoriteIcon></FavoriteIcon>
                        </div>
                        <div className="peple-count">{e.count}</div>
                      </div>
                      <div className="favor-member-text-wrapper">
                        <div className="people-icon">
                          <PeopleIcon></PeopleIcon>
                        </div>
                        <div className="peple-count">{e.ninzu}</div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose} variant="outlined" color="secondary">
          閉じる (Esc)
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ShowFavorMember;
