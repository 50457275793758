import React, { useContext, useState, useEffect, useMemo } from "react";
import "./ChatMember.css";
import { Store } from "../Store/store";
import { STATIC_URL, custom_axios, API_URL } from "../Common/Common";
import ShowUserInfo from "../Common/ShowUserInfo";
import ShowMember from "../Common/ShowMember";
import ShowNote from "../Common/ShowNote";
import useReactRouter from "use-react-router";
import ShowCommon from "../Common/ShowCommon";
import ShowPhotos from "../Common/ShowPhotos";
import ShowCall from "../Common/ShowCall";
import ShowReservationList from "../Common/ShowReservationList";
import ShowRoomAccount from "../Common/ShowRoomAccount";
import Search from "@material-ui/icons/Search";
import { withStyles } from "@material-ui/core/styles";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";
import Chip from "@material-ui/core/Chip";
//import Icon from "@material-ui/core";
import styled from "styled-components";
const Accordion = withStyles({
  root: {
    border: "1px solid rgba(0, 0, 0, .125)",
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles((theme) => ({
  root: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    borderBottom: "1px solid rgba(0, 0, 0, .125)",
    marginBottom: -1,
    padding: "0px 5px",
    minHeight: 30,
    "&$expanded": {
      minHeight: 30,
    },
  },
  content: {
    margin: "8px 0",
    "&$expanded": {
      margin: "8px 0",
    },
  },
  expanded: {},
}))(MuiAccordionSummary);

const AccordionDetails = withStyles(() => ({
  root: {
    padding: "0px",
    // padding: theme.spacing(2),
  },
}))(MuiAccordionDetails);

const StyledDivAccordion = styled.div`
  background-color: ${(props) => props.theme.palette.primary[500]};
  color: ${(props) => props.theme.palette.primary.contrastText};
  height: 39px;
`;

const StyledSearch = styled(Search)`
  color: ${(props) => props.theme.palette.primary[500]};
`;
const StyledFlexibleDiv = styled.div`
  display: flex;
`;
const StyledMemberWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  margin-bottom: 2px;
  background: #f7f8fc;
  cursor: pointer;
  transition: width 0.3s;
  -webkittransition: width 0.3s;
  position: relative;
  z-index: 0;
  &:hover {
    font-weight: 600;
    background-color: ${(props) => props.theme.palette.primary[100]};
    & .member-name {
      font-weight: 600;
    }
    & .member-image {
      border: 1px solid white;
    }
  }
`;

const StyledChip = styled(Chip)`
  margin-right: 5px;
  height: 15px;
  font-size: 8px;
`;

function ChatMember() {
  const { history } = useReactRouter();

  const { state, dispatch, hubConnection } = useContext(Store);
  const [open, setOpen] = useState(false);
  const [openPhoto, setOpenPhoto] = useState(false);
  const [openReservation, setOpenReservation] = useState(false);
  const [openNote, setOpenNote] = useState(false);
  const [openRoomName, setOpenRoomName] = useState(false);
  const [honbuid, setHonbuid] = useState("");
  const [chatsFilter, setChatsFilter] = useState("");
  const [localMemberList, setLocalMemberList] = useState([]);

  const [menuexpanded, setMenuExpanded] = React.useState(true);
  const [memberexpanded, setMemberExpanded] = React.useState(true);

  const [openCall, setOpenCall] = useState({ open: false, channel: "", uid: "", name: "", flag: "" });
  const handleCallClose = () => {
    setOpenCall({ ...openCall, open: false });
  };
  const handleChange = (e) => {
    setChatsFilter(e.target.value);
  };
  const handleClickOpen = (honbuid) => {
    setHonbuid(honbuid);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpenPhoto = (honbuid) => {
    setHonbuid(honbuid);
    setOpenPhoto(true);
  };

  const handlePhotoClose = () => {
    setOpenPhoto(false);
  };

  const handleClickOpenReservation = (honbuid) => {
    setHonbuid(honbuid);
    setOpenReservation(true);
  };

  const handleReservationClose = () => {
    setOpenReservation(false);
  };

  const handleNoteClose = () => {
    setOpenNote(false);
  };
  const handleRoomNameClose = () => {
    setOpenRoomName(false);
  };
  useEffect(() => {
    if (chatsFilter !== "") {
      const updateList = [
        {
          honbuid: state.honbuid,
          name: state.user_name,
          hpname: state.hpname,
          subname: state.subname,
          isavatar: state.isavatar,
          fileno: state.fileno,
          memo: state.memo,
          isvip: state.isvip,
          isadmin: state.isadmin,
          ismark: state.chatrooms.filter((f) => f.roomid === state.currentRoomid)[0].ismarkme,
        },
        ...state.chatrooms.filter((f) => f.roomid === state.currentRoomid)[0].memberlist,
      ].filter((item) => {
        if (chatsFilter && String(item.name).indexOf(chatsFilter) !== -1) {
          return true;
        }
        if (chatsFilter && String(item.hpname).indexOf(chatsFilter) !== -1) {
          return true;
        }
        if (chatsFilter && String(item.subname).indexOf(chatsFilter) !== -1) {
          return true;
        }

        return false;
      });
      setLocalMemberList(updateList);
    } else {
      setLocalMemberList([
        {
          honbuid: state.honbuid,
          name: state.user_name,
          hpname: state.hpname,
          subname: state.subname,
          isavatar: state.isavatar,
          fileno: state.fileno,
          memo: state.memo,
          isvip: state.isvip,
          isadmin: state.isadmin,
          ismark: state.chatrooms.filter((f) => f.roomid === state.currentRoomid)[0].ismarkme,
        },
        ...state.chatrooms.filter((f) => f.roomid === state.currentRoomid)[0].memberlist,
      ]);
    }
  }, [chatsFilter, state, memberexpanded]);

  const [openFa, setOpenFa] = useState(false);
  const handleFaClickOpen = () => {
    setOpenFa(true);
    handleClose();
  };
  const handleFaClose = () => {
    setOpenFa(false);
  };

  const [openModal, setOpenModal] = useState(false);
  const handleModalClose = () => {
    setOpenModal(false);
  };

  const [openDelModal, setOpenDelModal] = useState(false);
  const handleDelModalClose = () => {
    setOpenDelModal(false);
  };

  const [openHideModal, setOpenHideModal] = useState(false);
  const handleHideModalClose = () => {
    setOpenHideModal(false);
  };

  const handleLeaveRoom = async () => {
    setOpenModal(false);
    dispatch({ type: "CHANGE_ROOM", payload: { to: "", from: "" } });
    let honbuid = localStorage.getItem("honbuid");
    const result = await custom_axios(API_URL + `/delUserFromRoom?roomid=${state.currentRoomid}&honbuid=${honbuid}`);
    const status = result.status;
    if (status === 401) {
      history.replace("/loginError");
    }
    if (status === 200) {
      hubConnection.invoke(
        "SendMessage",
        state.currentRoomid,
        state.honbuid,
        state.user_name,
        state.user_name + " が退室しました",
        "information"
      );
    }
  };
  const handleDeleteRoom = async () => {
    setOpenDelModal(false);
    dispatch({ type: "CHANGE_ROOM", payload: { to: "", from: "" } });
    let honbuid = localStorage.getItem("honbuid");
    const result = await custom_axios(API_URL + `/delUserFromRoom?roomid=${state.currentRoomid}&honbuid=${honbuid}`);
    const status = result.status;
    if (status === 401) {
      history.replace("/loginError");
    }
  };

  const handleHideRoom = async () => {
    let honbuid = localStorage.getItem("honbuid");
    const apistr = API_URL + `/hideRoom?roomid=${state.currentRoomid}&honbuid=${honbuid}`;
    const result = await custom_axios(apistr);
    const status = result.status;
    if (status === 401) {
      history.replace("/loginError");
    }
    dispatch({ type: "HIDE_ROOM", payload: { roomid: state.currentRoomid } });
    setOpenHideModal(false);
  };

  const handleCall = async () => {
    let honbuid = localStorage.getItem("honbuid");
    setOpenCall({ open: true, channel: state.currentRoomid, uid: honbuid, name: state.user_name, flag: "create" });
  };
  const handleOnOff = async () => {
    let honbuid = localStorage.getItem("honbuid");
    //setAnchorEl(null);
    var result = await custom_axios(API_URL + `/changePushFromRoom?roomid=${state.currentRoomid}&honbuid=${honbuid}`);
    const status = result.status;
    if (status === 200) {
      dispatch({ type: "CHANGE_NOTIFICATION", payload: state.currentRoomid });
    }
    if (status === 401) {
      history.replace("/loginError");
    }
  };
  const handleStarOnOff = async () => {
    let honbuid = localStorage.getItem("honbuid");
    //setAnchorEl(null);
    var result = await custom_axios(API_URL + `/changeStarFromRoom?roomid=${state.currentRoomid}&honbuid=${honbuid}`);
    const status = result.status;
    if (status === 200) {
      dispatch({ type: "CHANGE_STAR", payload: state.currentRoomid });
    }
    if (status === 401) {
      history.replace("/loginError");
    }
  };
  let isOfficial = state.chatrooms.filter((f) => f.roomid === state.currentRoomid)[0].isofficial;
  //let isRoomName = state.chatrooms.filter((f) => f.roomid === state.currentRoomid)[0].roomname;
  let isMemberList = state.chatrooms.filter((f) => f.roomid === state.currentRoomid)[0].memberlist.length;
  let localisdirection = state.chatrooms.filter((f) => f.roomid === state.currentRoomid)[0].isdirection;
  const MemoAvatar = useMemo(() => {
    return <ShowUserInfo open={open} honbuid={honbuid} handleClose={handleClose} handleMeClose={() => {}} />;
  }, [open, honbuid]);

  const MemoRoomAccount = useMemo(() => {
    return <ShowRoomAccount open={openRoomName} handleClose={handleRoomNameClose} />;
  }, [openRoomName]);

  const MemoReservation = useMemo(() => {
    return <ShowReservationList open={openReservation} handleClose={handleReservationClose} />;
  }, [openReservation]);

  const MemoCall = useMemo(() => {
    return (
      <ShowCall
        open={openCall.open}
        handleClose={handleCallClose}
        channel={openCall.channel}
        uid={openCall.uid}
        name={openCall.name}
        flag={openCall.flag}
      />
    );
  }, [openCall]);

  const handleMemberClick = async () => {
    setMemberExpanded(!memberexpanded);
  };
  return (
    <div>
      {/* <div className="member-content">
        <div className="chat-member-num">メンバー</div>
        {state.chatrooms &&
          state.chatrooms.filter((f) => f.roomid === state.currentRoomid).length > 0 &&
          state.chatrooms.filter((f) => f.roomid === state.currentRoomid)[0].memberlist.length > 0 && (
            <div className="chat-member-num">
              {state.chatrooms.filter((f) => f.roomid === state.currentRoomid)[0].memberlist.length + 1}人
            </div>
          )}
      </div> */}
      <div className="chat-search-member">
        <div className="chat-search-icon">
          <StyledSearch></StyledSearch>
          {/* <Search className={classes.iconcolor} /> */}
        </div>
        <input
          placeholder="メンバー検索"
          value={chatsFilter}
          name="chatsFilter"
          onChange={handleChange}
          className="chat-search-field"
        />
      </div>
      {/* <ShowUserInfo open={open} honbuid={honbuid} handleClose={handleClose} /> */}
      {MemoCall}
      {MemoAvatar}
      {MemoRoomAccount}
      {MemoReservation}
      <ShowPhotos
        open={openPhoto}
        handleClose={handlePhotoClose}
        members={[
          {
            value: state.honbuid,
            label: state.user_name,
          },
          ...state.chatrooms
            .filter((f) => f.roomid === state.currentRoomid)[0]
            .memberlist.map((e) => {
              return { label: e.name, value: e.honbuid };
            }),
        ]}
      />

      <ShowNote open={openNote} handleClose={handleNoteClose}></ShowNote>

      <div className="member-list">
        {state.chatrooms.filter((f) => f.roomid === state.currentRoomid)[0].ismyroom == "1" ? (
          <Accordion square expanded={menuexpanded} onChange={() => setMenuExpanded(!menuexpanded)}>
            <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
              <div className="ac-group-between">
                <div className="ac-group">
                  <div>メニュー</div>
                </div>
                {menuexpanded ? <FaAngleUp /> : <FaAngleDown />}
              </div>
            </AccordionSummary>
            {state.chatrooms.filter((f) => f.roomid === state.currentRoomid)[0].memberlist.length > 1 && (
              <AccordionDetails>
                <StyledMemberWrapper onClick={() => setOpenRoomName(true)}>
                  <div className="menu-content">部屋情報</div>
                </StyledMemberWrapper>
              </AccordionDetails>
            )}
            <AccordionDetails>
              <StyledMemberWrapper onClick={() => setOpenNote(true)}>
                <div className="menu-content">ノート</div>
              </StyledMemberWrapper>
            </AccordionDetails>
            <AccordionDetails>
              <StyledMemberWrapper onClick={handleClickOpenReservation}>
                <div className="menu-content">メッセージ予約</div>
              </StyledMemberWrapper>
            </AccordionDetails>
            <AccordionDetails>
              <StyledMemberWrapper onClick={handleClickOpenPhoto}>
                <div className="menu-content">ファイル/写真</div>
              </StyledMemberWrapper>
            </AccordionDetails>
          </Accordion>
        ) : (
          <Accordion square expanded={menuexpanded} onChange={() => setMenuExpanded(!menuexpanded)}>
            <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
              <div className="ac-group-between">
                <div className="ac-group">
                  <div>メニュー</div>
                </div>
                {menuexpanded ? <FaAngleUp /> : <FaAngleDown />}
              </div>
            </AccordionSummary>
            {state.chatrooms.filter((f) => f.roomid === state.currentRoomid)[0].memberlist.length > 1 && (
              <AccordionDetails>
                <StyledMemberWrapper onClick={() => setOpenRoomName(true)}>
                  <div className="menu-content">部屋情報</div>
                </StyledMemberWrapper>
              </AccordionDetails>
            )}
            <AccordionDetails>
              <StyledMemberWrapper onClick={() => setOpenNote(true)}>
                <div className="menu-content">ノート</div>
              </StyledMemberWrapper>
            </AccordionDetails>
            <AccordionDetails>
              <StyledMemberWrapper onClick={handleClickOpenReservation}>
                <div className="menu-content">メッセージ予約</div>
              </StyledMemberWrapper>
            </AccordionDetails>
            <AccordionDetails>
              <StyledMemberWrapper onClick={handleClickOpenPhoto}>
                <div className="menu-content">ファイル/写真</div>
              </StyledMemberWrapper>
            </AccordionDetails>
            {isOfficial == "0" && ["1", "2", "3", "5", "6"].includes(state.isadmin) && (
              <AccordionDetails>
                <StyledMemberWrapper onClick={handleFaClickOpen}>
                  <div className="menu-content">メンバー追加</div>
                </StyledMemberWrapper>
              </AccordionDetails>
            )}
            <AccordionDetails>
              <StyledMemberWrapper onClick={handleOnOff}>
                <div className="menu-content">通知ON/OFF</div>
              </StyledMemberWrapper>
            </AccordionDetails>
            {state.honbuid == "133020" ? (
              <AccordionDetails>
                <StyledMemberWrapper onClick={handleCall}>
                  <div className="menu-content">通話(テスト)</div>
                </StyledMemberWrapper>
              </AccordionDetails>
            ) : null}
            <AccordionDetails>
              <StyledMemberWrapper onClick={handleStarOnOff}>
                <div className="menu-content">ピン留めON/OFF</div>
              </StyledMemberWrapper>
            </AccordionDetails>
            {isOfficial == "0" && localisdirection == "0" && (
              <AccordionDetails>
                <StyledMemberWrapper
                  onClick={() => {
                    handleClose();
                    setOpenHideModal(true);
                  }}
                >
                  <div className="menu-content">非表示</div>
                </StyledMemberWrapper>
              </AccordionDetails>
            )}
            {isOfficial == "0" && localisdirection == "0" && isMemberList > "1" && (
              <AccordionDetails>
                <StyledMemberWrapper
                  onClick={() => {
                    handleClose();
                    setOpenModal(true);
                  }}
                >
                  <div className="menu-content">会話から抜ける</div>
                </StyledMemberWrapper>
              </AccordionDetails>
            )}
            {isOfficial == "0" && localisdirection == "0" && isMemberList == "0" && (
              <AccordionDetails>
                <StyledMemberWrapper
                  onClick={() => {
                    handleClose();
                    setOpenDelModal(true);
                  }}
                >
                  <div className="menu-content">削除</div>
                </StyledMemberWrapper>
              </AccordionDetails>
            )}
          </Accordion>
        )}
        <div>
          <StyledDivAccordion className="ac-group-between" onClick={() => handleMemberClick()}>
            <div className="ac-group" style={{ marginLeft: "6px" }}>
              <div>メンバー</div>
            </div>
            <div style={{ marginRight: "6px" }}>{memberexpanded ? <FaAngleUp /> : <FaAngleDown />}</div>
          </StyledDivAccordion>
          <div style={{ display: memberexpanded ? "" : "none" }}>
            {localMemberList.map((e) => (
              <StyledMemberWrapper key={e.honbuid} onClick={() => handleClickOpen(e.honbuid, e.isvip, e.isadmin)}>
                <div className="member-content">
                  <img
                    src={STATIC_URL + `/downloadAvatar?honbuid=${e.honbuid}&fileno=${e.fileno}&size=small`}
                    width="30"
                    height="30"
                    alt=""
                    className="member-image"
                    //style={isCurrent ? { border: "1px solid white" } : null}
                  />
                  <div className="member-text-vip-wrapper">
                    <StyledFlexibleDiv>
                      {e.ismark == "1" && localisdirection == "1" && (
                        <StyledChip variant="outlined" size="small" label="発言権"></StyledChip>
                      )}
                      {e.isvip == "1" && (
                        <StyledChip variant="outlined" color="primary" size="small" label="VIP"></StyledChip>
                      )}
                      {e.isadmin == "1" ? (
                        <StyledChip variant="outlined" color="secondary" size="small" label="システム管理"></StyledChip>
                      ) : e.isadmin == "2" ? (
                        <StyledChip variant="outlined" color="secondary" size="small" label="施設管理"></StyledChip>
                      ) : e.isadmin == "3" ? (
                        <StyledChip variant="outlined" color="secondary" size="small" label="部屋管理"></StyledChip>
                      ) : e.isadmin == "6" ? (
                        <StyledChip variant="outlined" color="secondary" size="small" label="施設横断部屋"></StyledChip>
                      ) : e.isadmin == "4" ? (
                        <StyledChip variant="outlined" color="secondary" size="small" label="ユーザー管理"></StyledChip>
                      ) : e.isadmin == "5" ? (
                        <StyledChip
                          variant="outlined"
                          color="secondary"
                          size="small"
                          label="部屋・ユーザー管理"
                        ></StyledChip>
                      ) : (
                        <div></div>
                      )}
                    </StyledFlexibleDiv>
                    <div className="member-name-wrapper">
                      <div>
                        {e.name}
                        <span style={{ fontSize: "80%", marginLeft: "5px" }}>({e.subname})</span>
                      </div>
                    </div>
                  </div>
                </div>
              </StyledMemberWrapper>
            ))}
          </div>
        </div>
      </div>
      <ShowMember open={openFa} handleClose={handleFaClose} isadduser={true} roomid={state.currentRoomid} />
      <ShowCommon
        open={openModal}
        title="会話から抜けますか？"
        handleClose={handleModalClose}
        handleCallBack={handleLeaveRoom}
      ></ShowCommon>
      <ShowCommon
        open={openDelModal}
        title="削除しますか？"
        handleClose={handleDelModalClose}
        handleCallBack={handleDeleteRoom}
      ></ShowCommon>
      <ShowCommon
        open={openHideModal}
        title="非表示にしますか？"
        handleClose={handleHideModalClose}
        handleCallBack={handleHideRoom}
      ></ShowCommon>
    </div>
  );
}

export default ChatMember;
