/* eslint-disable react/prop-types */
import React, { useEffect } from "react";
import styled from "styled-components";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import AddBoxIcon from "@material-ui/icons/AddBox";

const StyledRoot = styled.div`
  border: 1px solid;
  border-radius: 5px;
  padding: 10px;
  margin-top: 10px;
`;

const StyledFlexEndDiv = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const StyledSelect = styled(Select)`
  margin-top: 2px;
  margin-bottom: 10px;
`;

function ShowAnpiCreateDetail({ listlength, anpidetail, editTitle, editKbn, editSu, editSeq, editText, isanpi }) {
  useEffect(() => {
    //console.log("anpidetail2", anpidetail);
  }, []);

  const handleNoPlus = () => {
    editSu("plus", anpidetail.seq);
  };
  const handleNoMinus = () => {
    editSu("minus", anpidetail.seq);
  };
  const handleSeqPlus = () => {
    editSeq("plus");
  };
  const handleSeqMinus = () => {
    editSeq("minus");
  };

  const handleKbnChange = (event) => {
    editKbn(anpidetail.seq, event.target.value);
  };
  return (
    <div>
      <StyledRoot>
        <div>
          <TextField
            id="outlined-multiline-static"
            label="質問"
            InputLabelProps={{
              shrink: true,
            }}
            variant="outlined"
            defaultValue={anpidetail.title}
            onChange={(e) => editTitle(anpidetail.seq, e.target.value)}
            margin="dense"
            style={{ width: "100%" }}
          />
          <StyledSelect
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={anpidetail.kbn}
            //defaultValue={anpidetail.kbn}
            onChange={handleKbnChange}
          >
            <MenuItem value={"radio"}>単一選択</MenuItem>
            {isanpi == "0" ? <MenuItem value={"multi"}>複数選択</MenuItem> : null}
            <MenuItem value={"text"}>フリー入力</MenuItem>
          </StyledSelect>
          {anpidetail.kbn != "text" ? (
            anpidetail.list.map((e, index) => {
              return (
                <div key={index}>
                  <TextField
                    id="outlined-multiline-static"
                    label={"選択肢" + e.no}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined"
                    defaultValue={e.text}
                    onChange={(ev) => editText(anpidetail.seq, e.no, ev.target.value)}
                    margin="dense"
                    style={{ width: "100%" }}
                  />
                </div>
              );
            })
          ) : (
            <div></div>
          )}
        </div>
        {anpidetail.kbn != "text" ? (
          <StyledFlexEndDiv>
            <IconButton aria-label="delete" size="small" onClick={handleNoPlus}>
              <AddBoxIcon fontSize="inherit" />
            </IconButton>
            {anpidetail.list.length > 2 && (
              <IconButton aria-label="delete" size="small" onClick={handleNoMinus}>
                <DeleteIcon fontSize="inherit" />
              </IconButton>
            )}
          </StyledFlexEndDiv>
        ) : (
          <div></div>
        )}
      </StyledRoot>
      <StyledFlexEndDiv>
        {listlength == 1 || (listlength > 1 && anpidetail.seq == listlength && listlength < 20) ? (
          <IconButton aria-label="delete" size="medium" onClick={handleSeqPlus}>
            <AddBoxIcon fontSize="inherit" />
          </IconButton>
        ) : anpidetail.seq == listlength && listlength >= 20 ? (
          <div>これ以上追加できません。</div>
        ) : (
          <div></div>
        )}
        {listlength > 1 && anpidetail.seq == listlength && (
          <IconButton aria-label="delete" size="medium" onClick={handleSeqMinus}>
            <DeleteIcon fontSize="inherit" />
          </IconButton>
        )}
      </StyledFlexEndDiv>
    </div>
  );
}
export default ShowAnpiCreateDetail;
