/* eslint-disable react/prop-types */
import "./ShowUserInfo.css";
import React, { useEffect, useState, useRef, useContext } from "react";
import useReactRouter from "use-react-router";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import { API_URL, custom_axios_post, custom_axios } from "../Common/Common";
import { Store } from "../Store/store";
import TextField from "@material-ui/core/TextField";
import MultiSelect from "react-multi-select-component";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import styled from "styled-components";
import ShowAnpiCreateDetail from "../Common/ShowAnpiCreateDetail";
import Snackbar from "@material-ui/core/Snackbar";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

const StyledDialogTitle = styled(DialogTitle)`
  color: ${(props) => props.theme.palette.primary.contrastText};
  background-color: ${(props) => props.theme.palette.primary.main};
  padding: 5px 10px;
`;
const StyledButton = styled(Button)`
  color: ${(props) => props.theme.palette.primary.contrastText};
  background-color: ${(props) => props.theme.palette.primary.main};
`;
const testlist = [
  {
    seq: "1",
    kbn: "radio",
    title: "",
    //su: "2",
    list: [
      { no: "1", text: "" },
      { no: "2", text: "" },
    ],
  },
];
const StyledSelect = styled(Select)`
  margin-top: 2px;
  margin-bottom: 10px;
  margin-left: 10px;
  margin-right: 10px;
`;

const StyledRoot = styled.div`
  border: 1px solid;
  border-radius: 5px;
  padding: 10px;
  margin-top: 10px;
`;
function ShowAnpiCreate({ open, openAnpiId, handleClose, afterProc, isCopy }) {
  const { history } = useReactRouter();
  const { state } = useContext(Store);
  const processingRef = useRef(false);

  const [options, setOptions] = useState([]);
  const [selectedhp, setSelectedHp] = useState([]);

  const [unitoptions, setUnitOptions] = useState([]);
  const [selectedunit, setSelectedUnit] = useState([]);

  const [selectedkbn, setSelectedKbn] = useState("unit");

  const [prefoptions, setPrefOptions] = useState([]);
  const [selectedpref, setSelectedPref] = useState([]);
  const [isrenkei, setIsRenkei] = useState("0");
  const [maxint, setMaxInt] = useState("");

  const [AnpiTitle, setAnpiTitle] = useState("");
  const [localList, setLocalList] = useState([]);

  const [errorsnackopen, setErrorSnackOpen] = useState(false);

  const mainproc = async () => {
    if (openAnpiId == null) {
      setLocalList(testlist);
      setSelectedKbn("unit");
      return;
    }
    setIsRenkei(openAnpiId.isrenkei);

    const result = await custom_axios(API_URL + `/GetAnpiOne?anpiid=${openAnpiId.anpiid}&hpcode=${state.hpcode}`);
    const status = result.status;
    if (status === 401) {
      history.replace("/loginError");
    }
    if (status === 200) {
      var list = result.data.list;
      var hplist = result.data.hplist;
      var unitmasterlist = result.data.unitmasterlist;
      var unitlist = result.data.unitlist;
      var preflist = result.data.preflist;
      var resmaxint = result.data.maxint;

      setLocalList(list);
      setAnpiTitle(openAnpiId.title);
      setSelectedKbn(openAnpiId.kbn);
      if (hplist.length == 0) {
        setSelectedUnit([{ label: state.hpcode + ":" + state.hpname, value: state.hpcode }]);
      } else {
        setSelectedHp(hplist);
      }

      setUnitOptions(
        unitmasterlist.map((e) => {
          return { label: e.label, value: e.value };
        })
      );
      if (unitlist.length == 0) {
        setSelectedUnit([{ label: state.unitcode + ":" + state.unitname, value: state.unitcode }]);
      } else {
        setSelectedUnit(unitlist);
      }
      setSelectedPref(preflist);
      setMaxInt(resmaxint);
    }
  };
  const kbnchangeproc = async (kbn, localhps) => {
    let joinhps = localhps
      .map((e) => {
        return e.value;
      })
      .join("_");

    if (joinhps.length == 0) {
      return;
    }

    const reqapi = API_URL + `/GetAnpiKbns?kbn=${kbn}&hascode=1&hpcodes=${joinhps}`;
    const result = await custom_axios(reqapi);
    const status = result.status;
    if (status === 401) {
      history.replace("/loginError");
    }
    if (status === 200) {
      setUnitOptions(
        result.data.units.map((e) => {
          return { label: e.unitname, value: e.unitcode };
        })
      );
    }
  };

  const proc = async (localhps) => {
    let joinhps = localhps
      .map((e) => {
        return e.value;
      })
      .join("_");

    if (joinhps.length == 0) {
      return;
    }
    //console.log("selected procの中身", joinhps);
    let honbuid = localStorage.getItem("honbuid");

    const reqapi = API_URL + `/GetAdminHpsUnits?honbuid=${honbuid}&hascode=1&hpcodes=${joinhps}&kbn=${selectedkbn}`;
    const result = await custom_axios(reqapi);
    const status = result.status;
    if (status === 401) {
      history.replace("/loginError");
    }
    if (status === 200) {
      setOptions(
        result.data.hps.map((e) => {
          return { label: e.hpname, value: e.hpcode };
        })
      );

      setUnitOptions(
        result.data.units.map((e) => {
          return { label: e.unitname, value: e.unitcode };
        })
      );

      setPrefOptions(
        result.data.preflist.map((e) => {
          return { label: e.prefname, value: e.prefcode };
        })
      );
    }
  };
  useEffect(() => {
    if (open) {
      setLocalList([]);
      setSelectedHp([{ label: state.hpcode + ":" + state.hpname, value: state.hpcode }]);
      setSelectedUnit([{ label: state.unitcode + ":" + state.unitname, value: state.unitcode }]);
      setAnpiTitle("");
      proc([{ label: state.hpname, value: state.hpcode }]);
      /* 修正の場合は、DBに保存されたデータを取得するようにする */

      mainproc();
    }
  }, [open]);
  const editKbn = (seq, kbn) => {
    setLocalList(
      localList.map((e) => {
        if (e.seq == seq) {
          return { ...e, kbn: kbn };
        }
        return e;
      })
    );
  };
  const editTitle = (seq, title) => {
    setLocalList(
      localList.map((e) => {
        if (e.seq == seq) {
          return { ...e, title: title };
        }
        return e;
      })
    );
  };
  const editText = (seq, no, text) => {
    setLocalList(
      localList.map((e) => {
        if (e.seq == seq) {
          return {
            ...e,
            list: e.list.map((n) => {
              if (n.no == no) {
                return { ...n, text: text };
              }
              return n;
            }),
          };
        }
        return e;
      })
    );
  };
  const editSu = (flag, seq) => {
    if (flag == "plus") {
      setLocalList(
        localList.map((e) => {
          if (e.seq == seq) {
            return { ...e, list: [...e.list, { no: String(e.list.length + 1), text: "" }] };
          }
          return e;
        })
      );
    } else {
      setLocalList(
        localList.map((e) => {
          if (e.seq == seq) {
            return { ...e, list: [...e.list.splice(0, e.list.length - 1)] };
          }
          return e;
        })
      );
    }
  };
  const editSeq = (flag) => {
    if (flag == "plus") {
      setLocalList([
        ...localList,
        {
          seq: String(localList.length + 1),
          kbn: "radio",
          title: "",
          //su: "2",
          list: [
            { no: "1", text: "" },
            { no: "2", text: "" },
          ],
        },
      ]);
    } else {
      setLocalList([...localList.splice(0, localList.length - 1)]);
    }
  };
  const handleSave = async (isSave) => {
    /* 施設・部署が選択されていなかったらエラー */
    if (selectedhp.length == 0 || selectedunit.length == 0) {
      setErrorSnackOpen(true);
      return;
    }
    /* 多重クリック防止 */
    if (processingRef.current) return;
    processingRef.current = true;
    let honbuid = localStorage.getItem("honbuid");

    let seqlist = [];
    let nolist = [];
    let hplist = selectedhp.map((v) => {
      return v.value;
    });
    let unitlist = selectedunit.map((v) => {
      return v.value;
    });

    let preflist = selectedpref.map((v) => {
      return v.value;
    });

    for (let i = 0; i < localList.length; i++) {
      seqlist.push({ seq: localList[i].seq, title: localList[i].title, kbn: localList[i].kbn });
      if (localList[i].kbn == "radio") {
        for (let j = 0; j < localList[i].list.length; j++) {
          nolist.push({ seq: localList[i].seq, no: localList[i].list[j].no, text: localList[i].list[j].text });
        }
      } else if (localList[i].kbn == "text") {
        nolist.push({ seq: localList[i].seq, no: "0", text: "" });
      }
    }

    const body = {
      anpiid: isCopy ? "-1" : openAnpiId == null ? "-1" : openAnpiId.istemplate == "1" ? "-1" : openAnpiId.anpiid,
      honbuid: honbuid,
      name: state.user_name,
      title: AnpiTitle,
      seqlist: seqlist, //Json型をそのまま投げる。
      nolist: nolist, //Json型をそのまま投げる。
      hplist: hplist, //Json型をそのまま投げる。
      unitlist: unitlist, //Json型をそのまま投げる。
      istemp: isSave ? "1" : "0", //一時保存できるように追加
      isrenkei: isrenkei,
      preflist: preflist,
      maxint: maxint,
      kbn: selectedkbn,
    };
    //console.log("post前", body);
    const result = await custom_axios_post(API_URL + "/createAnpi", body);

    processingRef.current = false;

    afterProc();

    if (result.status === 401) {
      history.replace("/loginError");
    }
    handleClose();
  };

  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    let tempIsVisible = true;
    if (AnpiTitle.length > 0) {
      for (let i = 0; i < localList.length; i++) {
        if (localList[i].title == "") {
          tempIsVisible = false;
        }
        if (localList[i].kbn == "radio") {
          for (let j = 0; j < localList[i].list.length; j++) {
            if (localList[i].list[j].text == "") {
              tempIsVisible = false;
            }
          }
        }
      }
    } else {
      tempIsVisible = false;
    }

    if (isrenkei == "1") {
      if (selectedpref.length == 0) {
        tempIsVisible = false;
      }
      if (maxint == "") {
        tempIsVisible = false;
      }
    }
    //console.log("check", tempIsVisible, localList);
    setIsVisible(tempIsVisible);
  }, [AnpiTitle, localList, isrenkei, selectedpref, maxint]);

  const handleRenkeiChange = (event) => {
    setIsRenkei(event.target.value);
  };
  return (
    <Dialog open={open} onClose={handleClose} maxWidth={"sm"} fullWidth>
      <StyledDialogTitle id="max-width-dialog-title">
        <div className="show-member-search">
          <div className="show-member-search">安否作成</div>
          <div className="question-title-actions">
            <StyledButton onClick={handleClose} variant="outlined">
              閉じる (Esc)
            </StyledButton>
          </div>
        </div>
      </StyledDialogTitle>

      <DialogContent>
        <Snackbar
          open={errorsnackopen}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          autoHideDuration={6000}
          onClose={() => {
            setErrorSnackOpen(false);
          }}
          message="対象が選択されていません。"
        ></Snackbar>
        <div className="question-create-wrap">
          <div className="anpi-subarea">
            施設
            <div className="multi-select">
              <MultiSelect
                options={options}
                value={selectedhp}
                onChange={(e) => {
                  proc(e);
                  setSelectedUnit([]);
                  setSelectedHp(e);
                }}
                labelledBy={"Select"}
                overrideStrings={{
                  selectSomeItems: "選択...",
                  allItemsAreSelected: "全選択",
                  selectAll: "全選択",
                  search: "検索",
                  clearSearch: "検索クリア",
                }}
                filterOptions={(options, filter) => {
                  if (!filter) {
                    return options;
                  }
                  const re = new RegExp(filter, "i");
                  return options.filter(({ label }) => label && label.match(re));
                }}
              />
            </div>
          </div>
          <div className="anpi-subarea">
            種類
            <div className="single-select">
              <Select
                //options={unitoptions}
                value={selectedkbn}
                onChange={(e) => {
                  setSelectedKbn(e.target.value);
                  setSelectedUnit([]);
                  kbnchangeproc(e.target.value, selectedhp);
                }}
              >
                <MenuItem value="unit">部署</MenuItem>
                <MenuItem value="room">グループ</MenuItem>
              </Select>
            </div>
          </div>
          <div className="anpi-subarea">
            対象
            <div className="multi-select">
              <MultiSelect
                options={unitoptions}
                value={selectedunit}
                onChange={(e) => {
                  setSelectedUnit(e);
                }}
                labelledBy={"Select"}
                overrideStrings={{
                  selectSomeItems: "選択...",
                  allItemsAreSelected: "全選択",
                  selectAll: "全選択",
                  search: "検索",
                  clearSearch: "検索クリア",
                }}
                filterOptions={(options, filter) => {
                  if (!filter) {
                    return options;
                  }
                  const re = new RegExp(filter, "i");
                  return options.filter(({ label }) => label && label.match(re));
                }}
              />
            </div>
          </div>
          <TextField
            id="outlined-multiline-static"
            label="タイトル"
            InputLabelProps={{
              shrink: true,
            }}
            variant="outlined"
            value={AnpiTitle}
            onChange={(e) => setAnpiTitle(e.target.value)}
            margin="dense"
            //style={{ width: "300px" }}
          />
          <StyledRoot>
            <div className="anpi-subarea">
              気象庁連携
              <StyledSelect
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={isrenkei}
                //defaultValue={anpidetail.kbn}
                onChange={handleRenkeiChange}
              >
                <MenuItem value={"0"}>しない</MenuItem>
                <MenuItem value={"1"}>する</MenuItem>
              </StyledSelect>
            </div>

            {isrenkei == "1" ? (
              <div>
                <div>
                  気象庁連携を「する」にして保存すると、「エリア」で選択した地域で「最大震度」以上の地震が発生した場合に、安否確認を自動で送信する事ができます。※1時間以内に同じ条件で地震が発生した場合は自動送信されません。
                </div>
                <div className="anpi-subarea">
                  エリア
                  <div className="multi-select">
                    <MultiSelect
                      options={prefoptions}
                      value={selectedpref}
                      onChange={(e) => {
                        setSelectedPref(e);
                      }}
                      labelledBy={"Select"}
                      overrideStrings={{
                        selectSomeItems: "選択...",
                        allItemsAreSelected: "全選択",
                        selectAll: "全選択",
                        search: "検索",
                        clearSearch: "検索クリア",
                      }}
                      filterOptions={(options, filter) => {
                        if (!filter) {
                          return options;
                        }
                        const re = new RegExp(filter, "i");
                        return options.filter(({ label }) => label && label.match(re));
                      }}
                    />
                  </div>
                </div>

                {/* <TextField
                id="outlined-multiline-static"
                label="最大震度"
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                defaultValue={maxint}
                onChange={(e) => setMaxInt(e.target.value)}
                margin="dense"
                style={{ width: "100%" }}
              /> */}

                <div className="anpi-subarea">
                  最大震度
                  <StyledSelect
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={maxint}
                    //defaultValue={anpidetail.kbn}
                    onChange={(e) => setMaxInt(e.target.value)}
                  >
                    <MenuItem value={"1"}>1</MenuItem>
                    <MenuItem value={"2"}>2</MenuItem>
                    <MenuItem value={"3"}>3</MenuItem>
                    <MenuItem value={"4"}>4</MenuItem>
                    <MenuItem value={"5"}>5</MenuItem>
                    <MenuItem value={"6"}>6</MenuItem>
                    <MenuItem value={"7"}>7</MenuItem>
                    <MenuItem value={"8"}>8</MenuItem>
                  </StyledSelect>
                  以上
                </div>
              </div>
            ) : (
              <div></div>
            )}
          </StyledRoot>

          {localList &&
            localList.map((e, index) => {
              return (
                <ShowAnpiCreateDetail
                  key={index}
                  listlength={localList.length}
                  anpidetail={e}
                  editTitle={editTitle}
                  editKbn={editKbn}
                  editSu={editSu}
                  editSeq={editSeq}
                  editText={editText}
                  isanpi="1"
                />
              );
            })}
        </div>
      </DialogContent>

      <DialogActions>
        <Button
          disabled={isVisible ? false : true}
          style={{ margin: "0 auto" }}
          variant="contained"
          color="primary"
          onClick={() => handleSave(true)}
          startIcon={<MailOutlineIcon />}
        >
          {isrenkei == "0" ? "一時保存" : "保存"}
        </Button>
        {isrenkei == "0" && (
          <Button
            disabled={isVisible ? false : true}
            style={{ margin: "0 auto" }}
            variant="contained"
            color="primary"
            onClick={() => handleSave(false)}
            startIcon={<MailOutlineIcon />}
          >
            送信
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}

export default ShowAnpiCreate;
