/* eslint-disable react/prop-types */
/* eslint-disable react/react-in-jsx-scope */
import "./ShowUserInfo.css";
import React from "react";
import { STATIC_URL } from "../Common/Common";
import Dialog from "@material-ui/core/Dialog";
import "video-react/dist/video-react.css";
import { Player } from "video-react";

function ShowVideoInfo({ open, messageid, handleClose }) {
  return (
    <Dialog open={open} onClose={handleClose} maxWidth={"lg"}>
      <div className="show-video">
        <Player src={STATIC_URL + `/downloadVideo?messageID=${messageid}&size=big`} />
      </div>
    </Dialog>
  );
}
export default ShowVideoInfo;
