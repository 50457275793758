/* eslint-disable react/prop-types */
import "./ShowUserInfo.css";
import React, { useEffect, useState, useRef, useContext } from "react";
import useReactRouter from "use-react-router";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import { API_URL, custom_axios_post, custom_axios } from "../Common/Common";
import { Store } from "../Store/store";
import TextField from "@material-ui/core/TextField";
//import MultiSelect from "react-multi-select-component";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import styled from "styled-components";
import ShowAnpiCreateDetail from "../Common/ShowAnpiCreateDetail";

const StyledDialogTitle = styled(DialogTitle)`
  color: ${(props) => props.theme.palette.primary.contrastText};
  background-color: ${(props) => props.theme.palette.primary.main};
  padding: 5px 10px;
`;
const StyledButton = styled(Button)`
  color: ${(props) => props.theme.palette.primary.contrastText};
  background-color: ${(props) => props.theme.palette.primary.main};
`;
const testlist = [
  {
    seq: "1",
    kbn: "radio",
    title: "",
    //su: "2",
    list: [
      { no: "1", text: "" },
      { no: "2", text: "" },
    ],
  },
];
function ShowNewQueCreate({ open, openAnpiId, handleClose, afterProc }) {
  const { history } = useReactRouter();
  const { state } = useContext(Store);
  const processingRef = useRef(false);

  //const [options, setOptions] = useState([]);
  //const [selectedhp, setSelectedHp] = useState([]);

  const [AnpiTitle, setAnpiTitle] = useState("");
  const [localList, setLocalList] = useState([]);

  const mainproc = async () => {
    if (openAnpiId == null) {
      setLocalList(testlist);
      return;
    }
    const result = await custom_axios(API_URL + `/GetNewQueOne?anpiid=${openAnpiId.anpiid}`);
    const status = result.status;
    if (status === 401) {
      history.replace("/loginError");
    }
    if (status === 200) {
      var list = result.data.list;
      setLocalList(list);
      setAnpiTitle(openAnpiId.title);
    }
  };

  useEffect(() => {
    if (open) {
      setLocalList([]);
      //setSelectedHp([{ label: state.hpcode + ":" + state.hpname, value: state.hpcode }]);
      setAnpiTitle("");
      /* 修正の場合は、DBに保存されたデータを取得するようにする */

      mainproc();
    }
  }, [open]);
  const editKbn = (seq, kbn) => {
    setLocalList(
      localList.map((e) => {
        if (e.seq == seq) {
          return { ...e, kbn: kbn };
        }
        return e;
      })
    );
  };
  const editTitle = (seq, title) => {
    setLocalList(
      localList.map((e) => {
        if (e.seq == seq) {
          return { ...e, title: title };
        }
        return e;
      })
    );
  };
  const editText = (seq, no, text) => {
    setLocalList(
      localList.map((e) => {
        if (e.seq == seq) {
          return {
            ...e,
            list: e.list.map((n) => {
              if (n.no == no) {
                return { ...n, text: text };
              }
              return n;
            }),
          };
        }
        return e;
      })
    );
  };
  const editSu = (flag, seq) => {
    if (flag == "plus") {
      setLocalList(
        localList.map((e) => {
          if (e.seq == seq) {
            return { ...e, list: [...e.list, { no: String(e.list.length + 1), text: "" }] };
          }
          return e;
        })
      );
    } else {
      setLocalList(
        localList.map((e) => {
          if (e.seq == seq) {
            return { ...e, list: [...e.list.splice(0, e.list.length - 1)] };
          }
          return e;
        })
      );
    }
  };
  const editSeq = (flag) => {
    if (flag == "plus") {
      setLocalList([
        ...localList,
        {
          seq: String(localList.length + 1),
          kbn: "radio",
          title: "",
          //su: "2",
          list: [
            { no: "1", text: "" },
            { no: "2", text: "" },
          ],
        },
      ]);
    } else {
      setLocalList([...localList.splice(0, localList.length - 1)]);
    }
  };
  const handleSave = async (isSave) => {
    /* 多重クリック防止 */
    if (processingRef.current) return;
    processingRef.current = true;
    let honbuid = localStorage.getItem("honbuid");

    let seqlist = [];
    let nolist = [];

    for (let i = 0; i < localList.length; i++) {
      seqlist.push({ seq: localList[i].seq, title: localList[i].title, kbn: localList[i].kbn });
      if (localList[i].kbn == "radio") {
        for (let j = 0; j < localList[i].list.length; j++) {
          nolist.push({ seq: localList[i].seq, no: localList[i].list[j].no, text: localList[i].list[j].text });
        }
      } else if (localList[i].kbn == "multi") {
        for (let j = 0; j < localList[i].list.length; j++) {
          nolist.push({ seq: localList[i].seq, no: localList[i].list[j].no, text: localList[i].list[j].text });
        }
      } else if (localList[i].kbn == "text") {
        nolist.push({ seq: localList[i].seq, no: "0", text: "" });
      }
    }

    const body = {
      anpiid: openAnpiId == null ? "-1" : openAnpiId.anpiid,
      honbuid: honbuid,
      name: state.user_name,
      title: AnpiTitle,
      roomid: state.currentRoomid,
      seqlist: seqlist, //Json型をそのまま投げる。
      nolist: nolist, //Json型をそのまま投げる。
      //hplist: hplist, //Json型をそのまま投げる。
      istemp: isSave ? "1" : "0", //一時保存できるように追加
    };
    console.log("post前", body);
    const result = await custom_axios_post(API_URL + "/createNewQue", body);

    processingRef.current = false;

    afterProc();

    if (result.status === 401) {
      history.replace("/loginError");
    }
    handleClose();
  };

  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    let tempIsVisible = true;
    if (AnpiTitle.length > 0) {
      for (let i = 0; i < localList.length; i++) {
        if (localList[i].title == "") {
          tempIsVisible = false;
        }
        if (localList[i].kbn == "radio") {
          for (let j = 0; j < localList[i].list.length; j++) {
            if (localList[i].list[j].text == "") {
              tempIsVisible = false;
            }
          }
        }
      }
    } else {
      tempIsVisible = false;
    }
    setIsVisible(tempIsVisible);
  }, [AnpiTitle, localList]);

  return (
    <Dialog open={open} onClose={handleClose} maxWidth={"sm"} fullWidth>
      <StyledDialogTitle id="max-width-dialog-title">
        <div className="show-member-search">
          <div className="show-member-search">アンケート作成</div>
          <div className="question-title-actions">
            <StyledButton onClick={handleClose} variant="outlined">
              閉じる (Esc)
            </StyledButton>
          </div>
        </div>
      </StyledDialogTitle>

      <DialogContent>
        <div className="question-create-wrap">
          <TextField
            id="outlined-multiline-static"
            label="タイトル"
            InputLabelProps={{
              shrink: true,
            }}
            variant="outlined"
            value={AnpiTitle}
            onChange={(e) => setAnpiTitle(e.target.value)}
            margin="dense"
          />
          {localList &&
            localList.map((e, index) => {
              return (
                <ShowAnpiCreateDetail
                  key={index}
                  listlength={localList.length}
                  anpidetail={e}
                  editTitle={editTitle}
                  editKbn={editKbn}
                  editSu={editSu}
                  editSeq={editSeq}
                  editText={editText}
                  isanpi="0"
                />
              );
            })}
        </div>
      </DialogContent>

      <DialogActions>
        <Button
          disabled={isVisible ? false : true}
          style={{ margin: "0 auto" }}
          variant="contained"
          color="primary"
          onClick={() => handleSave(true)}
          startIcon={<MailOutlineIcon />}
        >
          一時保存
        </Button>
        <Button
          disabled={isVisible ? false : true}
          style={{ margin: "0 auto" }}
          variant="contained"
          color="primary"
          onClick={() => handleSave(false)}
          startIcon={<MailOutlineIcon />}
        >
          送信
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ShowNewQueCreate;
