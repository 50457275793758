import { useContext, useState, useEffect } from "react";
import { Store } from "../Store/store";

function useRoomName() {
  const { state } = useContext(Store);
  const [username] = useState(state.username);
  const [roomnam, setRoomname] = useState("");
  const [ispush, setIspush] = useState(false);

  useEffect(() => {
    if (state.chatrooms && state.chatrooms.filter((f) => f.roomid === state.currentRoomid).length > 0) {
      let chatroom = state.chatrooms.filter((f) => f.roomid === state.currentRoomid)[0];
      if (chatroom.ispush == "1") {
        setIspush(true);
      } else {
        setIspush(false);
      }
      if (chatroom.roomname !== "") {
        const solonum = chatroom.memberlist.length + 1;
        setRoomname(chatroom.roomname + (solonum === 1 ? "" : "(" + String(solonum) + ")"));
      } else {
        //console.log("空っぽの場合２", chatroom.memberlist);
        let roomname = chatroom.memberlist.length > 2 ? username : "";
        for (let i = 0; i < chatroom.memberlist.length; i++) {
          roomname = roomname + chatroom.memberlist[i].name + ",";
          //console.log("chatroom.memberlist[i].name", chatroom.memberlist[i].name);
        }
        roomname = roomname.slice(0, -1);
        const groupnum = chatroom.memberlist.length + 1;
        setRoomname(roomname + (groupnum === 2 ? "" : "(" + String(groupnum) + ")"));
      }
    }
  }, [state]);

  return [roomnam, ispush];
}

export default useRoomName;
