/* eslint-disable react/prop-types */
import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import { USER_BUTTON_OPTION } from "../Common/Common";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import SettingsIcon from "@material-ui/icons/Settings";
import IconButton from "@material-ui/core/IconButton";
import styled from "styled-components";

const StyledButton = styled(Button)`
  width: 100%;
`;

function ShowButtonSelect({
  handleprintRun,
  handleRun,
  handleRunSms,
  handleRunCode,
  handleRunLeave,
  handleRunLock,
  handleRunName,
  honbuid,
  name,
  unitname,
  jobname,
}) {
  const [stampAnchorEl, setStampAnchorEl] = useState(null);
  const handleStampClick = (event) => {
    setStampAnchorEl(event.currentTarget);
  };
  const handleStampClose = () => {
    setStampAnchorEl(null);
  };

  return (
    <>
      <IconButton aria-label="delete" onClick={handleStampClick}>
        <SettingsIcon />
      </IconButton>
      <Menu
        id="simple-menu"
        anchorEl={stampAnchorEl}
        keepMounted
        open={Boolean(stampAnchorEl)}
        onClose={handleStampClose}
      >
        {USER_BUTTON_OPTION.map((title) => (
          <MenuItem key={title} value={title}>
            <StyledButton
              variant="contained"
              key={title}
              onClick={() => {
                if (title == "ロック解除") {
                  handleRunLock(honbuid);
                  setStampAnchorEl(null);
                }
                if (title == "QRコード印刷") {
                  handleprintRun(honbuid, name, unitname, jobname);
                  setStampAnchorEl(null);
                }
                if (title == "パスワード初期化") {
                  handleRun(honbuid);
                  setStampAnchorEl(null);
                }
                if (title == "SMS認証解除") {
                  handleRunSms(honbuid);
                  setStampAnchorEl(null);
                }
                if (title == "引換コード変更") {
                  handleRunCode(honbuid);
                  setStampAnchorEl(null);
                }
                if (title == "名前変更") {
                  handleRunName(honbuid, name);
                  setStampAnchorEl(null);
                }
                if (title == "退職") {
                  handleRunLeave(honbuid);
                  setStampAnchorEl(null);
                }
              }}
            >
              {title}
            </StyledButton>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}

export default ShowButtonSelect;
