/* eslint-disable react/prop-types */
import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import styled from "styled-components";

const StyledDialogTitle = styled(DialogTitle)`
  color: ${(props) => props.theme.palette.primary.contrastText};
  background-color: ${(props) => props.theme.palette.primary.main};
`;

function ShowCommon({ open, title, handleClose, handleCallBack }) {
  return (
    <Dialog open={open} onClose={handleClose} maxWidth={"lg"}>
      <StyledDialogTitle id="max-width-dialog-title">{title}</StyledDialogTitle>

      <DialogContent>
        <div className="logoff-wrap">
          <Button onClick={handleCallBack} style={{ margin: "5px" }} variant="contained" color="primary">
            はい
          </Button>
          <Button onClick={handleClose} style={{ margin: "5px" }} variant="contained">
            いいえ
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default ShowCommon;
