/* eslint-disable react/prop-types */
import Snackbar from "@material-ui/core/Snackbar";
import "./ShowUserInfo.css";
import React, { useEffect, useState, useMemo, useContext } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { API_URL, custom_axios, custom_axios_post } from "../Common/Common";
import useReactRouter from "use-react-router";
import ShowCommon from "../Common/ShowCommon";
import ShowRoomSwitch from "./ShowRoomSwitch";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Paper from "@material-ui/core/Paper";
import MultiSelect from "react-multi-select-component";
import Skeleton from "@material-ui/lab/Skeleton";
import Slide from "@material-ui/core/Slide";
import styled from "styled-components";
import ShowBellButtonSelect from "../Common/ShowBellButtonSelect";
import ShowBellDetail from "../Common/ShowBellDetail";
import { Store } from "../Store/store";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="right" ref={ref} {...props} />;
});

const headCells = [
  { id: "bellid", label: "ID" },
  { id: "title", label: "タイトル" },
  { id: "yyyymm", label: "日付" },
  { id: "ispopup", label: "ポップアップ" },
  { id: "popupcnt", label: "非表示件数" },
  { id: "option", label: "オプション" },
];
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}
function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align="left"
            padding="normal"
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={() => createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <StyledSpan>{order === "desc" ? "sorted descending" : "sorted ascending"}</StyledSpan>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

function LoadingTableHead() {
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell key={headCell.id} align="left" padding="normal">
            <TableSortLabel>{headCell.label}</TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
const StyledDialogTitle = styled(DialogTitle)`
  color: ${(props) => props.theme.palette.primary.contrastText};
  background-color: ${(props) => props.theme.palette.primary.main};
  padding: 5px 10px;
`;
const StyledButton = styled(Button)`
  color: ${(props) => props.theme.palette.primary.contrastText};
  background-color: ${(props) => props.theme.palette.primary.main};
  padding: 5px 10px;
`;
const StyledDialogContent = styled(DialogContent)`
  padding: 0px;
`;
const StyledRoot = styled.div`
  width: 100%;
`;
const StyledPaper = styled(Paper)`
  width: 100%;
  margin-bottom: ${(props) => props.theme.spacing(2) + "px"};
`;

const StyledTable = styled(Table)`
  min-width: 750;
`;

const StyledSpan = styled.span`
  border: 0;
  clip: rect(0 0 0 0);
  height: 1;
  margin: -1;
  overflow: hidden;
  padding: 0;
  position: absolute;
  top: 20;
  width: 1;
`;

function ShowBellAdmin({ open, handleClose }) {
  const { history } = useReactRouter();

  const [options, setOptions] = useState([]);

  const [bellList, setBellList] = useState([]);

  const [loading, setLoading] = useState(false);

  const { state } = useContext(Store);
  const [selectedhp, setSelectedHp] = useState([]);
  const initproc = async (selecthp) => {
    setLoading(true);
    let honbuid = localStorage.getItem("honbuid");
    let joinhps = selecthp
      .map((e) => {
        return e.value;
      })
      .join("_");
    const body = {
      hps: joinhps,
      honbuid: honbuid,
    };
    const result = await custom_axios_post(API_URL + "/GetBellAdmin", body);
    const status = result.status;
    if (status === 401) {
      history.replace("/loginError");
    }
    if (status === 200) {
      setLoading(false);
      setBellList(result.data.bells);
      setOptions(
        result.data.hps.map((e) => {
          return { label: e.hpname, value: e.hpcode };
        })
      );
    }
  };

  useEffect(() => {
    if (open) {
      setSelectedHp([{ label: state.hpcode + ":" + state.hpname, value: state.hpcode }]);
      initproc([{ label: state.hpcode + ":" + state.hpname, value: state.hpcode }]);
    }
  }, [open]);

  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const [bellid, setBellid] = useState("");
  const [dellsnackopen, setDellSnackOpen] = useState(false);
  const handleMainDell = async () => {
    let strurl = API_URL + `/deletebell?bellid=${bellid}`;
    const result = await custom_axios(strurl);
    const status = result.status;

    if (status === 401) {
      history.replace("/loginError");
    }
    if (status === 200) {
      initproc(selectedhp);
      setDellSnackOpen(true);
      setOpenCodeModal(false);
    }
  };

  const [openCodeModal, setOpenCodeModal] = useState(false);
  const [openRunModalOn, setOpenRunModalOn] = useState(false);
  const [openRunModalOff, setOpenRunModalOff] = useState(false);

  const handleCodeModalClose = () => {
    setOpenCodeModal(false);
  };
  const handleRunModalOnClose = () => {
    setOpenRunModalOn(false);
  };
  const handleRunModalOffClose = () => {
    setOpenRunModalOff(false);
  };

  const handleDell = async (bellid) => {
    setBellid(bellid);
    setOpenCodeModal(true);
  };
  const handleRun = async (bellid) => {
    setBellid(bellid);
    setOpenRunModalOff(true);
  };
  const handleChangePop = async (bellid) => {
    const result = await custom_axios(API_URL + `/changeBellPop?bellid=${bellid}`);
    const status = result.status;
    if (status === 401) {
      history.replace("/loginError");
    }
  };

  const MemoBellDetailOff = useMemo(() => {
    return (
      <ShowBellDetail
        open={openRunModalOff}
        openBellId={bellid}
        handleClose={handleRunModalOffClose}
        isEnabled={false}
        afterProc={() => {}}
      />
    );
  }, [openRunModalOff, bellid]);
  const MemoBellDetailOn = useMemo(() => {
    return (
      <ShowBellDetail
        open={openRunModalOn}
        openBellId={0}
        handleClose={handleRunModalOnClose}
        isEnabled={true}
        afterProc={() => {
          initproc(selectedhp);
        }}
      />
    );
  }, [openRunModalOn]);

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleClose}
      fullWidth={true}
      maxWidth={"xl"}
      TransitionComponent={Transition}
    >
      <StyledDialogTitle id="max-width-dialog-title">
        <div className="show-member-search">
          <div className="show-member-search">重要なお知らせ一覧</div>
          <div className="question-title-actions">
            <StyledButton
              onClick={() => {
                setOpenRunModalOn(true);
              }}
              //className={classes.textcolor}
              variant="outlined"
            >
              お知らせ作成
            </StyledButton>
            <div style={{ width: "20px" }}></div>
            <StyledButton onClick={handleClose} variant="outlined">
              閉じる (Esc)
            </StyledButton>
          </div>
        </div>
      </StyledDialogTitle>
      <StyledDialogContent>
        {MemoBellDetailOn}
        {MemoBellDetailOff}
        <Snackbar
          open={dellsnackopen}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          autoHideDuration={6000}
          onClose={() => {
            setDellSnackOpen(false);
          }}
          message="削除しました。"
        ></Snackbar>
        <ShowCommon
          open={openCodeModal}
          title="削除しますか"
          handleClose={handleCodeModalClose}
          handleCallBack={handleMainDell}
        ></ShowCommon>
        <StyledRoot>
          <div className="user-select-area">
            <div className="user-select-subarea">
              <div className="multi-select">
                <MultiSelect
                  options={options}
                  value={selectedhp}
                  onChange={(e) => {
                    setSelectedHp(e);
                    initproc(e);
                  }}
                  labelledBy={"Select"}
                  overrideStrings={{
                    selectSomeItems: "選択...",
                    allItemsAreSelected: "全選択",
                    selectAll: "全選択",
                    search: "検索",
                    clearSearch: "検索クリア",
                  }}
                  filterOptions={(options, filter) => {
                    if (!filter) {
                      return options;
                    }
                    const re = new RegExp(filter, "i");
                    return options.filter(({ label }) => label && label.match(re));
                  }}
                />
              </div>
            </div>
          </div>

          {loading ? (
            <div>
              <StyledPaper>
                <TableContainer>
                  <StyledTable
                    //className={classes.table}
                    aria-labelledby="tableTitle"
                    //size={dense ? "small" : "medium"}
                    size="small"
                    aria-label="enhanced table"
                  >
                    <LoadingTableHead />
                    <TableBody>
                      {[...Array(15)].map((_, i) => {
                        const skeletonWidth = 60;
                        return (
                          <TableRow hover role="checkbox" tabIndex={-1} key={i}>
                            <TableCell component="th">
                              <Skeleton variant="rect" width={skeletonWidth} />
                            </TableCell>
                            <TableCell align="left">
                              <Skeleton variant="rect" width={skeletonWidth} />
                            </TableCell>
                            <TableCell align="left">
                              <Skeleton variant="rect" width={skeletonWidth} />
                            </TableCell>
                            <TableCell align="left">
                              <Skeleton variant="rect" width={skeletonWidth} />
                            </TableCell>
                            <TableCell align="left">
                              <Skeleton variant="rect" width={skeletonWidth} />
                            </TableCell>
                            <TableCell align="left">
                              <Skeleton variant="rect" width={skeletonWidth} />
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </StyledTable>
                </TableContainer>
              </StyledPaper>
            </div>
          ) : (
            <StyledPaper>
              <TableContainer>
                <StyledTable
                  //className={classes.table}
                  aria-labelledby="tableTitle"
                  //size={dense ? "small" : "medium"}
                  size="small"
                  aria-label="enhanced table"
                >
                  <EnhancedTableHead
                    //classes={classes}
                    //numSelected={selected.length}
                    order={order}
                    orderBy={orderBy}
                    //onSelectAllClick={handleSelectAllClick}
                    onRequestSort={handleRequestSort}
                    //rowCount={roomsList.length}
                  />
                  <TableBody>
                    {stableSort(bellList, getComparator(order, orderBy)).map((row, index) => {
                      return (
                        <TableRow
                          hover
                          //onClick={(event) => handleClick(event, row.honbuid)}
                          role="checkbox"
                          tabIndex={-1}
                          key={index}
                        >
                          <TableCell align="left">{row.bellid}</TableCell>
                          <TableCell align="left">{row.title}</TableCell>
                          <TableCell align="left">{row.yyyymm}</TableCell>

                          <TableCell align="left">
                            <ShowRoomSwitch
                              initvalue={row.popupflag}
                              callBack={() => handleChangePop(row.bellid)}
                              bellid={row.bellid}
                            />
                          </TableCell>
                          <TableCell align="left">{row.popupcnt}</TableCell>
                          <TableCell align="left">
                            <ShowBellButtonSelect
                              handleRun={() => handleRun(row.bellid)}
                              handleDell={() => handleDell(row.bellid)}
                              bellid={row.bellid}
                            ></ShowBellButtonSelect>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </StyledTable>
              </TableContainer>
            </StyledPaper>
          )}
        </StyledRoot>
      </StyledDialogContent>
    </Dialog>
  );
}

export default ShowBellAdmin;
