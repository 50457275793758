/* eslint-disable react/prop-types */
import "./ShowUserInfo.css";
import React, { useEffect, useState, useRef } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import Snackbar from "@material-ui/core/Snackbar";
import SaveIcon from "@material-ui/icons/Save";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/styles";
import { createTheme } from "@material-ui/core/styles";
import AccessTimeIcon from "@material-ui/icons/AccessTime";

import { API_URL, custom_axios, custom_axios_post } from "../Common/Common";
import useReactRouter from "use-react-router";
//import { Store } from "../Store/store";
import ReactQuill from "react-quill"; // ES6
import "react-quill/dist/quill.snow.css"; // ES6
import styled from "styled-components";
import "./ShowNote.css";

// <Dialog>の中身のcssを直接いじる(右からやってくる)
const theme = createTheme({
  overrides: {
    MuiDialog: {
      paperFullScreen: { width: "80%" },
      container: { "margin-left": "-11.1%" },
    },
  },
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

// const StyledGridListroot = styled.div`
//   overflow-y: auto;
//   height: calc(100vh - 47px);
// `;

const StyledDialogTitle = styled(DialogTitle)`
  color: ${(props) => props.theme.palette.primary.contrastText};
  background-color: ${(props) => props.theme.palette.primary.main};
  padding: 5px 10px;
`;

const StyledButton = styled(Button)`
  color: ${(props) => props.theme.palette.primary.contrastText};
  background-color: ${(props) => props.theme.palette.primary.main};
  padding: 5px 10px;
`;
const StyledDialogContent = styled(DialogContent)`
  padding: 0px;
`;
const StyledSaveButton = styled(Button)`
  color: ${(props) => props.theme.palette.primary.contrastText};
  background-color: ${(props) => props.theme.palette.primary.main};
  padding: 5px 10px;
  margin-right: 10px;
  margin-left: 10px;
`;

const CustomButton = () => <AccessTimeIcon />;
/*
 * Event handler to be attached using Quill toolbar module (see line 73)
 * https://quilljs.com/docs/modules/toolbar/
 */
function timeStamp() {
  const nowDateTime = new Date().toLocaleString();
  const cursorPosition = this.quill.getSelection().index;
  this.quill.insertText(cursorPosition, nowDateTime);
  //this.quill.setSelection(cursorPosition + 19);
  // const cursorPosition2 = this.quill.getSelection().index;
  // this.quill.insertText(cursorPosition2, "\n");
}

/*
 * Custom toolbar component including timeStamp button and dropdowns
 */
const CustomToolbar = () => (
  <div id="toolbar" className="note-toolbar-class">
    <button className="ql-header" value="1" data-toggle="tooltip" data-placement="bottom" title="特大" />
    <button className="ql-header" value="2" data-toggle="tooltip" data-placement="bottom" title="大" />
    <select
      className="ql-size note-toolbar-divider ssss"
      defaultValue={"normal"}
      onChange={(e) => e.persist()}
      data-toggle="tooltip"
      data-placement="bottom"
      title="文字サイズ"
    >
      <option value="small"></option>
      <option value="normal"></option>
      <option value="large"></option>
      <option value="huge"></option>
    </select>

    <button className="ql-bold" data-toggle="tooltip" data-placement="bottom" title="太字" />
    <button className="ql-italic" data-toggle="tooltip" data-placement="bottom" title="斜体" />
    <button className="ql-underline" data-toggle="tooltip" data-placement="bottom" title="下線" />
    <button
      className="ql-strike note-toolbar-divider"
      data-toggle="tooltip"
      data-placement="bottom"
      title="取り消し線"
    />

    <select className="ql-color note-toolbar-divider" data-toggle="tooltip" data-placement="bottom" title="文字色">
      <option value="black" />
      <option value="red" />
      <option value="green" />
      <option value="blue" />
    </select>

    <button className="ql-list" value="ordered" data-toggle="tooltip" data-placement="bottom" title="段落番号" />
    <button className="ql-list" value="bullet" data-toggle="tooltip" data-placement="bottom" title="箇条書き" />
    <button className="ql-list" value="check" data-toggle="tooltip" data-placement="bottom" title="チェックボックス" />
    <button className="ql-indent" value="-1" data-toggle="tooltip" data-placement="bottom" title="段落を一段上げる" />
    <button className="ql-indent" value="+1" data-toggle="tooltip" data-placement="bottom" title="段落を一段下げる" />
    <button className="ql-blockquote note-toolbar-divider" data-toggle="tooltip" data-placement="bottom" title="引用" />

    <button className="ql-link" data-toggle="tooltip" data-placement="bottom" title="ハイパーリンク" />
    <button className="ql-image" data-toggle="tooltip" data-placement="bottom" title="画像添付" />
    <button
      className="ql-video note-toolbar-divider"
      data-toggle="tooltip"
      data-placement="bottom"
      title="Web上の動画添付"
    />

    <button
      className="ql-clean note-toolbar-divider"
      data-toggle="tooltip"
      data-placement="bottom"
      title="文字加工を全消し"
    />

    <button className="ql-timeStamp" data-toggle="tooltip" data-placement="bottom" title="現在日時">
      <CustomButton />
    </button>
  </div>
);

function ShowNote({ open, handleClose }) {
  const { history } = useReactRouter();
  //const { state } = useContext(Store);
  //const [ischange, setIsChange] = useState(false);

  const modules = {
    toolbar: {
      container: "#toolbar",
      handlers: {
        timeStamp: timeStamp,
      },
    },
    clipboard: {
      matchVisual: false,
    },
  };
  /*
   * Quill editor formats
   * See https://quilljs.com/docs/formats/
   */
  const formats = [
    "header",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "script",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "check",
    "color",
    "link",
    "image",
    "video",
  ];

  const onFocused = useRef(false);
  const isPasted = useRef(false);
  const quillRef = useRef([]);

  const [loading, setLoading] = useState(false);
  const [snackopen, setSnackOpen] = useState(false);
  const [contentsText, setContentsText] = useState("");
  const [nowWriting, setNowWriting] = useState(false);

  const mainproc = async () => {
    setLoading(true);
    let honbuid = localStorage.getItem("honbuid");
    let roomid = localStorage.getItem("currentRoomid");
    const result = await custom_axios(API_URL + `/getNote?honbuid=${honbuid}&roomid=${roomid}`);
    const status = result.status;
    if (status === 401) {
      history.replace("/loginError");
    } else if (status === 200) {
      let ed = quillRef.current.getEditor();
      var jj = JSON.parse(result.data.note);
      // console.log(jj);
      ed.setContents({ ops: jj });
      //setIsChange(false);
    }
    setLoading(false);
    updateproc(false);
  };

  const updateproc = async (snack) => {
    // ノートが開いてる状態じゃないと、quillRefがないのでエラーが起きるので回避
    if (quillRef.current !== null) {
      // ノート起動時にもcontentsTextが変更されて呼び出されるが、
      // quillRefが生成されておらず(?)エラーが起きるので回避
      if (quillRef.current.length !== 0) {
        let ed = quillRef.current.getEditor();
        let honbuid = localStorage.getItem("honbuid");
        let roomid = localStorage.getItem("currentRoomid");
        //console.log("ed.getContents()", ed.getContents().ops);
        const params = {
          honbuid: honbuid,
          roomid: roomid,
          note: JSON.stringify(ed.getContents().ops),
        };
        custom_axios_post(API_URL + "/updateNote", params);
        if (snack) {
          setSnackOpen(true);
        }
        setNowWriting(false);
      }
    }
  };

  // ノート開けたときの処理
  useEffect(() => {
    if (open) {
      onFocused.current = false;
      isPasted.current = false;
      mainproc();
    }
  }, [open]);

  // debounce処理で自動保存(https://www.tohuandkonsome.site/entry/2019/10/24/210833)
  useEffect(() => {
    // フォーカスされてない時は実行しない
    if (onFocused.current) {
      setNowWriting(true);
      const timer = setTimeout(() => {
        updateproc(true);
      }, 2000);
      // componentWillUnmount(https://namu-r21.hatenablog.com/entry/2019/07/26/191752)
      return () => {
        clearTimeout(timer);
      };
    }
  }, [contentsText]);
  //const ref = useRef(null);
  return (
    <MuiThemeProvider theme={theme}>
      <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
        <StyledDialogTitle id="max-width-dialog-title">
          <div className="show-member-search">
            <div className="show-member-search">ノート{loading ? " (読込中...)" : ""}</div>
            <div className="question-title-actions">
              <div className="">{nowWriting ? "未保存" : "保存済み"}</div>
              <StyledSaveButton onClick={updateproc} variant="outlined" startIcon={<SaveIcon />}>
                保存
              </StyledSaveButton>
              <StyledButton
                onClick={() => {
                  handleClose();
                  onFocused.current = false;
                  isPasted.current = false;
                }}
                variant="outlined"
              >
                閉じる (Esc)
              </StyledButton>
            </div>
          </div>
        </StyledDialogTitle>
        <StyledDialogContent className="quill-scroll-container">
          <CustomToolbar className="note-class" />
          <ReactQuill
            scrollingContainer=".quill-scroll-container"
            className="note-class"
            readOnly={loading}
            ref={quillRef}
            formats={formats}
            modules={modules}
            // フォーカスある時だけ自動保存
            // ※ ペーストしたときにフォーカスが外れてしまい保存されない
            // → フォーカスが外れたときにフラグを持っておいてtrueだったら、フォーカスされたときに保存する
            onFocus={() => {
              onFocused.current = true;
              if (isPasted.current) updateproc(true);
              isPasted.current = false;
            }}
            onBlur={() => {
              onFocused.current = false;
              isPasted.current = true;
            }}
            onChange={(contents) => setContentsText(contents)}
          />
          <Snackbar
            open={snackopen}
            autoHideDuration={2000}
            onClose={() => {
              setSnackOpen(false);
              //setIsChange(false);
            }}
            message="保存しました。"
          />
        </StyledDialogContent>
      </Dialog>
    </MuiThemeProvider>
  );
}

export default ShowNote;
