/* eslint-disable react/prop-types */
import "./ShowUserInfo.css";
import React, { useEffect, useState, useContext, useMemo } from "react";
import useReactRouter from "use-react-router";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import { API_URL, STATIC_URL, custom_axios } from "../Common/Common";
import { Store } from "../Store/store";
import ShowAccountPhoto from "../Common/ShowAccountPhoto";
import ShowColorSelect from "../Common/ShowColorSelect";
import ShowQrcode from "../Common/ShowQrcode";
import TextField from "@material-ui/core/TextField";
import SaveOutlinedIcon from "@material-ui/icons/SaveOutlined";
import AppleIcon from "@material-ui/icons/Apple";
import AndroidIcon from "@material-ui/icons/Android";
import CameraAltIcon from "@material-ui/icons/CameraAlt";
import IconButton from "@material-ui/core/IconButton";
// import FavoriteIcon from "@material-ui/icons/Favorite";
import ShowFavorMember from "../Common/ShowFavorMember";
import styled from "styled-components";

const StyledDialogTitle = styled(DialogTitle)`
  color: ${(props) => props.theme.palette.primary.contrastText};
  background-color: ${(props) => props.theme.palette.primary.main};
`;
const StyledButton = styled(Button)`
  margin: 0px 10px;
`;
const StyledDialogContent = styled(DialogContent)`
  padding: 0px;
`;

const StyledPhoneDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 5px;
`;

const StyledPhoneSubDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 5px;
`;

function ShowAccount({ open, handleClose }) {
  const { history } = useReactRouter();
  const { state, dispatch } = useContext(Store);

  //console.log("stateの値", state);
  const [name, setName] = useState();
  // const [subname, setSubName] = useState();
  //const [phone, setPhone] = useState();
  const [email, setEmail] = useState();
  const [pass1, setPass1] = useState("");
  const [pass2, setPass2] = useState("");
  // const [favorcount, setFavorcount] = useState("");
  const [openFavor, setOpenFavor] = useState(false);
  const handleFavorClose = () => {
    setOpenFavor(false);
  };

  const [openPreview, setOpenPreview] = useState({ isOpen: false, url: "" });
  const handlePreviewClose = () => {
    setOpenPreview({ isOpen: false, url: "" });
  };
  //const [color, setColor] = useState(state.color);
  const handleiOS = async () => {
    let honbuid = localStorage.getItem("honbuid");
    const result = await custom_axios(API_URL + `/getQrString?honbuid=${honbuid}`);
    const status = result.status;
    if (status === 401) {
      history.replace("/loginError");
    } else if (status === 200) {
      if (result.data.code != "") {
        setOpenPreview({
          isOpen: true,
          url: `https://chart.apis.google.com/chart?chs=150x150&cht=qr&chl=https://apps.apple.com/redeem?code=${result.data.code}&ctx=apps`,
        });
      }
    }
  };
  const handleAndroid = async () => {
    setOpenPreview({
      isOpen: true,
      url:
        "https://chart.apis.google.com/chart?chs=150x150&cht=qr&chl=https://play.google.com/store/apps/details?id=com.tis.chatapp",
    });
  };
  const handleSave = async () => {
    let honbuid = localStorage.getItem("honbuid");
    //console.log("state", state);

    const send_name = name ? name : state.user_name;

    //const send_phone = phone ? phone : state.phone;
    //const send_email = email ? email : state.email;
    var changeemail = "";
    if (email && email != state.email) {
      changeemail = "1";
    } else {
      changeemail = "0";
    }

    const send_email = email;

    let urlstr =
      API_URL +
      `/changeAccountAll?honbuid=${honbuid}&name=${send_name}&email=${send_email}&changeemail=${changeemail}&password=${pass1}&color=${themeColor}`;

    const result = await custom_axios(urlstr);
    const status = result.status;
    if (status === 401) {
      history.replace("/loginError");
    }
    //console.log("GetUser", result);
    handleClose();
    if (result.status === 200) {
      dispatch({
        type: "CHANGE_ACCOUNT",
        payload: { name: send_name, email: send_email, color: themeColor },
      });
      // }
    }
    setIsVisible(false);
    handleClose();
  };
  const [isVisible, setIsVisible] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [themeColor, setThemeColor] = useState("");
  const handleModalClose = () => {
    setModalOpen(false);
  };
  useEffect(() => {
    let ret = false;
    if (name && name.length > 0 && name != state.user_name) {
      ret = true;
    }
    if (state.email && state.email.length > 0) {
      if (email != state.email) {
        ret = true;
      }
    } else {
      if (email && email.length > 0 && email != state.email) {
        ret = true;
      }
    }

    // if (phone && phone.length > 0 && phone != state.phone) {
    //   ret = true;
    // }
    if (themeColor != state.color) {
      ret = true;
    }
    if (ret && pass1.length == 0 && pass2.length == 0) {
      ret = true;
    } else {
      ret = false;
      if (pass1 && pass1.length > 7 && pass2 && pass2.length > 7) {
        if (pass1 == pass2) {
          ret = true;
        }
      }
    }

    setIsVisible(ret);
  }, [name, email, pass1, pass2, themeColor]);

  useEffect(() => {
    // const proc = async () => {
    //   let honbuid = localStorage.getItem("honbuid");
    //   if (open) {
    //     const result = await custom_axios(API_URL + `/getFavorCount?honbuid=${honbuid}`);
    //     const status = result.status;
    //     if (status === 401) {
    //       history.replace("/loginError");
    //     } else if (status === 200) {
    //       setFavorcount(result.data.count);
    //     }
    //   }
    // };
    setThemeColor(state.color);
    //proc();
  }, [open]);

  const MemoFavor = useMemo(() => {
    return <ShowFavorMember open={openFavor} handleClose={handleFavorClose}></ShowFavorMember>;
  }, [openFavor]);
  const MemoQrcode = useMemo(() => {
    return <ShowQrcode open={openPreview.isOpen} url={openPreview.url} handleClose={handlePreviewClose}></ShowQrcode>;
  }, [openPreview]);
  const MemoPhoto = useMemo(() => {
    return <ShowAccountPhoto open={modalOpen} handleClose={handleModalClose}></ShowAccountPhoto>;
  }, [modalOpen]);

  return (
    <Dialog open={open} onClose={handleClose} maxWidth={"sm"}>
      <StyledDialogTitle id="max-width-dialog-title">
        <div className="show-member-search">
          <div className="show-member-search">プロフィール変更</div>
        </div>
      </StyledDialogTitle>

      <StyledDialogContent>
        {MemoFavor}
        {MemoQrcode}
        {MemoPhoto}
        <div className="question-create-wrap">
          <div className="show-user-wrap">
            <div className="show-user-image-back">
              <img
                src={STATIC_URL + `/downloadAvatar?honbuid=${state.honbuid}&fileno=${state.fileno}`}
                alt=""
                className="show-user-image-back-image"
                //style={isCurrent ? { border: "1px solid white" } : null}
              />
            </div>

            <div className="show-user-image-wraper">
              <img
                src={STATIC_URL + `/downloadAvatar?honbuid=${state.honbuid}&fileno=${state.fileno}`}
                width="150"
                height="150"
                alt=""
                className="show-user-image"
                //style={isCurrent ? { border: "1px solid white" } : null}
              />
            </div>

            {/* <div className="show-user-image-wraper-good" onClick={() => setOpenFavor(true)}>
              <div className="favor-icon">
                <FavoriteIcon></FavoriteIcon>
              </div>
              <div className="favor-count">{favorcount}</div>
            </div> */}

            <div className="show-user-information-camera">
              <IconButton style={{ color: "white" }} onClick={() => setModalOpen(true)}>
                <CameraAltIcon></CameraAltIcon>
              </IconButton>
            </div>
          </div>

          <TextField
            label="本部ID"
            variant="outlined"
            defaultValue={state.honbuid}
            disabled
            style={{ margin: "8px", minWidth: "300px" }}
          />
          <TextField
            label="施設"
            variant="outlined"
            defaultValue={state.hpname}
            disabled
            style={{ margin: "8px", minWidth: "300px" }}
          />
          <TextField
            label="所属"
            variant="outlined"
            defaultValue={state.subname}
            disabled
            // onChange={(e) => {
            //   setSubName(e.target.value);
            // }}
            style={{ margin: "8px", minWidth: "300px" }}
          />
          <TextField
            label="名前"
            variant="outlined"
            defaultValue={state.user_name}
            onChange={(e) => {
              setName(e.target.value);
            }}
            style={{ margin: "8px", minWidth: "300px" }}
          />

          {/* <TextField
            label="電話番号"
            variant="outlined"
            defaultValue={state.phone}
            onChange={(e) => {
              setPhone(e.target.value);
            }}
            style={{ margin: "8px", minWidth: "300px" }}
          /> */}

          <TextField
            label="新パスワード"
            type="password"
            variant="outlined"
            defaultValue=""
            onChange={(e) => {
              setPass1(e.target.value);
            }}
            style={{ margin: "8px", minWidth: "300px" }}
            inputProps={{
              autoComplete: "new-password",
              form: {
                autoComplete: "off",
              },
            }}
          />

          <TextField
            label="新パスワード(再)"
            type="password"
            variant="outlined"
            defaultValue=""
            onChange={(e) => {
              setPass2(e.target.value);
            }}
            style={{ margin: "8px", minWidth: "300px" }}
            inputProps={{
              autoComplete: "new-password",
              form: {
                autoComplete: "off",
              },
            }}
          />
          <ShowColorSelect initvalue={state.color} setThemeColor={setThemeColor}></ShowColorSelect>
          <TextField
            label="安否確認用Email(ガラケーの方)"
            variant="outlined"
            defaultValue={state.email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            style={{ margin: "8px", minWidth: "300px" }}
          />
          <StyledPhoneDiv>
            <div>スマホアプリインストール用</div>
            <StyledPhoneSubDiv>
              <StyledButton variant="contained" color="primary" onClick={handleiOS} startIcon={<AppleIcon />}>
                iOS
              </StyledButton>
              <StyledButton variant="contained" color="primary" onClick={handleAndroid} startIcon={<AndroidIcon />}>
                Android
              </StyledButton>
            </StyledPhoneSubDiv>
          </StyledPhoneDiv>
        </div>
      </StyledDialogContent>

      <DialogActions>
        <Button
          disabled={isVisible ? false : true}
          variant="contained"
          color="primary"
          onClick={handleSave}
          startIcon={<SaveOutlinedIcon />}
        >
          変更
        </Button>
        <Button onClick={handleClose} variant="outlined" color="secondary">
          閉じる (Esc)
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ShowAccount;
