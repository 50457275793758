/* eslint-disable react/display-name */
/* eslint-disable react/prop-types */
import "./ShowUserInfo.css";
import React, { useEffect, useState, useMemo, useContext, forwardRef } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import { STATIC_URL, API_URL, custom_axios, custom_axios_post } from "../Common/Common";
import useReactRouter from "use-react-router";
import { Store } from "../Store/store";
// import Select from "@material-ui/core/Select";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ShowAnpiCreate from "../Common/ShowAnpiCreate";
import MaterialTable from "material-table";
import ShowCommon from "../Common/ShowCommon";
import IconButton from "@material-ui/core/IconButton";
import SettingsIcon from "@material-ui/icons/Settings";
import Badge from "@material-ui/core/Badge";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Slide from "@material-ui/core/Slide";
import { FaAngleDown } from "react-icons/fa";
import { withStyles } from "@material-ui/core/styles";
import styled from "styled-components";
//import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import Save from "@material-ui/icons/Save";
import ShowAnpiVote from "./ShowAnpiVote";
import Snackbar from "@material-ui/core/Snackbar";
// import Typography from "@material-ui/core/Typography";
// import { PieChart, Pie, Cell } from "recharts";
// import purple from "@material-ui/core/colors/purple";
// import green from "@material-ui/core/colors/green";
// import teal from "@material-ui/core/colors/teal";
// import indigo from "@material-ui/core/colors/indigo";
// import blue from "@material-ui/core/colors/blue";
// import deepPurple from "@material-ui/core/colors/deepPurple";
// import pink from "@material-ui/core/colors/pink";
// import red from "@material-ui/core/colors/red";
// import deepOrange from "@material-ui/core/colors/deepOrange";
// import orange from "@material-ui/core/colors/orange";
// import yellow from "@material-ui/core/colors/yellow";
// import brown from "@material-ui/core/colors/brown";
// import lightGreen from "@material-ui/core/colors/lightGreen";
// import cyan from "@material-ui/core/colors/cyan";
// import lightBlue from "@material-ui/core/colors/lightBlue";
// import amber from "@material-ui/core/colors/amber";
// import lime from "@material-ui/core/colors/lime";
// import blueGrey from "@material-ui/core/colors/blueGrey";
// import grey from "@material-ui/core/colors/grey";
import CircularProgress from "@material-ui/core/CircularProgress";
// import AddAlertIcon from "@material-ui/icons/AddAlert";
// import Fab from "@material-ui/core/Fab";
//import ShowAnpiListTable from "../Common/ShowAnpiListTable";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import ShowAnpiUserView from "../Common/ShowAnpiUserView";
import ShowAnpiChart from "../Common/ShowAnpiChart";
import Chip from "@material-ui/core/Chip";
// import MultiSelect from "react-multi-select-component";

// const StyledFab = styled(Fab)`
//   position: fixed;
//   bottom: ${(props) => props.theme.spacing(5) + "px"};
//   right: ${(props) => props.theme.spacing(5) + "px"};
//   z-index: 10;
// `;
const StyledChip = styled(Chip)`
  margin-right: 5px;
  height: 15px;
  font-size: 8px;
`;
const StyledTypography = styled(Typography)`
  margin: 15px;
  color: ${(props) => props.theme.palette.primary.main};
`;
const StyledCntLink = styled(Link)`
  font-size: large;
`;
const tableIcons = {
  Save: forwardRef((props, ref) => <Save {...props} ref={ref} />),
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const StyledAnpiRow = styled.div`
  margin-left: 5px;
  margin-top: 2px;
`;

const MuiAccordion = withStyles({
  root: {
    border: "1px solid rgba(0, 0, 0, .125)",
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
})(Accordion);

const MuiAccordionSummary = withStyles((theme) => ({
  root: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    borderBottom: "1px solid rgba(0, 0, 0, .125)",
    marginBottom: -1,
    padding: "0px 5px",
    minHeight: 30,
    "&$expanded": {
      minHeight: 30,
    },
  },
  content: {
    margin: "8px 0",
    "&$expanded": {
      margin: "8px 0",
    },
  },
  expanded: {},
}))(AccordionSummary);

const MuiAccordionDetails = withStyles(() => ({
  root: {
    padding: "0px",
    // padding: theme.spacing(2),
  },
}))(AccordionDetails);

const StyledLink = styled.div`
  font-size: small;
  padding: 0px;
  color: ${(props) => props.theme.palette.primary.main};
`;
const StyledDialogContent = styled(DialogContent)`
  padding: 0px;
`;
const StyledDialogTitle = styled(DialogTitle)`
  color: ${(props) => props.theme.palette.primary.contrastText};
  background-color: ${(props) => props.theme.palette.primary.main};
  padding: 5px 10px;
`;
const StyledButton = styled(Button)`
  color: ${(props) => props.theme.palette.primary.contrastText};
  background-color: ${(props) => props.theme.palette.primary.main};
`;

const StyledAnpiWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 2px;
  background: ${(props) => (props.bigfont ? props.theme.palette.primary[100] : "#f7f8fc")};
  cursor: pointer;
  -webkit-transition: width 0.3s;
  transition: width 0.3s;
  width: 295px;
  //height: 38px;
  position: relative;
  z-index: 0;
  &:hover {
    background-color: ${(props) => props.theme.palette.primary[100]};
    & .chat-name {
      font-weight: 700;
    }
    & .chat-image {
      border: 1px solid white;
    }
  }
  & .chat-name {
    //color: ${(props) => (props.bigfont ? props.theme.palette.primary.contrastText : "black")};
    font-weight: ${(props) => (props.bigfont ? "700" : "normal")};
  }
`;

const StyledCircularProgress = styled(CircularProgress)`
  color: ${(props) => props.theme.palette.primary[100]};
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -12;
  margin-left: -12;
`;

// function TabPanel(props) {
//   const { children, value, index, ...other } = props;

//   return (
//     <div
//       role="tabpanel"
//       hidden={value !== index}
//       id={`scrollable-auto-tabpanel-${index}`}
//       aria-labelledby={`scrollable-auto-tab-${index}`}
//       {...other}
//     >
//       {value === index && <Typography component={`span`}>{children}</Typography>}
//     </div>
//   );
// }

function ShowAnpiList({ open, handleClose, createOk }) {
  const { history } = useReactRouter();
  const { state } = useContext(Store);
  const [selectedAnpiid, setSelectedAnpiid] = useState(null);
  const [selectedIsTemp, setSelectedIsTemp] = useState(false);
  const [localList, setLocalList] = useState([]);
  const [membersList, setMembersList] = useState([]);
  const [membersColumns, setMembersColumns] = useState([]);
  const [columns, setColumns] = useState([]);
  const [loading, setLoading] = useState(false);
  const [snackopen, setSnackOpen] = useState(false);
  const [snackpushopen, setSnackPushOpen] = useState(false);
  const [value, setValue] = React.useState(0);

  /* サマリー */
  // const [votesumarry, setVoteSumarry] = useState([]);
  // const [textsumarry, setTextSumarry] = useState([]);
  // const [textgroup, setTextGroup] = useState([]);

  const [sumcol, setSumCol] = useState([]);
  const [sumdata, setSumData] = useState([]);

  const [allcol, setAllCol] = useState([]);
  const [alldata, setAllData] = useState([]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const mainproc = async () => {
    let honbuid = localStorage.getItem("honbuid");
    if (open) {
      //setLocalList([]);
      setMembersList([]);
      setMembersColumns([]);
      setSelectedAnpiid(null);

      setSumCol([]);
      setSumData([]);

      const result = await custom_axios(API_URL + `/GetAnpiList?honbuid=${honbuid}`);
      const status = result.status;
      if (status === 401) {
        history.replace("/loginError");
      }
      if (status === 200) {
        setLocalList(result.data.anpilist);
      }
    }
  };

  useEffect(() => {
    mainproc();
  }, [open]);

  /* 左メニューをクリックしたときの動作 */
  const handleClick = async (q, t) => {
    setSelectedIsTemp(t == "1" ? true : false);
    setSelectedAnpiid(q);
  };
  const voteproc = async (seqlist) => {
    const isLargeNumber = (element) => element.honbuid == openVoteObj.honbuid;
    var inin = membersList.findIndex(isLargeNumber);
    const clonedData = [...membersList];
    seqlist.forEach((e) => {
      if (e.no == "0") {
        clonedData[inin]["vote" + e.seq] = e.value == "" ? "-" : e.value;
      } else {
        clonedData[inin]["vote" + e.seq] = e.no;
      }
    });
    clonedData[inin]["vote"] = "済";
    setMembersList(clonedData);
  };

  const subproc = async () => {
    setLoading(true);
    let honbuid = localStorage.getItem("honbuid");
    /* 1安否確認の画面更新 */
    const result2 = await custom_axios(API_URL + `/GetAnpi?honbuid=${honbuid}&anpiid=${selectedAnpiid}`);
    const status2 = result2.status;
    if (status2 === 401) {
      history.replace("/loginError");
    }
    if (status2 === 200) {
      setMembersList(result2.data.data);
      setColumns(result2.data.columns);
      setMembersColumns(
        result2.data.columns.map((e) => {
          var obj = {};
          if (e.field == "honbuid") {
            return {
              type: "numeric",
              key: e.field,
              title: e.title,
              field: e.field,
              filtering: false,
              editable: "never",
              filterCellStyle: {
                padding: 0,
              },
              //width: 100,
              cellStyle: {
                paddingLeft: 30,
                paddingTop: 0,
                paddingRight: 0,
                paddingBottom: 0,
                width: 10,
                minWidth: 10,
              },
              headerStyle: {
                paddingLeft: 30,
                paddingTop: 0,
                paddingRight: 0,
                paddingBottom: 0,
                width: 10,
                minWidth: 10,
              },
            };
          } else if (e.field == "name") {
            return {
              key: e.field,
              title: e.title,
              field: e.field,
              filtering: false,
              render: (rowData) => getIcon(rowData),
              editable: "never",
              filterCellStyle: {
                padding: 0,
              },
              // width: 150,
              cellStyle: {
                padding: 0,
                width: 150,
                minWidth: 150,
              },
              headerStyle: {
                padding: 0,
                width: 100,
                minWidth: 100,
              },
            };
          } else if (e.field == "unitname") {
            return {
              key: e.field,
              title: e.title,
              field: e.field,
              editable: "never",
              // maxwidth: "10%",
              filterCellStyle: {
                padding: 0,
              },
              // width: 100,
              cellStyle: {
                padding: 0,
                width: 100,
                minWidth: 100,
              },
              headerStyle: {
                padding: 0,
                width: 100,
                minWidth: 100,
              },
            };
          } else if (e.field == "vote") {
            obj = { "-": "", 済: "済" };
            return {
              key: e.field,
              title: e.title,
              field: e.field,
              lookup: obj,
              editable: "never",
              filterCellStyle: {
                padding: 0,
              },
              // width: 100,
              cellStyle: {
                padding: 0,
                width: 100,
                minWidth: 100,
              },
              headerStyle: {
                padding: 0,
                width: 100,
                minWidth: 100,
              },
            };
          } else if (
            [
              "vote1",
              "vote2",
              "vote3",
              "vote4",
              "vote5",
              "vote6",
              "vote7",
              "vote8",
              "vote9",
              "vote10",
              "vote11",
              "vote12",
              "vote13",
              "vote14",
              "vote15",
              "vote16",
              "vote17",
              "vote18",
              "vote19",
              "vote20",
            ].includes(e.field) &&
            e.type == "radio"
          ) {
            var fieldsuffix = e.field.replace("vote", "");
            obj = { "-": "　" };
            result2.data.menuitems
              .filter((f) => f.seq === fieldsuffix)
              .forEach((e) => {
                obj[e.no] = e.text;
              });
            return {
              key: e.field,
              filterCellStyle: {
                padding: 0,
              },
              // width: 100,
              cellStyle: {
                padding: 0,
                width: 100,
                minWidth: 100,
              },
              headerStyle: {
                padding: 0,
                width: 100,
                minWidth: 100,
              },
              title: e.title,
              field: e.field,
              lookup: obj,
              // editComponent: (t) => (
              //   <FormControl variant="outlined">
              //     <Select
              //       value={t.value}
              //       onChange={(e) => {
              //         t.onChange(e.target.value);
              //         console.group(e.target.value);
              //         this.currentEditingTarget = e.target.value;
              //       }}
              //     >
              //       {result2.data.menuitems
              //         .filter((f) => f.seq === fieldsuffix)
              //         .map((e) => {
              //           return (
              //             <MenuItem key={e.no} value={e.no}>
              //               {e.text}
              //             </MenuItem>
              //           );
              //         })}
              //     </Select>
              //   </FormControl>
              // ),
            };
          } else if (["inmmdd", "upmmdd"].includes(e.field)) {
            return {
              key: e.field,
              title: e.title,
              field: e.field,
              hidden: true,
              export: true,
              // width: 100,
              cellStyle: {
                padding: 0,
                width: 100,
                minWidth: 100,
              },
              headerStyle: {
                padding: 0,
                width: 100,
                minWidth: 100,
              },
            };
          } else {
            return {
              key: e.field,
              title: e.title,
              field: e.field,
              filterCellStyle: {
                padding: 0,
              },
              // width: 100,
              cellStyle: {
                padding: 0,
                width: 100,
                minWidth: 100,
              },
              headerStyle: {
                padding: 0,
                width: 100,
                minWidth: 100,
              },
            };
          }
        })
      );
      // setVoteSumarry(result2.data.votesumarry);
      // setTextSumarry(result2.data.textsumarry);
      setSumCol(
        result2.data.sumcol.map((e) => {
          if (e.field == "all") {
            return {
              title: e.title,
              field: e.field,
              filtering: false,
              editable: "never",
              render: (rowData) => {
                return (
                  <StyledCntLink
                    component="button"
                    variant="body2"
                    onClick={() => {
                      // setOpenViewObj({
                      //   kbn: "all",
                      //   anpiid: selectedAnpiid,
                      //   vote: "1",
                      //   hpcode: "",
                      //   unitcode: rowData.unitcode,
                      //   seq: "",
                      //   no: "",
                      //   isopen: true,
                      //   name: "済",
                      // });
                      setOpenChartObj({
                        kbn: "unit",
                        vote: "all",
                        hpcode: rowData.hpcode,
                        unitcode: rowData.unitcode,
                        anpiid: rowData.anpiid,
                        isopen: true,
                        title: rowData.unitname + " 合計",
                      });
                    }}
                  >
                    {rowData.all}
                  </StyledCntLink>
                );
              },
            };
          } else if (e.field == "vote") {
            return {
              title: e.title,
              field: e.field,
              filtering: false,
              editable: "never",
              render: (rowData) => {
                if (rowData.vote != "0") {
                  return (
                    <StyledCntLink
                      component="button"
                      variant="body2"
                      onClick={() => {
                        // setOpenViewObj({
                        //   kbn: "unit",
                        //   anpiid: selectedAnpiid,
                        //   vote: "1",
                        //   hpcode: "",
                        //   unitcode: rowData.unitcode,
                        //   seq: "",
                        //   no: "",
                        //   isopen: true,
                        //   name: "済",
                        // });
                        setOpenChartObj({
                          kbn: "unit",
                          vote: "vote",
                          hpcode: rowData.hpcode,
                          unitcode: rowData.unitcode,
                          anpiid: rowData.anpiid,
                          isopen: true,
                          title: rowData.unitname + " 済",
                        });
                      }}
                    >
                      {rowData.vote}
                    </StyledCntLink>
                  );
                } else {
                  return rowData.vote;
                }
              },
            };
          } else if (e.field == "novote") {
            return {
              title: e.title,
              field: e.field,
              filtering: false,
              editable: "never",
              render: (rowData) => {
                if (rowData.novote != "0") {
                  return (
                    <StyledCntLink
                      component="button"
                      variant="body2"
                      onClick={() => {
                        // setOpenViewObj({
                        //   kbn: "unit",
                        //   anpiid: selectedAnpiid,
                        //   vote: "0",
                        //   hpcode: "",
                        //   unitcode: rowData.unitcode,
                        //   seq: "",
                        //   no: "",
                        //   isopen: true,
                        //   name: "未",
                        // });
                        setOpenChartObj({
                          kbn: "unit",
                          vote: "novote",
                          hpcode: rowData.hpcode,
                          unitcode: rowData.unitcode,
                          anpiid: rowData.anpiid,
                          isopen: true,
                          title: rowData.unitname + " 未",
                        });
                      }}
                    >
                      {rowData.novote}
                    </StyledCntLink>
                  );
                } else {
                  return rowData.novote;
                }
              },
            };
          } else if (e.field == "hpname") {
            return {
              title: e.title,
              field: e.field,
              filterCellStyle: {
                padding: 0,
              },
              // width: 100,
              cellStyle: {
                paddingLeft: 30,
                width: 100,
                minWidth: 100,
              },
              headerStyle: {
                paddingLeft: 30,
                width: 100,
                minWidth: 100,
              },
            };
          } else {
            return {
              title: e.title,
              field: e.field,
              filterCellStyle: {
                padding: 0,
              },
              // width: 100,
              cellStyle: {
                padding: 0,
                width: 100,
                minWidth: 100,
              },
              headerStyle: {
                padding: 0,
                width: 100,
                minWidth: 100,
              },
            };
          }
        })
      );
      setSumData(result2.data.sumdata);

      setAllCol(
        result2.data.allcol.map((e) => {
          if (e.field == "all") {
            return {
              title: e.title,
              field: e.field,
              filtering: false,
              editable: "never",
              render: (rowData) => {
                return (
                  <StyledCntLink
                    component="button"
                    variant="body2"
                    onClick={() => {
                      // setOpenViewObj({
                      //   kbn: "hp",
                      //   anpiid: selectedAnpiid,
                      //   vote: "1",
                      //   hpcode: rowData.hpcode,
                      //   unitcode: "",
                      //   seq: "",
                      //   no: "",
                      //   isopen: true,
                      //   name: "済",
                      // });
                      setOpenChartObj({
                        kbn: "hp",
                        vote: "all",
                        hpcode: rowData.hpcode,
                        unitcode: rowData.unitcode,
                        anpiid: rowData.anpiid,
                        isopen: true,
                        title: rowData.hpname + " 合計",
                      });
                    }}
                  >
                    {rowData.all}
                  </StyledCntLink>
                );
              },
            };
          } else if (e.field == "vote") {
            return {
              title: e.title,
              field: e.field,
              filtering: false,
              editable: "never",
              render: (rowData) => {
                if (rowData.vote != "0") {
                  return (
                    <StyledCntLink
                      component="button"
                      variant="body2"
                      onClick={() => {
                        // setOpenViewObj({
                        //   kbn: "hp",
                        //   anpiid: selectedAnpiid,
                        //   vote: "1",
                        //   hpcode: rowData.hpcode,
                        //   unitcode: "",
                        //   seq: "",
                        //   no: "",
                        //   isopen: true,
                        //   name: "済",
                        // });
                        setOpenChartObj({
                          kbn: "hp",
                          vote: "vote",
                          hpcode: rowData.hpcode,
                          unitcode: rowData.unitcode,
                          anpiid: rowData.anpiid,
                          isopen: true,
                          title: rowData.hpname + " 済",
                        });
                      }}
                    >
                      {rowData.vote}
                    </StyledCntLink>
                  );
                } else {
                  return rowData.vote;
                }
              },
            };
          } else if (e.field == "novote") {
            return {
              title: e.title,
              field: e.field,
              filtering: false,
              editable: "never",
              render: (rowData) => {
                if (rowData.novote != "0") {
                  return (
                    <StyledCntLink
                      component="button"
                      variant="body2"
                      onClick={() => {
                        // setOpenViewObj({
                        //   kbn: "hp",
                        //   anpiid: selectedAnpiid,
                        //   vote: "0",
                        //   hpcode: rowData.hpcode,
                        //   unitcode: "",
                        //   seq: "",
                        //   no: "",
                        //   isopen: true,
                        //   name: "未",
                        // });
                        setOpenChartObj({
                          kbn: "hp",
                          vote: "novote",
                          hpcode: rowData.hpcode,
                          unitcode: rowData.unitcode,
                          anpiid: rowData.anpiid,
                          isopen: true,
                          title: rowData.hpname + " 未",
                        });
                      }}
                    >
                      {rowData.novote}
                    </StyledCntLink>
                  );
                } else {
                  return rowData.novote;
                }
              },
            };
          } else if (e.field == "hpname") {
            return {
              title: e.title,
              field: e.field,
              filterCellStyle: {
                padding: 0,
              },
              // width: 100,
              cellStyle: {
                paddingLeft: 30,
                width: 100,
                minWidth: 100,
              },
              headerStyle: {
                paddingLeft: 30,
                width: 100,
                minWidth: 100,
              },
            };
          } else {
            return {
              title: e.title,
              field: e.field,
              filterCellStyle: {
                padding: 0,
              },
              // width: 100,
              cellStyle: {
                padding: 0,
                width: 100,
                minWidth: 100,
              },
              headerStyle: {
                padding: 0,
                width: 100,
                minWidth: 100,
              },
            };
          }
        })
      );
      setAllData(result2.data.alldata);

      //setValueSumarry(result2.data.valuesumarry);
      // var textgroup = result2.data.textsumarry.reduce(function (result, current) {
      //   var element = result.find(function (p) {
      //     return p.seq === current.seq;
      //   });
      //   if (element) {
      //     element.count++;
      //   } else {
      //     result.push({
      //       seq: current.seq,
      //       title: current.title,
      //       count: 1,
      //     });
      //   }
      //   return result;
      // }, []);
      // setTextGroup(textgroup);
    }
    setLoading(false);
  };
  useEffect(() => {
    if (selectedAnpiid && selectedIsTemp == false) {
      subproc();
    }
  }, [state.addVoteCnt, selectedAnpiid]);
  const getIcon = (rowData) => {
    return (
      <div className="chat-name-wrapper">
        <img
          src={STATIC_URL + `/downloadAvatar?honbuid=${rowData.honbuid}&size=small`}
          width="32"
          height="32"
          alt=""
          className="chat-image"
        />
        {rowData.name}
      </div>
    );
  };
  const [openAnpiCreate, setOpenAnpiCreate] = useState(false);
  const handleAnpiCreateClose = () => {
    setOpenAnpiCreate(false);
  };

  const [openPushObj, setOpenPushObj] = useState({ honbuid: "", isopen: false });
  const handlePushClose = () => {
    setOpenPushObj({ ...openPushObj, isopen: false });
  };

  const handleAnpiVoteClose = () => {
    setOpenVoteObj({ ...openVoteObj, isopen: false });
  };
  const [openAnpiId, setOpenAnpiId] = useState(null);

  const [anchorEl, setAnchorEl] = useState(null);
  const settingClick = (event, e) => {
    setOpenAnpiId(e);
    setAnchorEl(event.currentTarget);
    event.stopPropagation();
  };
  const settingClose = () => {
    setAnchorEl(null);
  };

  const [openModal, setOpenModal] = useState(false);
  const [openEndModal, setOpenEndModal] = useState(false);
  const [openAnpiCopy, setOpenAnpiCopy] = useState(false);
  const handleAnpiCopyClose = () => {
    setOpenAnpiCopy(false);
  };
  const delAnpi = async (q) => {
    const result = await custom_axios(API_URL + `/delAnpi?anpiid=${q}`);
    const status = result.status;
    if (status === 401) {
      history.replace("/loginError");
    }
    if (status === 200) {
      //setSelectedAnpiid(null);
      mainproc();
      setOpenModal(false);
    }
  };
  const endAnpi = async (q) => {
    const result = await custom_axios(API_URL + `/endAnpi?anpiid=${q}`);
    const status = result.status;
    if (status === 401) {
      history.replace("/loginError");
    }
    if (status === 200) {
      //setSelectedAnpiid(null);
      mainproc();
      setOpenEndModal(false);
    }
  };
  const [expanded, setExpanded] = React.useState(true);
  const [endexpanded, setEndExpanded] = React.useState(true);
  const [editexpanded, setEditExpanded] = React.useState(true);
  const [tempexpanded, setTempExpanded] = React.useState(true);
  const [quakeexpanded, setQuakeExpanded] = React.useState(true);
  const [openVoteObj, setOpenVoteObj] = React.useState({ honbuid: 0, isopen: false });
  const [openViewObj, setOpenViewObj] = React.useState({
    kbn: "",
    anpiid: 0,
    hpcode: "",
    unitcode: "",
    isopen: false,
    vote: "",
    seq: "",
    no: "",
  });
  const [openChartObj, setOpenChartObj] = React.useState({
    kbn: "",
    hpcode: "",
    unitcode: "",
    anpiid: "",
    isopen: false,
  });
  const handleAnpiChartClose = () => {
    setOpenChartObj({ ...openChartObj, isopen: false });
  };
  const handleAnpiUserViewClose = () => {
    setOpenViewObj({ ...openViewObj, isopen: false });
  };
  const MemoAnpiChart = useMemo(() => {
    return <ShowAnpiChart openChartObj={openChartObj} handleClose={handleAnpiChartClose} />;
  }, [openChartObj.isopen]);
  const MemoAnpiCreate = useMemo(() => {
    return (
      <ShowAnpiCreate
        open={openAnpiCreate}
        openAnpiId={openAnpiId}
        handleClose={handleAnpiCreateClose}
        afterProc={mainproc}
        isCopy={false}
      />
    );
  }, [openAnpiCreate, openAnpiId]);

  const MemoAnpiCopy = useMemo(() => {
    return (
      <ShowAnpiCreate
        open={openAnpiCopy}
        openAnpiId={openAnpiId}
        handleClose={handleAnpiCopyClose}
        afterProc={mainproc}
        isCopy={true}
      />
    );
  }, [openAnpiCopy, openAnpiId]);

  const MemoAnpiUserView = useMemo(() => {
    return <ShowAnpiUserView openViewObj={openViewObj} handleClose={handleAnpiUserViewClose} />;
  }, [openViewObj]);
  const MemoAnpiVote = useMemo(() => {
    return (
      <ShowAnpiVote
        open={openVoteObj.isopen}
        honbuid={openVoteObj.honbuid}
        openAnpiId={selectedAnpiid}
        handleClose={handleAnpiVoteClose}
        afterProc={voteproc}
        roomid={null}
        isEnabled={true}
      />
    );
  }, [openVoteObj.isopen, openVoteObj.honbuid, selectedAnpiid]);

  const handleSubSave = async (anpiid, honbuid, seq, no, value) => {
    const body = {
      anpiid: anpiid,
      honbuid: honbuid,
      seq: seq,
      no: no,
      value: value,
    };

    //console.log("post前", body);
    const result = await custom_axios_post(API_URL + "/voteAnpiSub", body);

    if (result.status === 401) {
      history.replace("/loginError");
    }
  };

  const handlePush = async () => {
    let honbuid = localStorage.getItem("honbuid");
    const body = {
      anpiid: selectedAnpiid,
      honbuid: honbuid,
      roomid: openPushObj.honbuid,
    };

    //console.log("post前", body);
    const result = await custom_axios_post(API_URL + "/pushAnpiMember", body);

    if (result.status === 401) {
      history.replace("/loginError");
    }
    handlePushClose();
    setSnackPushOpen(true);
  };

  // const onPieClick = (index) => {
  //   var votevalue = index.name == "未" ? "0" : "1";
  //   setOpenViewObj({
  //     anpiid: selectedAnpiid,
  //     vote: votevalue,
  //     unitcode: rowData.unitcode,
  //     seq: "",
  //     no: "",
  //     isopen: true,
  //   });
  // };

  //const [rowsPerPage] = useState(10);

  return (
    <Dialog fullScreen open={open} onClose={handleClose} fullWidth maxWidth={"xl"} TransitionComponent={Transition}>
      <StyledDialogTitle id="max-width-dialog-title">
        <div className="show-member-search">
          <div className="show-member-search">安否確認一覧</div>
          <div className="question-title-actions">
            <StyledButton
              onClick={() => {
                subproc();
              }}
              variant="outlined"
            >
              画面更新
            </StyledButton>
            <div style={{ width: "20px" }}></div>
            {createOk && (
              <StyledButton
                onClick={() => {
                  setOpenAnpiId(null);
                  setOpenAnpiCreate(true);
                }}
                variant="outlined"
              >
                安否確認作成
              </StyledButton>
            )}

            <div style={{ width: "20px" }}></div>
            <StyledButton onClick={handleClose} variant="outlined">
              閉じる (Esc)
            </StyledButton>
          </div>
        </div>
      </StyledDialogTitle>

      <StyledDialogContent>
        {MemoAnpiCreate}
        {MemoAnpiCopy}
        {MemoAnpiVote}
        {MemoAnpiUserView}
        {MemoAnpiChart}
        <div className="question-dialog-wraper">
          <div className="anpi-member-list">
            <MuiAccordion square expanded={expanded} onChange={() => setExpanded(!expanded)}>
              <MuiAccordionSummary aria-controls="panel2d-content" id="panel2d-header">
                <div className="ac-group-between">
                  <StyledAnpiRow>
                    <div>実施中</div>
                  </StyledAnpiRow>
                  <FaAngleDown></FaAngleDown>
                </div>
              </MuiAccordionSummary>

              {localList &&
                localList
                  .filter((f) => f.istemp != "1" && f.isend != "1" && f.istemplate == "0")
                  .sort(function (a, b) {
                    if (a.timestring > b.timestring) {
                      return -1;
                    }
                    if (a.timestring < b.timestring) {
                      return 1;
                    }
                    return 0;
                  })
                  .map((e) => {
                    return (
                      <MuiAccordionDetails key={e.anpiid}>
                        <StyledAnpiWrapper
                          bigfont={selectedAnpiid === e.anpiid}
                          onClick={() => {
                            handleClick(e.anpiid, e.istemp);
                          }}
                        >
                          <div className="anpi-member-content question-padding-wrap">
                            <div className="qustion-title-text-wrapper">
                              {e.isrenkei == "1" && (
                                <div>
                                  <StyledChip
                                    variant="outlined"
                                    color="secondary"
                                    size="small"
                                    label="気象庁連携"
                                  ></StyledChip>
                                </div>
                              )}
                              <Badge
                                color="secondary"
                                badgeContent={Number(e.unvotecnt)}
                                invisible={Number(e.unvotecnt) === 0}
                              >
                                <div className={`read-member-name-list`}>{e.title}</div>
                              </Badge>
                              <span style={{ fontSize: "80%", marginLeft: "5px" }}>{e.time}</span>
                            </div>

                            <div style={{ marginRight: "5px" }}>
                              <IconButton
                                aria-label="setting"
                                component="span"
                                onClick={(event) => settingClick(event, e)}
                              >
                                <SettingsIcon />
                              </IconButton>
                            </div>
                          </div>
                        </StyledAnpiWrapper>
                      </MuiAccordionDetails>
                    );
                  })}
            </MuiAccordion>

            <MuiAccordion square expanded={endexpanded} onChange={() => setEndExpanded(!endexpanded)}>
              <MuiAccordionSummary aria-controls="panel2d-content" id="panel2d-header">
                <div className="ac-group-between">
                  <StyledAnpiRow>
                    <div>終了</div>
                  </StyledAnpiRow>
                  <FaAngleDown></FaAngleDown>
                </div>
              </MuiAccordionSummary>

              {localList &&
                localList
                  .filter((f) => f.istemp != "1" && f.isend == "1" && f.istemplate == "0")
                  .sort(function (a, b) {
                    if (a.timestring > b.timestring) {
                      return -1;
                    }
                    if (a.timestring < b.timestring) {
                      return 1;
                    }
                    return 0;
                  })
                  .map((e) => {
                    return (
                      <MuiAccordionDetails key={e.anpiid}>
                        <StyledAnpiWrapper
                          bigfont={selectedAnpiid === e.anpiid}
                          onClick={() => {
                            handleClick(e.anpiid, e.istemp);
                          }}
                        >
                          <div className="anpi-member-content question-padding-wrap">
                            <div className="qustion-title-text-wrapper">
                              {e.isrenkei == "1" && (
                                <div>
                                  <StyledChip
                                    variant="outlined"
                                    color="secondary"
                                    size="small"
                                    label="気象庁連携"
                                  ></StyledChip>
                                </div>
                              )}
                              <Badge
                                color="secondary"
                                badgeContent={Number(e.unvotecnt)}
                                invisible={Number(e.unvotecnt) === 0}
                              >
                                <div className={`read-member-name-list`}>{e.title}</div>
                              </Badge>
                              <span style={{ fontSize: "80%", marginLeft: "5px" }}>{e.time}</span>
                            </div>

                            <div style={{ marginRight: "5px" }}>
                              <IconButton
                                aria-label="setting"
                                component="span"
                                onClick={(event) => settingClick(event, e)}
                              >
                                <SettingsIcon />
                              </IconButton>
                            </div>
                          </div>
                        </StyledAnpiWrapper>
                      </MuiAccordionDetails>
                    );
                  })}
            </MuiAccordion>

            <MuiAccordion square expanded={editexpanded} onChange={() => setEditExpanded(!editexpanded)}>
              <MuiAccordionSummary aria-controls="panel2d-content" id="panel2d-header">
                <div className="ac-group-between">
                  <StyledAnpiRow>
                    <div>一時保存中</div>
                  </StyledAnpiRow>
                  <FaAngleDown></FaAngleDown>
                </div>
              </MuiAccordionSummary>

              {localList &&
                localList
                  .filter((f) => f.istemp == "1" && f.isrenkei == "0")
                  .sort(function (a, b) {
                    if (a.timestring > b.timestring) {
                      return -1;
                    }
                    if (a.timestring < b.timestring) {
                      return 1;
                    }
                    return 0;
                  })
                  .map((e) => {
                    return (
                      <MuiAccordionDetails key={e.anpiid}>
                        <StyledAnpiWrapper
                          bigfont={selectedAnpiid === e.anpiid}
                          onClick={() => {
                            handleClick(e.anpiid, e.istemp);
                          }}
                        >
                          <div className="anpi-member-content question-padding-wrap">
                            <div className="qustion-title-text-wrapper">
                              {e.isrenkei == "1" && (
                                <div>
                                  <StyledChip
                                    variant="outlined"
                                    color="secondary"
                                    size="small"
                                    label="気象庁連携"
                                  ></StyledChip>
                                </div>
                              )}
                              <Badge
                                color="secondary"
                                badgeContent={Number(e.unvotecnt)}
                                invisible={Number(e.unvotecnt) === 0}
                              >
                                <div className={`read-member-name-list`}>{e.title}</div>
                              </Badge>
                              <span style={{ fontSize: "80%", marginLeft: "5px" }}>{e.time}</span>
                            </div>

                            <div style={{ marginRight: "5px" }}>
                              <IconButton
                                aria-label="setting"
                                component="span"
                                onClick={(event) => settingClick(event, e)}
                              >
                                <SettingsIcon />
                              </IconButton>
                            </div>
                          </div>
                        </StyledAnpiWrapper>
                      </MuiAccordionDetails>
                    );
                  })}
            </MuiAccordion>

            <MuiAccordion square expanded={quakeexpanded} onChange={() => setQuakeExpanded(!quakeexpanded)}>
              <MuiAccordionSummary aria-controls="panel2d-content" id="panel2d-header">
                <div className="ac-group-between">
                  <StyledAnpiRow>
                    <div>気象庁連携</div>
                  </StyledAnpiRow>
                  <FaAngleDown></FaAngleDown>
                </div>
              </MuiAccordionSummary>

              {localList &&
                localList
                  .filter((f) => f.istemp == "1" && f.isrenkei == "1")
                  .sort(function (a, b) {
                    if (a.timestring > b.timestring) {
                      return -1;
                    }
                    if (a.timestring < b.timestring) {
                      return 1;
                    }
                    return 0;
                  })
                  .map((e) => {
                    return (
                      <MuiAccordionDetails key={e.anpiid}>
                        <StyledAnpiWrapper
                          bigfont={selectedAnpiid === e.anpiid}
                          onClick={() => {
                            handleClick(e.anpiid, e.istemp);
                          }}
                        >
                          <div className="anpi-member-content question-padding-wrap">
                            <div className="qustion-title-text-wrapper">
                              {e.isrenkei == "1" && (
                                <div>
                                  <StyledChip
                                    variant="outlined"
                                    color="secondary"
                                    size="small"
                                    label="気象庁連携"
                                  ></StyledChip>
                                </div>
                              )}
                              <Badge
                                color="secondary"
                                badgeContent={Number(e.unvotecnt)}
                                invisible={Number(e.unvotecnt) === 0}
                              >
                                <div className={`read-member-name-list`}>{e.title}</div>
                              </Badge>
                              <span style={{ fontSize: "80%", marginLeft: "5px" }}>{e.time}</span>
                            </div>

                            <div style={{ marginRight: "5px" }}>
                              <IconButton
                                aria-label="setting"
                                component="span"
                                onClick={(event) => settingClick(event, e)}
                              >
                                <SettingsIcon />
                              </IconButton>
                            </div>
                          </div>
                        </StyledAnpiWrapper>
                      </MuiAccordionDetails>
                    );
                  })}
            </MuiAccordion>

            <MuiAccordion square expanded={tempexpanded} onChange={() => setTempExpanded(!tempexpanded)}>
              <MuiAccordionSummary aria-controls="panel2d-content" id="panel2d-header">
                <div className="ac-group-between">
                  <StyledAnpiRow>
                    <div>テンプレート</div>
                  </StyledAnpiRow>
                  <FaAngleDown></FaAngleDown>
                </div>
              </MuiAccordionSummary>

              {localList &&
                localList
                  .filter((f) => f.istemplate == "1")
                  .sort(function (a, b) {
                    if (a.anpiid > b.anpiid) {
                      return -1;
                    }
                    if (a.anpiid < b.anpiid) {
                      return 1;
                    }
                    return 0;
                  })
                  .map((e) => {
                    return (
                      <MuiAccordionDetails key={e.anpiid}>
                        <StyledAnpiWrapper
                          bigfont={selectedAnpiid === e.anpiid}
                          onClick={() => {
                            //handleClick(e.anpiid, e.istemp);
                          }}
                        >
                          <div className="anpi-member-content question-padding-wrap">
                            <div className="qustion-title-text-wrapper">
                              <Badge
                                color="secondary"
                                badgeContent={Number(e.unvotecnt)}
                                invisible={Number(e.unvotecnt) === 0}
                              >
                                <div className={`read-member-name-list`}>{e.title}</div>
                              </Badge>
                              <span style={{ fontSize: "80%", marginLeft: "5px" }}>{e.time}</span>
                            </div>

                            <div style={{ marginRight: "5px" }}>
                              <IconButton
                                aria-label="setting"
                                component="span"
                                onClick={(event) => settingClick(event, e)}
                              >
                                <SettingsIcon />
                              </IconButton>
                            </div>
                          </div>
                        </StyledAnpiWrapper>
                      </MuiAccordionDetails>
                    );
                  })}
            </MuiAccordion>

            <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={settingClose}>
              {openAnpiId && openAnpiId.istemp == "1" && openAnpiId.istemplate == "0" && (
                <MenuItem
                  onClick={() => {
                    settingClose();
                    setOpenAnpiCreate(true);
                  }}
                >
                  修正
                </MenuItem>
              )}
              {openAnpiId && openAnpiId.istemp == "0" && openAnpiId.isend == "0" && openAnpiId.istemplate == "0" && (
                <MenuItem
                  onClick={() => {
                    settingClose();
                    setOpenEndModal(true);
                  }}
                >
                  終了
                </MenuItem>
              )}
              {openAnpiId && openAnpiId.istemplate == "0" && (
                <MenuItem
                  onClick={() => {
                    settingClose();
                    setOpenModal(true);
                  }}
                >
                  削除
                </MenuItem>
              )}
              {openAnpiId && openAnpiId.istemplate == "0" && (
                <MenuItem
                  onClick={() => {
                    settingClose();
                    setOpenAnpiCopy(true);
                  }}
                >
                  複製
                </MenuItem>
              )}
              {openAnpiId && openAnpiId.istemplate == "1" && (
                <MenuItem
                  onClick={() => {
                    settingClose();
                    setOpenAnpiCreate(true);
                  }}
                >
                  テンプレートから作成
                </MenuItem>
              )}
            </Menu>
            <ShowCommon
              open={openPushObj.isopen}
              title="プッシュ通知しますか？"
              handleClose={() => {
                handlePushClose();
              }}
              handleCallBack={() => handlePush()}
            ></ShowCommon>
            <ShowCommon
              open={openModal}
              title="削除しますか？"
              handleClose={() => {
                setOpenModal(false);
              }}
              handleCallBack={() => delAnpi(openAnpiId.anpiid)}
            ></ShowCommon>
            <ShowCommon
              open={openEndModal}
              title="終了しますか？"
              handleClose={() => {
                setOpenEndModal(false);
              }}
              handleCallBack={() => endAnpi(openAnpiId.anpiid)}
            ></ShowCommon>
          </div>

          {loading ? (
            <div>
              <StyledCircularProgress />
            </div>
          ) : selectedAnpiid && selectedIsTemp == false ? (
            <div className="anpi-member-list-right">
              {/* <AppBar position="static" color="default"> */}
              <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
                <Tab label="概要" />
                {/* <Tab label="チャート" /> */}
                <Tab label="詳細" />
              </Tabs>
              {/* </AppBar> */}

              {value == 0 ? (
                <div>
                  <div>
                    <MaterialTable
                      key="1"
                      title={
                        <StyledTypography variant="h4" component="div">
                          施設別
                        </StyledTypography>
                      }
                      toolbar={false}
                      showTitle={false}
                      columns={allcol}
                      onFilterChange={(e) => console.log(e)}
                      data={alldata}
                      localization={{
                        header: { actions: "" },
                        toolbar: { searchPlaceholder: "検索" },
                      }}
                      icons={tableIcons}
                      options={{
                        showTitle: true,
                        search: false,
                        paging: false,
                        //toolbar: false,
                        // rowStyle: (rowData) => {
                        //   const rowBackgroundColor = rowData.tableData.id % 2 == 0 ? "#FFFFFF" : "#E3E3E3";
                        //   return { backgroundColor: rowBackgroundColor };
                        // },
                      }}
                    ></MaterialTable>
                  </div>
                  <div>
                    <MaterialTable
                      key="2"
                      title={
                        <StyledTypography variant="h4" component="div">
                          部署別
                        </StyledTypography>
                      }
                      columns={sumcol}
                      onFilterChange={(e) => console.log(e)}
                      data={sumdata}
                      localization={{
                        header: { actions: "" },
                        toolbar: { searchPlaceholder: "検索" },
                      }}
                      icons={tableIcons}
                      options={{
                        showTitle: true,
                        search: false,
                        paging: false,
                        //toolbar: false,
                        // rowStyle: (rowData) => {
                        //   const rowBackgroundColor = rowData.tableData.id % 2 == 0 ? "#FFFFFF" : "#E3E3E3";
                        //   return { backgroundColor: rowBackgroundColor };
                        // },
                      }}
                    ></MaterialTable>
                  </div>
                </div>
              ) : (
                <div></div>
              )}

              {value == 1 ? (
                <div>
                  <MaterialTable
                    key="3"
                    title={
                      <StyledTypography variant="h4" component="div">
                        個人別
                      </StyledTypography>
                    }
                    columns={membersColumns}
                    onFilterChange={(e) => console.log(e)}
                    data={membersList}
                    localization={{
                      header: { actions: "" },
                      toolbar: { searchPlaceholder: "検索" },
                    }}
                    actions={[
                      () => ({
                        icon: () => <StyledLink href="#">修正</StyledLink>,
                        onClick: (event, rowData) => setOpenVoteObj({ honbuid: rowData.honbuid, isopen: true }),
                      }),
                      // () => ({
                      //   icon: () => <StyledLink href="#">通知</StyledLink>,
                      //   onClick: (event, rowData) => setOpenPushObj({ honbuid: rowData.honbuid, isopen: true }),
                      // }),
                    ]}
                    icons={tableIcons}
                    options={{
                      showTitle: true,
                      // exportButton: {
                      //   csv: true,
                      //   pdf: false,
                      // },
                      //pageSize: rowsPerPage,
                      //pageSizeOptions: [10, 50, 100, { value: membersList.length, label: "All" }],
                      exportButton: true,
                      filtering: true,
                      paging: false,
                      // rowStyle: (rowData) => {
                      //   const rowBackgroundColor = rowData.tableData.id % 2 == 0 ? "#FFFFFF" : "#E3E3E3";
                      //   return { backgroundColor: rowBackgroundColor };
                      // },
                    }}
                    cellEditable={{
                      onCellEditApproved: (newValue, oldValue, rowData, columnDef) => {
                        return new Promise((resolve) => {
                          const clonedData = [...membersList];
                          clonedData[rowData.tableData.id][columnDef.field] = newValue;
                          clonedData[rowData.tableData.id]["vote"] = "済";
                          var lhonbuid = clonedData[rowData.tableData.id]["honbuid"];
                          setMembersList(clonedData);

                          var col = columns.filter((f) => f.field === columnDef.field)[0];
                          var fieldsuffix = columnDef.field.slice(-1);
                          if (col.type == "radio") {
                            handleSubSave(selectedAnpiid, lhonbuid, fieldsuffix, newValue, newValue);
                          } else {
                            handleSubSave(selectedAnpiid, lhonbuid, fieldsuffix, "0", newValue);
                          }
                          setSnackOpen(true);
                          setTimeout(resolve, 1000);
                        });
                      },
                    }}
                  ></MaterialTable>
                </div>
              ) : (
                <div></div>
              )}
            </div>
          ) : (
            <div className="anpi-member-list-right"></div>
          )}
        </div>
        <Snackbar
          open={snackopen}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          autoHideDuration={1000}
          onClose={() => {
            setSnackOpen(false);
          }}
          message="保存しました。"
        ></Snackbar>
        <Snackbar
          open={snackpushopen}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          autoHideDuration={1000}
          onClose={() => {
            setSnackPushOpen(false);
          }}
          message="プッシュ通知しました。"
        ></Snackbar>
      </StyledDialogContent>
    </Dialog>
  );
}

export default ShowAnpiList;
