/* eslint-disable react/display-name */
/* eslint-disable react/prop-types */
import "./ShowUserInfo.css";
import React, { useEffect, useState, useMemo, useContext } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import { API_URL, custom_axios } from "../Common/Common";
import useReactRouter from "use-react-router";
import { Store } from "../Store/store";
// import Select from "@material-ui/core/Select";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ShowNewQueCreate from "../Common/ShowNewQueCreate";
// import MaterialTable from "material-table";
import ShowCommon from "../Common/ShowCommon";
import IconButton from "@material-ui/core/IconButton";
import SettingsIcon from "@material-ui/icons/Settings";
import Badge from "@material-ui/core/Badge";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Slide from "@material-ui/core/Slide";
import { FaAngleDown } from "react-icons/fa";
import { withStyles } from "@material-ui/core/styles";
import styled from "styled-components";
import { PieChart, Pie, Cell, Tooltip } from "recharts";
import ShowNewQueVote from "./ShowNewQueVote";
import Snackbar from "@material-ui/core/Snackbar";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import ShowPlusQuestionUserView from "../Common/ShowPlusQuestionUserView";
import purple from "@material-ui/core/colors/purple";
import green from "@material-ui/core/colors/green";
import teal from "@material-ui/core/colors/teal";
import indigo from "@material-ui/core/colors/indigo";
import blue from "@material-ui/core/colors/blue";
import deepPurple from "@material-ui/core/colors/deepPurple";
import pink from "@material-ui/core/colors/pink";
import red from "@material-ui/core/colors/red";
import deepOrange from "@material-ui/core/colors/deepOrange";
import orange from "@material-ui/core/colors/orange";

const StyledChartMain = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const StyledCenter = styled.div`
  text-align: center;
`;
const TopDiv = styled.div`
  margin-top: 5px;
`;
const StyledPieCenter = styled.div`
  width: calc(100vw - 220px);
`;
const StyledChartSub = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
// const StyledTypography = styled(Typography)`
//   margin: 15px;
//   color: ${(props) => props.theme.palette.primary.main};
// `;
// const StyledCntLink = styled(Link)`
//   font-size: large;
// `;
// const tableIcons = {
//   Save: forwardRef((props, ref) => <Save {...props} ref={ref} />),
//   Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
//   Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
//   Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
//   Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
//   DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
//   Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
//   Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
//   Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
//   FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
//   LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
//   NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
//   PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
//   ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
//   Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
//   SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
//   ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
//   ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
// };
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const StyledAnpiRow = styled.div`
  margin-left: 5px;
  margin-top: 2px;
`;

const MuiAccordion = withStyles({
  root: {
    border: "1px solid rgba(0, 0, 0, .125)",
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
})(Accordion);

const MuiAccordionSummary = withStyles((theme) => ({
  root: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    borderBottom: "1px solid rgba(0, 0, 0, .125)",
    marginBottom: -1,
    padding: "0px 5px",
    minHeight: 30,
    "&$expanded": {
      minHeight: 30,
    },
  },
  content: {
    margin: "8px 0",
    "&$expanded": {
      margin: "8px 0",
    },
  },
  expanded: {},
}))(AccordionSummary);

const MuiAccordionDetails = withStyles(() => ({
  root: {
    padding: "0px",
    // padding: theme.spacing(2),
  },
}))(AccordionDetails);

const StyledDialogContent = styled(DialogContent)`
  padding: 0px;
`;
const StyledDialogTitle = styled(DialogTitle)`
  color: ${(props) => props.theme.palette.primary.contrastText};
  background-color: ${(props) => props.theme.palette.primary.main};
  padding: 5px 10px;
`;
const StyledButton = styled(Button)`
  color: ${(props) => props.theme.palette.primary.contrastText};
  background-color: ${(props) => props.theme.palette.primary.main};
`;

const StyledAnpiWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 2px;
  background: ${(props) => (props.bigfont ? props.theme.palette.primary[100] : "#f7f8fc")};
  cursor: pointer;
  -webkit-transition: width 0.3s;
  transition: width 0.3s;
  width: 295px;
  height: 38px;
  position: relative;
  z-index: 0;
  &:hover {
    background-color: ${(props) => props.theme.palette.primary[100]};
    & .chat-name {
      font-weight: 700;
    }
    & .chat-image {
      border: 1px solid white;
    }
  }
  & .chat-name {
    //color: ${(props) => (props.bigfont ? props.theme.palette.primary.contrastText : "black")};
    font-weight: ${(props) => (props.bigfont ? "700" : "normal")};
  }
`;

const StyledCircularProgress = styled(CircularProgress)`
  color: ${(props) => props.theme.palette.primary[100]};
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -12;
  margin-left: -12;
`;

function ShowPlusQuestionList({ open, handleClose }) {
  const TOPCOLORS = [blue[300], pink[300]];
  const COLORS = [
    indigo[300],
    green[300],
    teal[300],
    blue[300],
    deepPurple[300],
    pink[300],
    red[300],
    purple[300],
    deepOrange[300],
    orange[300],
    // yellow[300],
    // brown[300],
    // lightGreen[300],
    // cyan[300],
    // lightBlue[300],
    // amber[300],
    // lime[300],
    // blueGrey[300],
    // grey[300],
  ];
  const { history } = useReactRouter();
  const { state } = useContext(Store);
  const [votesumarry, setVoteSumarry] = useState([]);
  const [textsumarry, setTextSumarry] = useState([]);
  const [textgroup, setTextGroup] = useState([]);

  const [selectedAnpiid, setSelectedAnpiid] = useState(null);
  const [selectedIsTemp, setSelectedIsTemp] = useState(false);
  const [localList, setLocalList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [snackopen, setSnackOpen] = useState(false);
  const [snackpushopen, setSnackPushOpen] = useState(false);
  const [openQuestionVoteObj, setOpenQuestionVoteObj] = React.useState({ honbuid: 0, isopen: false, questionid: 0 });

  const handleQuestionVoteClose = () => {
    setOpenQuestionVoteObj({ ...openQuestionVoteObj, isopen: false });
  };

  const mainproc = async () => {
    let honbuid = localStorage.getItem("honbuid");
    if (open) {
      setSelectedAnpiid(null);

      const result = await custom_axios(API_URL + `/GetNewQueList?honbuid=${honbuid}&roomid=${state.currentRoomid}`);
      const status = result.status;

      if (status === 200) {
        setLocalList(result.data.anpilist);
      } else if (status === 401) {
        history.replace("/loginError");
      } else {
        setLocalList([]);
      }
    }
  };

  useEffect(() => {
    mainproc();
  }, [open]);

  const MemoQuestionVote = useMemo(() => {
    return (
      <ShowNewQueVote
        open={openQuestionVoteObj.isopen}
        honbuid={openQuestionVoteObj.honbuid}
        openAnpiId={openQuestionVoteObj.questionid}
        handleClose={handleQuestionVoteClose}
        afterProc={mainproc}
        messageid={null}
      />
    );
  }, [openQuestionVoteObj.isopen, openQuestionVoteObj.honbuid, openQuestionVoteObj.questionid]);

  /* 左メニューをクリックしたときの動作 */
  const handleClick = async (q, t, h) => {
    let honbuid = localStorage.getItem("honbuid");
    setSelectedIsTemp(t == "1" ? true : false);
    if (h != honbuid) {
      /* 自分の作ったアンケートじゃない場合 */
      setOpenQuestionVoteObj({ honbuid: honbuid, isopen: true, questionid: q });
    } else {
      /* 自分のアンケートの場合（一覧みたり、修正できる */
      setSelectedAnpiid(q);
    }
    //setSelectedAnpiid(q);
  };

  const subproc = async () => {
    setLoading(true);
    /* 1安否確認の画面更新 */
    var apistring = API_URL + `/GetAllQuestionChartMini?anpiid=${selectedAnpiid}`;

    const result = await custom_axios(apistring);
    const status = result.status;
    if (status === 200) {
      setVoteSumarry(result.data.votesumarry);
      setTextSumarry(result.data.textsumarry);
      var textgroup = result.data.textsumarry.reduce(function (result, current) {
        var element = result.find(function (p) {
          return p.seq === current.seq;
        });
        if (element) {
          element.count++;
        } else {
          result.push({
            seq: current.seq,
            title: current.title,
            count: 1,
          });
        }
        return result;
      }, []);
      setTextGroup(textgroup);
    }
    setLoading(false);
  };
  useEffect(() => {
    if (selectedAnpiid && selectedIsTemp == false) {
      subproc();
    }
  }, [state.addVoteCnt, selectedAnpiid]);
  const [openAnpiCreate, setOpenAnpiCreate] = useState(false);
  const handleAnpiCreateClose = () => {
    setOpenAnpiCreate(false);
  };

  const [openAnpiId, setOpenAnpiId] = useState(null);

  const [anchorEl, setAnchorEl] = useState(null);
  const settingClick = (event, e) => {
    setOpenAnpiId(e);
    setAnchorEl(event.currentTarget);
    event.stopPropagation();
  };
  const settingClose = () => {
    setAnchorEl(null);
  };

  const [openModal, setOpenModal] = useState(false);
  const [openEndModal, setOpenEndModal] = useState(false);

  const delAnpi = async (q) => {
    const result = await custom_axios(API_URL + `/delNewQue?anpiid=${q}`);
    const status = result.status;
    if (status === 401) {
      history.replace("/loginError");
    }
    if (status === 200) {
      //setSelectedAnpiid(null);
      mainproc();
      setOpenModal(false);
    }
  };
  const endAnpi = async (q) => {
    const result = await custom_axios(API_URL + `/endNewQue?anpiid=${q}`);
    const status = result.status;
    if (status === 401) {
      history.replace("/loginError");
    }
    if (status === 200) {
      //setSelectedAnpiid(null);
      mainproc();
      setOpenEndModal(false);
    }
  };
  const [expanded, setExpanded] = React.useState(true);
  const [endexpanded, setEndExpanded] = React.useState(true);
  const [editexpanded, setEditExpanded] = React.useState(true);

  const onPieMainClick = (e) => {
    console.log(e);
    setOpenViewObj({
      anpiid: selectedAnpiid,
      isopen: true,
      vote: e.voteno,
      seq: "",
      no: "",
      name: e.name,
    });
  };
  const onPieClick = (e) => {
    console.log(e);
    setOpenViewObj({
      anpiid: selectedAnpiid,
      isopen: true,
      vote: "",
      seq: e.seq,
      no: e.no,
      name: e.name,
    });
  };
  const [openViewObj, setOpenViewObj] = React.useState({
    anpiid: 0,
    isopen: false,
    vote: "",
    seq: "",
    no: "",
    name: "",
  });
  const handleAnpiUserViewClose = () => {
    setOpenViewObj({ ...openViewObj, isopen: false });
  };

  const MemoAnpiCreate = useMemo(() => {
    return (
      <ShowNewQueCreate
        open={openAnpiCreate}
        openAnpiId={openAnpiId}
        handleClose={handleAnpiCreateClose}
        afterProc={mainproc}
      />
    );
  }, [openAnpiCreate, openAnpiId]);
  const MemoAnpiUserView = useMemo(() => {
    return <ShowPlusQuestionUserView openViewObj={openViewObj} handleClose={handleAnpiUserViewClose} />;
  }, [openViewObj]);

  return (
    <Dialog fullScreen open={open} onClose={handleClose} fullWidth maxWidth={"xl"} TransitionComponent={Transition}>
      <StyledDialogTitle id="max-width-dialog-title">
        <div className="show-member-search">
          <div className="show-member-search">アンケート一覧</div>
          <div className="question-title-actions">
            <StyledButton
              onClick={() => {
                subproc();
              }}
              variant="outlined"
            >
              画面更新
            </StyledButton>
            <div style={{ width: "20px" }}></div>
            {/* {createOk && ( */}
            <StyledButton
              onClick={() => {
                setOpenAnpiId(null);
                setOpenAnpiCreate(true);
              }}
              variant="outlined"
            >
              アンケート作成
            </StyledButton>
            {/* )} */}
            <div style={{ width: "20px" }}></div>
            <StyledButton onClick={handleClose} variant="outlined">
              閉じる (Esc)
            </StyledButton>
          </div>
        </div>
      </StyledDialogTitle>

      <StyledDialogContent>
        {MemoAnpiCreate}
        {MemoQuestionVote}
        {MemoAnpiUserView}
        {/* {MemoAnpiChart} */}
        <div className="question-dialog-wraper">
          <div className="anpi-member-list">
            <MuiAccordion square expanded={expanded} onChange={() => setExpanded(!expanded)}>
              <MuiAccordionSummary aria-controls="panel2d-content" id="panel2d-header">
                <div className="ac-group-between">
                  <StyledAnpiRow>
                    <div>実施中</div>
                  </StyledAnpiRow>
                  <FaAngleDown></FaAngleDown>
                </div>
              </MuiAccordionSummary>

              {localList &&
                localList
                  .filter((f) => f.istemp != "1" && f.isend != "1")
                  .sort(function (a, b) {
                    if (a.timestring > b.timestring) {
                      return -1;
                    }
                    if (a.timestring < b.timestring) {
                      return 1;
                    }
                    return 0;
                  })
                  .map((e) => {
                    return (
                      <MuiAccordionDetails key={e.anpiid}>
                        <StyledAnpiWrapper
                          bigfont={selectedAnpiid === e.anpiid}
                          onClick={() => {
                            handleClick(e.anpiid, e.istemp, e.honbuid);
                          }}
                        >
                          <div className="anpi-member-content question-padding-wrap">
                            <div className="qustion-title-text-wrapper">
                              <Badge
                                color="secondary"
                                badgeContent={Number(e.unvotecnt)}
                                invisible={Number(e.unvotecnt) === 0}
                              >
                                <div className={`read-member-name-list`}>{e.title}</div>
                              </Badge>
                              <span style={{ fontSize: "80%", marginLeft: "5px" }}>{e.time}</span>
                            </div>

                            <div style={{ marginRight: "5px" }}>
                              <IconButton
                                aria-label="setting"
                                component="span"
                                onClick={(event) => settingClick(event, e)}
                              >
                                <SettingsIcon />
                              </IconButton>
                            </div>
                          </div>
                        </StyledAnpiWrapper>
                      </MuiAccordionDetails>
                    );
                  })}
            </MuiAccordion>

            <MuiAccordion square expanded={endexpanded} onChange={() => setEndExpanded(!endexpanded)}>
              <MuiAccordionSummary aria-controls="panel2d-content" id="panel2d-header">
                <div className="ac-group-between">
                  <StyledAnpiRow>
                    <div>終了</div>
                  </StyledAnpiRow>
                  <FaAngleDown></FaAngleDown>
                </div>
              </MuiAccordionSummary>

              {localList &&
                localList
                  .filter((f) => f.istemp != "1" && f.isend == "1")
                  .sort(function (a, b) {
                    if (a.timestring > b.timestring) {
                      return -1;
                    }
                    if (a.timestring < b.timestring) {
                      return 1;
                    }
                    return 0;
                  })
                  .map((e) => {
                    return (
                      <MuiAccordionDetails key={e.anpiid}>
                        <StyledAnpiWrapper
                          bigfont={selectedAnpiid === e.anpiid}
                          onClick={() => {
                            handleClick(e.anpiid, e.istemp, e.honbuid);
                          }}
                        >
                          <div className="anpi-member-content question-padding-wrap">
                            <div className="qustion-title-text-wrapper">
                              <Badge
                                color="secondary"
                                badgeContent={Number(e.unvotecnt)}
                                invisible={Number(e.unvotecnt) === 0}
                              >
                                <div className={`read-member-name-list`}>{e.title}</div>
                              </Badge>
                              <span style={{ fontSize: "80%", marginLeft: "5px" }}>{e.time}</span>
                            </div>

                            <div style={{ marginRight: "5px" }}>
                              <IconButton
                                aria-label="setting"
                                component="span"
                                onClick={(event) => settingClick(event, e)}
                              >
                                <SettingsIcon />
                              </IconButton>
                            </div>
                          </div>
                        </StyledAnpiWrapper>
                      </MuiAccordionDetails>
                    );
                  })}
            </MuiAccordion>

            <MuiAccordion square expanded={editexpanded} onChange={() => setEditExpanded(!editexpanded)}>
              <MuiAccordionSummary aria-controls="panel2d-content" id="panel2d-header">
                <div className="ac-group-between">
                  <StyledAnpiRow>
                    <div>一時保存中</div>
                  </StyledAnpiRow>
                  <FaAngleDown></FaAngleDown>
                </div>
              </MuiAccordionSummary>

              {localList &&
                localList
                  .filter((f) => f.istemp == "1")
                  .sort(function (a, b) {
                    if (a.timestring > b.timestring) {
                      return -1;
                    }
                    if (a.timestring < b.timestring) {
                      return 1;
                    }
                    return 0;
                  })
                  .map((e) => {
                    return (
                      <MuiAccordionDetails key={e.anpiid}>
                        <StyledAnpiWrapper
                          bigfont={selectedAnpiid === e.anpiid}
                          onClick={() => {
                            handleClick(e.anpiid, e.istemp, e.honbuid);
                          }}
                        >
                          <div className="anpi-member-content question-padding-wrap">
                            <div className="qustion-title-text-wrapper">
                              <Badge
                                color="secondary"
                                badgeContent={Number(e.unvotecnt)}
                                invisible={Number(e.unvotecnt) === 0}
                              >
                                <div className={`read-member-name-list`}>{e.title}</div>
                              </Badge>
                              <span style={{ fontSize: "80%", marginLeft: "5px" }}>{e.time}</span>
                            </div>

                            <div style={{ marginRight: "5px" }}>
                              <IconButton
                                aria-label="setting"
                                component="span"
                                onClick={(event) => settingClick(event, e)}
                              >
                                <SettingsIcon />
                              </IconButton>
                            </div>
                          </div>
                        </StyledAnpiWrapper>
                      </MuiAccordionDetails>
                    );
                  })}
            </MuiAccordion>

            <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={settingClose}>
              {openAnpiId && openAnpiId.istemp == "1" && (
                <MenuItem
                  onClick={() => {
                    settingClose();
                    setOpenAnpiCreate(true);
                  }}
                >
                  修正
                </MenuItem>
              )}
              {openAnpiId && openAnpiId.istemp == "0" && openAnpiId.isend == "0" && (
                <MenuItem
                  onClick={() => {
                    settingClose();
                    setOpenEndModal(true);
                  }}
                >
                  終了
                </MenuItem>
              )}
              {openAnpiId && (
                <MenuItem
                  onClick={() => {
                    settingClose();
                    setOpenModal(true);
                  }}
                >
                  削除
                </MenuItem>
              )}
            </Menu>
            {/* <ShowCommon
              open={openPushObj.isopen}
              title="プッシュ通知しますか？"
              handleClose={() => {
                handlePushClose();
              }}
              handleCallBack={() => handlePush()}
            ></ShowCommon> */}
            <ShowCommon
              open={openModal}
              title="削除しますか？"
              handleClose={() => {
                setOpenModal(false);
              }}
              handleCallBack={() => delAnpi(openAnpiId.anpiid)}
            ></ShowCommon>
            <ShowCommon
              open={openEndModal}
              title="終了しますか？"
              handleClose={() => {
                setOpenEndModal(false);
              }}
              handleCallBack={() => endAnpi(openAnpiId.anpiid)}
            ></ShowCommon>
          </div>

          {loading ? (
            <div>
              <StyledCircularProgress />
            </div>
          ) : selectedAnpiid ? (
            <StyledPieCenter>
              <StyledChartMain>
                <StyledCenter>
                  <TopDiv>
                    <Typography variant="h5">
                      {"報告状況 (" +
                        votesumarry.reduce(function (sum, element) {
                          return sum + element.cnt;
                        }, 0) +
                        "人)"}
                    </Typography>
                  </TopDiv>
                  <PieChart width={600} height={300}>
                    <Tooltip></Tooltip>
                    <Pie
                      data={votesumarry}
                      startAngle={-270}
                      isAnimationActive={false}
                      onClick={onPieMainClick}
                      dataKey="cnt"
                      nameKey="vote"
                      cx="50%"
                      cy="50%"
                      outerRadius={120}
                      label={({ vote, cnt }) => `${vote}:${cnt}人`}
                    >
                      {votesumarry.map((entry, index) => (
                        <Cell cursor="pointer" key={index} fill={entry.vote == "済" ? TOPCOLORS[0] : TOPCOLORS[1]} />
                      ))}
                    </Pie>
                  </PieChart>
                </StyledCenter>
                <StyledChartSub>
                  {textgroup.map((e) => {
                    var textfilter = textsumarry.filter((f) => e.seq == f.seq);
                    return (
                      <StyledCenter key={e.seq}>
                        <div>
                          {e.title +
                            " (" +
                            textfilter.reduce(function (sum, element) {
                              return sum + element.cnt;
                            }, 0) +
                            "件)"}
                        </div>
                        <PieChart width={300} height={200}>
                          <Tooltip></Tooltip>
                          <Pie
                            data={textfilter}
                            startAngle={-270}
                            isAnimationActive={false}
                            onClick={onPieClick}
                            dataKey="cnt"
                            nameKey="text"
                            cx="50%"
                            cy="50%"
                            outerRadius={50}
                            label={({ text, cnt }) => `${text}:${cnt}件`}
                          >
                            {textfilter.map((entry, index) => (
                              <Cell cursor="pointer" key={index} fill={COLORS[index % COLORS.length]} />
                            ))}
                          </Pie>
                        </PieChart>
                      </StyledCenter>
                    );
                  })}
                </StyledChartSub>
              </StyledChartMain>
            </StyledPieCenter>
          ) : (
            <div className="anpi-member-list-right"></div>
          )}
        </div>
        <Snackbar
          open={snackopen}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          autoHideDuration={1000}
          onClose={() => {
            setSnackOpen(false);
          }}
          message="保存しました。"
        ></Snackbar>
        <Snackbar
          open={snackpushopen}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          autoHideDuration={1000}
          onClose={() => {
            setSnackPushOpen(false);
          }}
          message="プッシュ通知しました。"
        ></Snackbar>
      </StyledDialogContent>
    </Dialog>
  );
}

export default ShowPlusQuestionList;
