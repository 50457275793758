/* eslint-disable react/prop-types */
import useReactRouter from "use-react-router";
import React, { useState, useEffect } from "react";
import "./Login.css";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import axios from "axios";
import { LOGIN_URL } from "../Common/Common";
import { API_URL, custom_axios } from "../Common/Common";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import styled from "styled-components";
const StyledSubmit = styled(Button)``;
const StyledDivPaper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
function LoginMail() {
  const { history } = useReactRouter();
  const honbuid = localStorage.getItem("honbuid"); //props.match.params;
  const [loginMessage, setLoginMessage] = useState("");
  const handleClick = async () => {
    localStorage.setItem("login", "true");
    let urlstr = LOGIN_URL + `/changeInitialized?honbuid=${honbuid}`;
    await axios(urlstr);
    history.replace("/");
  };
  const [checked, setChecked] = useState(false);

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  useEffect(() => {
    const initrun = async () => {
      const result = await custom_axios(API_URL + `/getLoginMessage?honbuid=${honbuid}&kbn=web`);
      const status = result.status;
      if (status === 401) {
        history.replace("/loginError");
      }
      if (status === 200) {
        setLoginMessage(result.data.message);
      }
    };
    initrun();
  }, []);

  return (
    <Container component="main" maxWidth="lg">
      <CssBaseline />
      <StyledDivPaper>
        <div>
          <div className="login-mail-div" dangerouslySetInnerHTML={{ __html: loginMessage }}></div>

          <div className="login-mail">
            <FormControlLabel
              control={<Checkbox checked={checked} onChange={handleChange} name="checkedB" color="primary" />}
              label="確認"
            />
            <div className="login-mail-p">
              <StyledSubmit
                type="submit"
                variant="contained"
                color="primary"
                onClick={handleClick}
                //className={classes.submit}
                disabled={checked ? false : true}
              >
                利用開始
              </StyledSubmit>
            </div>
          </div>
        </div>
      </StyledDivPaper>
    </Container>
  );
}
export default LoginMail;
