/* eslint-disable react/prop-types */
import React from "react";
import { API_URL, STAMP_URL } from "../Common/Common";
import "./MessageList.css";

function StampMessage({ conversation, isReplySource }) {
  const srcMessage = isReplySource ? conversation.replymessage : conversation.message;
  return (
    <img
      src={srcMessage.indexOf("png") != -1 ? STAMP_URL + `/${srcMessage}` : API_URL + `/GetStamp?stampid=${srcMessage}`}
      //width="120"
      height="120"
      alt=""
      className="chat-message-stamp"
      //style={isCurrent ? { border: "1px solid white" } : null}
    />
  );
}

export default StampMessage;
