/* eslint-disable react/prop-types */
import "./ShowUserInfo.css";
import React, { useEffect, useState, useRef } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import { API_URL, unescapeHTML, custom_axios_post } from "./Common";
import styled from "styled-components";
import ContentEditable from "react-contenteditable";

const StyledDiv = styled.div`
  //min-width: 300;
`;

const StyledDialogTitle = styled(DialogTitle)`
  color: ${(props) => props.theme.palette.primary.contrastText};
  background-color: ${(props) => props.theme.palette.primary.main};
  padding: 5px 10px;
`;
function ShowEditMessage({ open, handleClose, messageid, message }) {
  const myRef = useRef(null);
  const [inputtext, setInputtext] = useState({ html: "" });
  const [charSize, setCharSize] = useState(0);

  const sendServer = async () => {
    let sendstr = myRef.current.innerHTML.replace(/<br>/g, "\n").replace(/<(?:.|)*?>/gm, "");
    /* contentEditable=true をしてると、何故かhooksが取得できないのでローカルストレージから取得 */
    let sendRoomid = localStorage.getItem("currentRoomid");
    let honbuid = localStorage.getItem("honbuid");
    /* ペーストでの画像送信 */
    const sendPastedFiles = [];
    for (let i = 0; i < myRef.current.childNodes.length; i++) {
      if (myRef.current.childNodes[i].nodeName == "IMG") {
        //setSendfiles([...sendFiles,])
        let byteString = atob(myRef.current.childNodes[i].src.split(",")[1]);
        let mimeType = myRef.current.childNodes[i].src.split(";")[0].split(":")[1];
        let l = byteString.length;
        let content = new Uint8Array(l);
        for (let j = 0; l > j; j++) {
          content[j] = byteString.charCodeAt(j);
        }

        let blob = new File([content], "test", {
          type: mimeType,
        });
        sendPastedFiles.push(blob);
      }
    }

    /* テキスト送信 */
    if (sendstr.trim() != "") {
      const body = {
        messageid: messageid,
        honbuid: honbuid,
        roomid: sendRoomid,
        message: unescapeHTML(sendstr.substring(0, 1000).trim()),
      };
      console.log("post前", body);
      await custom_axios_post(API_URL + "/editMessage", body);
    }
    setInputtext({ html: "" });
    setCharSize(0);
    handleClose();
  };
  useEffect(() => {
    const proc = async () => {
      if (open) {
        setCharSize(message.length);
        setInputtext({ html: message.replace(/\r?\n/g, "<br>") });
      }
    };
    proc();
  }, [open, message]);

  const handleKeyDown = (e) => {
    let checked = localStorage.getItem("checkedEnter") == "1" ? true : false;
    const sendMes = () => {
      e.preventDefault();
      sendServer();
    };
    // Enter押下
    if (e.which === 13) {
      // Ctrl
      if (e.ctrlKey && !e.shiftKey) {
        sendMes();
        // Shift
      } else if (!e.ctrlKey && e.shiftKey) {
        // 改行
        return false;
        // Ctrl, Shiftなし
      } else {
        // 「Enterで送信」チェックの有無
        if (checked) {
          sendMes();
        } else {
          // 改行
          return false;
        }
      }
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth={"sm"} fullWidth>
      <StyledDialogTitle id="max-width-dialog-title">
        <div className="show-member-search">修正内容</div>
      </StyledDialogTitle>

      <DialogContent>
        <StyledDiv>
          <ContentEditable
            translate="no"
            onPaste={(e) => {
              let imgs = [];
              for (var i = 0; i < e.clipboardData.items.length; i++) {
                // Skip content if not image
                if (e.clipboardData.items[i].type.indexOf("image") == -1) continue;
                // Retrieve image on clipboard as blob
                var blob = e.clipboardData.items[i].getAsFile();
                imgs.push(blob);
              }
              if (imgs.length > 0) {
                /* 画像データ貼り付け時 */
                //console.log("inputjs past元", blob);
                //document.execCommand("insertHTML", false, e.clipboardData);
              } else {
                /* テキスト貼り付け時 */
                e.preventDefault();
                var text = e.clipboardData.getData("text/plain");
                //console.log("inputjs past加工", text);
                document.execCommand("insertHTML", false, text.replace(/\r?\n/g, "<br>"));
              }
            }}
            className={"editablediv"}
            innerRef={myRef}
            html={inputtext.html}
            disabled={false}
            onChange={(e) => {
              let sendstr = myRef.current.innerHTML.replace(/<br>/g, "\n").replace(/<(?:.|)*?>/gm, "");
              //console.log("文字の中身", myRef.current.innerHTML, sendstr);

              setCharSize(sendstr.length);
              setInputtext({ html: e.target.value });
            }} // handle innerHTML change
            onKeyDown={(e) => handleKeyDown(e)}
          />
          <div className="sending-area-buttons">(文字:{charSize} / 1000)</div>
        </StyledDiv>
      </DialogContent>

      <DialogActions>
        <Button onClick={sendServer} variant="outlined" color="primary">
          修正
        </Button>
        <Button onClick={handleClose} variant="outlined" color="secondary">
          キャンセル
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ShowEditMessage;
