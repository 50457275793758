/* eslint-disable react/prop-types */
import React, { useEffect } from "react";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Checkbox from "@material-ui/core/Checkbox";
import ListItemText from "@material-ui/core/ListItemText";
import Input from "@material-ui/core/Input";
import { USER_SELECT_OPTION } from "../Common/Common";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function ShowUserSelect({ initvalue, callBack, honbuid }) {
  const [userOption, setUserOption] = React.useState([]);

  function handleChange(event) {
    setUserOption(event.target.value);
    let issalary = 0;
    let isphr = 0;
    let islogin = 0;
    let isvip = 0;
    let isanpi = 0;
    let isnipo = 0;
    if (event.target.value.indexOf(USER_SELECT_OPTION[0]) > -1) {
      issalary = 1;
    }
    if (event.target.value.indexOf(USER_SELECT_OPTION[1]) > -1) {
      isphr = 1;
    }
    if (event.target.value.indexOf(USER_SELECT_OPTION[2]) > -1) {
      islogin = 1;
    }
    if (event.target.value.indexOf(USER_SELECT_OPTION[3]) > -1) {
      isvip = 1;
    }
    if (event.target.value.indexOf(USER_SELECT_OPTION[4]) > -1) {
      isanpi = 1;
    }
    if (event.target.value.indexOf(USER_SELECT_OPTION[5]) > -1) {
      isnipo = 1;
    }
    callBack(honbuid, issalary, isphr, islogin, isvip, isanpi, isnipo);
  }
  useEffect(() => {
    setUserOption(initvalue);
  }, [initvalue]);

  return (
    <Select
      labelId="demo-mutiple-checkbox-label"
      id="demo-mutiple-checkbox"
      multiple
      value={userOption}
      onChange={handleChange}
      input={<Input />}
      renderValue={(selected) => selected.join(", ")}
      MenuProps={MenuProps}
    >
      {USER_SELECT_OPTION.map((name) => (
        <MenuItem key={name} value={name}>
          <Checkbox checked={userOption.indexOf(name) > -1} />
          <ListItemText primary={name} />
        </MenuItem>
      ))}
    </Select>
  );
}

export default ShowUserSelect;
