/* eslint-disable react/prop-types */
import "./ShowUserInfo.css";
import Snackbar from "@material-ui/core/Snackbar";
import React, { useEffect, useState, useContext, useMemo } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Search from "@material-ui/icons/Search";
import Close from "@material-ui/icons/Close";
import { API_URL, STATIC_URL, custom_axios, custom_axios_post, USER_SELECT_OPTION } from "../Common/Common";
import useReactRouter from "use-react-router";
import { Store } from "../Store/store";
import { ADMIN_BUTTON_OPTION } from "../Common/Common";
import Chip from "@material-ui/core/Chip";
import ShowCommon from "../Common/ShowCommon";
import ShowCreateAccount from "../Common/ShowCreateAccount";
import ShowNewUser from "../Common/ShowNewUser";
import ShowAdminSelect from "../Common/ShowAdminSelect";
import ShowChangeName from "../Common/ShowChangeName";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import TablePagination from "@mui/material/TablePagination";
import Paper from "@material-ui/core/Paper";
import MultiSelect from "react-multi-select-component";
import Skeleton from "@material-ui/lab/Skeleton";
import Slide from "@material-ui/core/Slide";
import styled from "styled-components";
import ShowUserSelect from "./ShowUserSelect";
import ShowButtonSelect from "./ShowButtonSelect";
import ShowPrint from "./ShowPrint";
import Checkbox from "@material-ui/core/Checkbox";
import SettingsIcon from "@material-ui/icons/Settings";
import IconButton from "@material-ui/core/IconButton";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
const StyledMenuButton = styled(Button)`
  width: 100%;
`;
const StyledIconButton = styled(IconButton)`
  margin-left: 5px;
`;
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="right" ref={ref} {...props} />;
});
const StyledChip = styled(Chip)`
  margin-right: 5px;
  height: 15px;
  font-size: 8px;
`;
const headCells = [
  { id: "name", label: "名前" },
  { id: "honbuid", label: "本部ID" },
  { id: "hpname", label: "病院名" },
  // { id: "jobname", label: "職種名" },
  { id: "unitname", label: "部署名" },
  { id: "opt", label: "機種" },
  { id: "time", label: "ログイン時刻" },
  { id: "lock", label: "ロック" },
  { id: "admin", label: "管理者ロール" },
  { id: "salary", label: "ユーザー権限" },
  { id: "edit", label: "設定" },
  //  { id: "time", label: "最終アクセス" },
  //{ id: "email", label: "Email" },
];
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}
function EnhancedTableHead(props) {
  const {
    order,
    orderBy,
    onRequestSort,
    selectAllClick,
    setSelectAllClick,
    selectAllClickCallback,
    indeterminate,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {/* <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all desserts" }}
          />
        </TableCell> */}
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            checked={selectAllClick}
            indeterminate={indeterminate}
            onChange={() => {
              setSelectAllClick(!selectAllClick);
              selectAllClickCallback(!selectAllClick);
            }}
            inputProps={{ "aria-label": "select all desserts" }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align="left"
            padding="normal"
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <StyledSpan>{order === "desc" ? "sorted descending" : "sorted ascending"}</StyledSpan>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

function LoadingTableHead() {
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell key={headCell.id} align="left" padding="normal">
            <TableSortLabel>{headCell.label}</TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
const StyledDialogTitle = styled(DialogTitle)`
  color: ${(props) => props.theme.palette.primary.contrastText};
  background-color: ${(props) => props.theme.palette.primary.main};
  padding: 5px 10px;
`;
const StyledButton = styled(Button)`
  color: ${(props) => props.theme.palette.primary.contrastText};
  background-color: ${(props) => props.theme.palette.primary.main};
  padding: 5px 10px;
`;
const StyledDialogContent = styled(DialogContent)`
  padding: 0px;
`;
const StyledRoot = styled.div`
  width: 100%;
`;
const StyledPaper = styled(Paper)`
  width: 100%;
  margin-bottom: ${(props) => props.theme.spacing(2) + "px"};
`;
const StyledTable = styled(Table)`
  min-width: 750;
`;

const StyledSpan = styled.span`
  border: 0;
  clip: rect(0 0 0 0);
  height: 1;
  margin: -1;
  overflow: hidden;
  padding: 0;
  position: absolute;
  top: 20;
  width: 1;
`;

function ShowUser({ open, handleClose }) {
  const { history } = useReactRouter();
  const { state } = useContext(Store);

  const [openFa, setOpenFa] = useState(false);
  const handleFaClickOpen = () => {
    setOpenFa(true);
  };
  const handleFaClose = () => {
    setOpenFa(false);
  };
  const handleNewUserClose = () => {
    setNewUser({ ...newuser, open: false });
  };
  const [passsnackopen, setPassSnackOpen] = useState(false);
  const [smssnackopen, setSmsSnackOpen] = useState(false);
  const [codesnackopen, setCodeSnackOpen] = useState(false);
  const [leavesnackopen, setLeaveSnackOpen] = useState(false);
  const [locksnackopen, setLockSnackOpen] = useState(false);
  const [snackopen, setSnackOpen] = useState(false);
  // const [snacksalaryopen, setSnackSalaryOpen] = useState(false);
  const [uppersnackopen, setUpperSnackOpen] = useState(false);
  const [membersList, setMembersList] = useState([]);
  const [originalmembersList, setOriginalMembersList] = useState([]);
  // const [joboptions, setJobOptions] = useState([]);
  // const [selectedjob, setSelectedJob] = useState([]);
  const [unitoptions, setUnitOptions] = useState([]);
  const [selectedunit, setSelectedUnit] = useState([]);
  const [options, setOptions] = useState([]);
  const [selectedhp, setSelectedHp] = useState([]);
  const [newuser, setNewUser] = useState({ open: false, id: "" });
  const [tempoptions] = useState([
    { label: "通常ユーザー", value: "0" },
    { label: "一時ユーザー", value: "1" },
  ]);
  const [lockoptions] = useState([
    { label: "ロック中", value: "1" },
    { label: "未ロック", value: "0" },
  ]);
  const [osoptions] = useState([
    { label: "iOS", value: "iOS" },
    { label: "Android", value: "Android" },
    { label: "なし", value: "not" },
  ]);
  const [selectedtemp, setSelectedTemp] = useState([]);
  const [selectedlock, setSelectedLock] = useState([]);
  const [selectedos, setSelectedOs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const proc = async (localhps, localunits, localtemps, locallocks, localoss) => {
    setLoading(true);
    setSelectAllClick(false);
    let joinhps = localhps
      .map((e) => {
        return e.value;
      })
      .join("_");

    let joinunits = localunits
      .map((e) => {
        return e.value;
      })
      .join("_");

    var tempkbn = "";
    var localtempsvalues = localtemps.map((e) => {
      return e.value;
    });
    if (localtempsvalues.includes("0") && localtempsvalues.includes("1")) {
      tempkbn = "2"; /* 通常＋一時 */
    } else if (localtempsvalues.includes("0")) {
      tempkbn = "0"; /* 通常 */
    } else {
      tempkbn = "1"; /* 一時 */
    }

    var lockkbn = "";
    var locallockvalues = locallocks.map((e) => {
      return e.value;
    });
    if (locallockvalues.includes("0") && locallockvalues.includes("1")) {
      lockkbn = "2"; /* 両方 */
    } else if (locallockvalues.includes("0")) {
      lockkbn = "0"; /* 未ロック */
    } else {
      lockkbn = "1"; /* ロック中 */
    }

    let joinoss = localoss
      .map((e) => {
        return e.value;
      })
      .join("_");

    if (
      joinhps.length == 0 ||
      joinunits.length == 0 ||
      localtemps.length == 0 ||
      locallocks.length == 0 ||
      localoss.length == 0
    ) {
      setMembersList([]);
      setOriginalMembersList([]);
      return;
    }
    let honbuid = localStorage.getItem("honbuid");
    const body = {
      honbuid: honbuid,
      hascode: "1",
      hpcodes: joinhps,
      unitcodes: joinunits,
      tempkbn: tempkbn,
      lockkbn: lockkbn,
      oss: joinoss,
    };
    //const result = await custom_axios_post(API_URL + "/GetDebugAdminUsers", body);
    const result = await custom_axios_post(API_URL + "/GetAdminUsers", body);
    const status = result.status;
    if (status === 401) {
      history.replace("/loginError");
    }
    if (status === 200) {
      setLoading(false);
      setOptions(
        result.data.hps.map((e) => {
          return { label: e.hpname, value: e.hpcode };
        })
      );
      // setJobOptions(
      //   result.data.jobs.map((e) => {
      //     return { label: e.jobname, value: e.jobcode };
      //   })
      // );
      setUnitOptions(
        result.data.units.map((e) => {
          return { label: e.unitname, value: e.unitcode };
        })
      );
      setOriginalMembersList(
        result.data.users.map((e) => {
          return { ischecked: false, ...e };
        })
      );
      if (searchText != "") {
        setMembersList(
          result.data.users
            .map((e) => {
              return { ischecked: false, ...e };
            })
            .filter(
              (f) =>
                String(f.name).toLowerCase().indexOf(searchText) !== -1 || String(f.honbuid).indexOf(searchText) !== -1
            )
        );
      } else {
        setMembersList(
          result.data.users.map((e) => {
            return { ischecked: false, ...e };
          })
        );
      }
    }
  };

  const afterSaveProc = async () => {
    setLoading(true);
    let joinhps = selectedhp
      .map((e) => {
        return e.value;
      })
      .join("_");

    // let joinjobs = selectedjob
    //   .map((e) => {
    //     return e.value;
    //   })
    //   .join("_");

    let joinunits = selectedunit
      .map((e) => {
        return e.value;
      })
      .join("_");

    var tempkbn = "";
    var localtempsvalues = selectedtemp.map((e) => {
      return e.value;
    });
    if (localtempsvalues.includes("0") && localtempsvalues.includes("1")) {
      tempkbn = "2"; /* 通常＋一時 */
    } else if (localtempsvalues.includes("0")) {
      tempkbn = "0"; /* 通常 */
    } else {
      tempkbn = "1"; /* 一時 */
    }

    var lockkbn = "";
    var locallockvalues = selectedlock.map((e) => {
      return e.value;
    });
    if (locallockvalues.includes("0") && locallockvalues.includes("1")) {
      lockkbn = "2"; /* 両方 */
    } else if (locallockvalues.includes("0")) {
      lockkbn = "0"; /* 未ロック */
    } else {
      lockkbn = "1"; /* ロック中 */
    }

    let joinoss = selectedos
      .map((e) => {
        return e.value;
      })
      .join("_");

    if (joinhps.length == 0 || joinunits.length == 0) {
      setOriginalMembersList([]);
      setMembersList([]);
      return;
    }
    let honbuid = localStorage.getItem("honbuid");
    const body = {
      honbuid: honbuid,
      hascode: "1",
      hpcodes: joinhps,
      unitcodes: joinunits,
      tempkbn: tempkbn,
      lockkbn: lockkbn,
      oss: joinoss,
    };
    const result = await custom_axios_post(API_URL + "/GetAdminUsers", body);
    const status = result.status;
    if (status === 401) {
      history.replace("/loginError");
    }
    if (status === 200) {
      setLoading(false);
      setOriginalMembersList(
        result.data.users.map((e) => {
          return { ischecked: false, ...e };
        })
      );
      if (searchText != "") {
        setMembersList(
          result.data.users
            .map((e) => {
              return { ischecked: false, ...e };
            })
            .filter(
              (f) =>
                String(f.name).toLowerCase().indexOf(searchText) !== -1 || String(f.honbuid).indexOf(searchText) !== -1
            )
        );
      } else {
        setMembersList(
          result.data.users.map((e) => {
            return { ischecked: false, ...e };
          })
        );
      }
    }
  };

  useEffect(() => {
    if (open) {
      setSelectedHp([{ label: state.hpcode + ":" + state.hpname, value: state.hpcode }]);
      setSelectedTemp([
        { label: "通常ユーザー", value: "0" },
        { label: "一時ユーザー", value: "1" },
      ]);
      setSelectedLock([
        { label: "ロック中", value: "1" },
        { label: "未ロック", value: "0" },
      ]);
      setSelectedUnit([{ label: state.unitcode + ":" + state.unitname, value: state.unitcode }]);
      setSelectedOs([
        { label: "iOS", value: "iOS" },
        { label: "Android", value: "Android" },
        { label: "なし", value: "not" },
      ]);
      proc(
        [{ label: state.hpname, value: state.hpcode }],
        [{ label: state.unitname, value: state.unitcode }],
        [
          { label: "通常ユーザー", value: "0" },
          { label: "一時ユーザー", value: "1" },
        ],
        [
          { label: "ロック中", value: "1" },
          { label: "未ロック", value: "0" },
        ],
        [
          { label: "iOS", value: "iOS" },
          { label: "Android", value: "Android" },
          { label: "なし", value: "not" },
        ]
      );
    }
  }, [open]);

  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const [openLockModal, setOpenLockModal] = useState(false);
  const [openLockAllModal, setOpenLockAllModal] = useState(false);
  const [openSmsModal, setOpenSmsModal] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openCodeModal, setOpenCodeModal] = useState(false);
  const [openPrintModal, setOpenPrintModal] = useState(false);
  const [openLeaveModal, setOpenLeaveModal] = useState(false);
  const [openNameModal, setOpenNameModal] = useState({ open: false, honbuid: "", name: "" });
  const [delid, setDelId] = useState(0);
  const handleLockModalClose = () => {
    setOpenLockModal(false);
  };
  const handleLockAllModalClose = () => {
    setOpenLockAllModal(false);
  };
  const handleSmsModalClose = () => {
    setOpenSmsModal(false);
  };
  const handleModalClose = () => {
    setOpenModal(false);
  };
  const handleCodeModalClose = () => {
    setOpenCodeModal(false);
  };
  const handlePrintModalClose = () => {
    setOpenPrintModal(false);
  };
  const handleLeaveModalClose = () => {
    setOpenLeaveModal(false);
  };
  const handleNameModalClose = () => {
    setOpenNameModal({ open: false, honbuid: "", name: "" });
  };

  const handleInitSms = async () => {
    let strurl = API_URL + `/initSms?honbuid=${delid}`;
    const result = await custom_axios(strurl);
    const status = result.status;
    setSmsSnackOpen(true);
    if (status === 401) {
      history.replace("/loginError");
    }
    if (status === 200) {
      setOriginalMembersList(
        membersList.map((item) => {
          if (item.honbuid === delid) {
            return { ...item, issalary: "0", isphr: "0" };
          }
          return item;
        })
      );
      setMembersList(
        membersList.map((item) => {
          if (item.honbuid === delid) {
            return { ...item, issalary: "0", isphr: "0" };
          }
          return item;
        })
      );
      setOpenSmsModal(false);
    }
  };

  const handleInitPass = async () => {
    let strurl = API_URL + `/initPasswordRand?honbuid=${delid}`;
    const result = await custom_axios(strurl);
    const status = result.status;
    setPassSnackOpen(true);
    if (status === 401) {
      history.replace("/loginError");
    }
    if (status === 200) {
      // setMembersList(
      //   membersList.map((item) => {
      //     if (item.honbuid === delid) {
      //       return { ...item, issalary: "0", isphr: "0" };
      //     }
      //     return item;
      //   })
      // );
      setOpenModal(false);
    }
  };
  const handleLeave = async () => {
    let strurl = API_URL + `/leaveuser?honbuid=${delid}`;
    const result = await custom_axios(strurl);
    const status = result.status;

    if (status === 401) {
      history.replace("/loginError");
    }
    if (status === 200) {
      await afterSaveProc();
      setLeaveSnackOpen(true);
      setOpenLeaveModal(false);
    }
  };
  const handleInitLockAll = async () => {
    setOpenLockAllModal(false);
    let strhonbuids = honbuids
      .map((e) => {
        return e.honbuid;
      })
      .join("_");

    const body = {
      honbuids: strhonbuids,
    };
    const result = await custom_axios_post(API_URL + "/initLockAll", body);
    const status = result.status;
    if (status === 401) {
      history.replace("/loginError");
    }
    if (status === 200) {
      //await afterSaveProc();
      var resultlist = [];

      for (let j = 0; j < membersList.length; j++) {
        var inserted = false;
        for (let i = 0; i < honbuids.length; i++) {
          if (honbuids[i].honbuid == membersList[j].honbuid) {
            resultlist.push({ ...membersList[j], islocked: "0" });
            inserted = true;
          }
        }
        if (inserted == false) {
          resultlist.push({ ...membersList[j] });
        }
      }
      setOriginalMembersList(resultlist);
      setMembersList(resultlist);
      setLockSnackOpen(true);
    }
  };
  const handleInitLock = async () => {
    let strurl = API_URL + `/initLock?honbuid=${delid}`;
    const result = await custom_axios(strurl);
    const status = result.status;
    setLockSnackOpen(true);
    if (status === 401) {
      history.replace("/loginError");
    }
    if (status === 200) {
      setOriginalMembersList(
        membersList.map((item) => {
          if (item.honbuid === delid) {
            return { ...item, islocked: "0" };
          }
          return item;
        })
      );
      setMembersList(
        membersList.map((item) => {
          if (item.honbuid === delid) {
            return { ...item, islocked: "0" };
          }
          return item;
        })
      );

      setOpenLockModal(false);
    }
  };
  const handleInitCode = async () => {
    let strurl = API_URL + `/initCode?honbuid=${delid}`;
    const result = await custom_axios(strurl);
    const status = result.status;
    setCodeSnackOpen(true);
    if (status === 401) {
      history.replace("/loginError");
    }
    if (status === 200) {
      setOpenCodeModal(false);
    }
  };
  const handleChangeAdmin = async (honbuid, value) => {
    const result = await custom_axios(API_URL + `/changeAdmin?honbuid=${honbuid}&value=${value}`);
    const status = result.status;
    setSnackOpen(true);
    if (status === 401) {
      history.replace("/loginError");
    }
  };

  const handleRunCode = async (honbuid) => {
    setDelId(honbuid);
    setOpenCodeModal(true);
  };
  const handleRunLock = async (honbuid) => {
    setDelId(honbuid);
    setOpenLockModal(true);
  };

  const handleRunSms = async (honbuid) => {
    setDelId(honbuid);
    setOpenSmsModal(true);
  };

  const handleRunLeave = async (honbuid) => {
    setDelId(honbuid);
    setOpenLeaveModal(true);
  };

  const handleRun = async (honbuid) => {
    setDelId(honbuid);
    setOpenModal(true);
  };
  const handleprintRun = async (honbuid, name, unitname, jobname) => {
    //setDelId(honbuid);
    setHonbuids([{ honbuid: honbuid, unitname: unitname, jobname: jobname, name: name }]);
    setOpenPrintModal(true);
  };

  const handleChangeUserOption = async (honbuid, issalary, isphr, islogin, isvip, isanpi, isnipo) => {
    const result = await custom_axios(
      API_URL +
        `/changeUserOption?honbuid=${honbuid}&issalary=${issalary}&isphr=${isphr}&islogin=${islogin}&isvip=${isvip}&isanpi=${isanpi}&isnipo=${isnipo}`
    );
    const status = result.status;

    if (status === 200) {
      setOriginalMembersList(
        membersList.map((item) => {
          if (item.honbuid === honbuid) {
            return {
              ...item,
              issalary: issalary,
              isphr: isphr,
              isloginok: islogin,
              isvip: isvip,
              isanpi: isanpi,
              isnipo: isnipo,
            };
          }
          return item;
        })
      );
      setMembersList(
        membersList.map((item) => {
          if (item.honbuid === honbuid) {
            return {
              ...item,
              issalary: issalary,
              isphr: isphr,
              isloginok: islogin,
              isvip: isvip,
              isanpi: isanpi,
              isnipo: isnipo,
            };
          }
          return item;
        })
      );
      setSnackOpen(true);
    } else if (status === 204) {
      // setSnackSalaryOpen(true);
    } else if (status === 401) {
      history.replace("/loginError");
    }
  };

  const calcInitvalue = (honbuid, issalary, isphr, isloginok, isvip, isanpi, isnipo) => {
    const initvalue = [];
    if (issalary == "1") {
      initvalue.push(USER_SELECT_OPTION[0]);
    }
    if (isphr == "1") {
      initvalue.push(USER_SELECT_OPTION[1]);
    }
    if (isloginok == "1") {
      initvalue.push(USER_SELECT_OPTION[2]);
    }
    if (isvip == "1") {
      initvalue.push(USER_SELECT_OPTION[3]);
    }
    if (isanpi == "1") {
      initvalue.push(USER_SELECT_OPTION[4]);
    }
    if (isnipo == "1") {
      initvalue.push(USER_SELECT_OPTION[5]);
    }
    return initvalue;
  };

  const selectAllClickCallback = (isclicked) => {
    if (isclicked) {
      setOriginalMembersList(
        membersList.map((item) => {
          return { ...item, ischecked: true };
        })
      );
      setMembersList(
        membersList.map((item) => {
          return { ...item, ischecked: true };
        })
      );
      setIndeterminate(false);
    } else {
      setOriginalMembersList(
        membersList.map((item) => {
          return { ...item, ischecked: false };
        })
      );
      setMembersList(
        membersList.map((item) => {
          return { ...item, ischecked: false };
        })
      );
      setIndeterminate(false);
    }
  };
  const onSelectClick = (honbuid) => {
    var localmeberList = membersList.map((item) => {
      if (item.honbuid === honbuid) {
        return { ...item, ischecked: !item.ischecked };
      }
      return item;
    });
    setOriginalMembersList(localmeberList);
    setMembersList(localmeberList);
    var cntfilter = localmeberList.filter((f) => f.ischecked == true);
    if (cntfilter.length == 0) {
      setIndeterminate(false);
      setSelectAllClick(false);
    } else if (cntfilter.length == localmeberList.length) {
      setIndeterminate(false);
      setSelectAllClick(true);
    } else {
      setIndeterminate(true);
      setSelectAllClick(true);
    }
  };
  const [honbuids, setHonbuids] = useState([]);

  const handleRunName = (honbuid, name) => {
    setOpenNameModal({ open: true, honbuid: honbuid, name: name });
  };
  const afterSaveNameProc = (honbuid, name) => {
    var localmeberList = membersList.map((item) => {
      if (item.honbuid === honbuid) {
        return { ...item, name: name };
      }
      return item;
    });
    setOriginalMembersList(localmeberList);
    setMembersList(localmeberList);
  };
  const MemoChangeName = useMemo(() => {
    return (
      <ShowChangeName
        open={openNameModal.open}
        handleClose={handleNameModalClose}
        afterSaveNameProc={afterSaveNameProc}
        honbuid={openNameModal.honbuid}
        username={openNameModal.name}
      ></ShowChangeName>
    );
  }, [openNameModal]);

  const MemoSMS = useMemo(() => {
    return (
      <ShowCommon
        open={openSmsModal}
        title="SMS認証を解除しますか？"
        handleClose={handleSmsModalClose}
        handleCallBack={handleInitSms}
      ></ShowCommon>
    );
  }, [openSmsModal]);
  const MemoInit = useMemo(() => {
    return (
      <ShowCommon
        open={openModal}
        title="初期化しますか？"
        handleClose={handleModalClose}
        handleCallBack={handleInitPass}
      ></ShowCommon>
    );
  }, [openModal]);
  const MemoLeave = useMemo(() => {
    return (
      <ShowCommon
        open={openLeaveModal}
        title="退職扱いにしますか？"
        handleClose={handleLeaveModalClose}
        handleCallBack={handleLeave}
      ></ShowCommon>
    );
  }, [openLeaveModal]);
  const MemoCode = useMemo(() => {
    return (
      <ShowCommon
        open={openCodeModal}
        title="引き換えコードを新しくしますか？"
        handleClose={handleCodeModalClose}
        handleCallBack={handleInitCode}
      ></ShowCommon>
    );
  }, [openCodeModal]);
  const MemoLock = useMemo(() => {
    return (
      <ShowCommon
        open={openLockModal}
        title="ロックを解除しますか？"
        handleClose={handleLockModalClose}
        handleCallBack={handleInitLock}
      ></ShowCommon>
    );
  }, [openLockModal]);
  const MemoLockAll = useMemo(() => {
    return (
      <ShowCommon
        open={openLockAllModal}
        title={honbuids.length.toString() + "ユーザーのロックを解除しますか？"}
        handleClose={handleLockAllModalClose}
        handleCallBack={handleInitLockAll}
      ></ShowCommon>
    );
  }, [openLockAllModal, honbuids]);
  const MemoCreateAccount = useMemo(() => {
    return (
      <ShowCreateAccount
        open={openFa}
        handleClose={handleFaClose}
        afterSaveProc={afterSaveProc}
        setNewUser={setNewUser}
        hpcode={state.hpcode}
        hpname={state.hpname}
      ></ShowCreateAccount>
    );
  }, [openFa]);
  const MemoNewUser = useMemo(() => {
    return <ShowNewUser open={newuser.open} handleClose={handleNewUserClose} id={newuser.honbuid}></ShowNewUser>;
  }, [newuser]);
  const MemoPrint = useMemo(() => {
    return <ShowPrint open={openPrintModal} handleClose={handlePrintModalClose} honbuids={honbuids}></ShowPrint>;
  }, [openPrintModal, honbuids]);

  const [selectAllClick, setSelectAllClick] = useState(false);
  const [indeterminate, setIndeterminate] = useState(false);
  const [stampAnchorEl, setStampAnchorEl] = useState(null);
  const handleSettingClick = (event) => {
    setStampAnchorEl(event.currentTarget);
  };
  const handleStampClose = () => {
    setStampAnchorEl(null);
  };
  const [searchText, setSearchText] = useState("");
  // useEffect(() => {
  //   if (searchText != "") {
  //     setMembersList(originalmembersList.filter((f) => String(f.name).toLowerCase().indexOf(searchText) !== -1));
  //   } else {
  //     setMembersList(originalmembersList);
  //   }
  // }, [searchText]);
  const handleChange = (e) => {
    setSearchText(e.target.value);
  };
  const handleKeyDown = (e) => {
    //e.preventDefault();
    if (e.which === 13 && !e.shiftKey) {
      e.preventDefault();
      if (searchText != "") {
        handleSearchClick();
      }
    }
  };
  const handleCloseClick = async () => {
    setSearchText("");
    setMembersList(originalmembersList);
  };
  const handleSearchClick = async () => {
    if (searchText != "") {
      setMembersList(
        originalmembersList.filter(
          (f) => String(f.name).toLowerCase().indexOf(searchText) !== -1 || String(f.honbuid).indexOf(searchText) !== -1
        )
      );
    } else {
      setMembersList(originalmembersList);
    }
  };

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleClose}
      fullWidth={true}
      maxWidth={"xl"}
      TransitionComponent={Transition}
    >
      <StyledDialogTitle id="max-width-dialog-title">
        <div className="show-member-search">
          <div className="show-member-search">ユーザー一覧</div>
          <div className="question-title-actions">
            <StyledButton
              onClick={() => {
                handleFaClickOpen();
              }}
              variant="outlined"
            >
              一時ユーザー作成
            </StyledButton>
            <div style={{ width: "20px" }}></div>
            <StyledButton onClick={handleClose} variant="outlined">
              閉じる (Esc)
            </StyledButton>
          </div>
        </div>
      </StyledDialogTitle>
      <StyledDialogContent>
        {MemoChangeName}
        {MemoSMS}
        {MemoInit}
        {MemoLeave}
        {MemoCode}
        {MemoLock}
        {MemoLockAll}
        {MemoCreateAccount}
        {MemoNewUser}
        {MemoPrint}
        <Snackbar
          open={uppersnackopen}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          autoHideDuration={6000}
          onClose={() => {
            setUpperSnackOpen(false);
          }}
          message="自分より上位の権限へは操作できません。"
        ></Snackbar>
        <Snackbar
          open={snackopen}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          autoHideDuration={6000}
          onClose={() => {
            setSnackOpen(false);
          }}
          message="ユーザー情報を変更しました。"
        ></Snackbar>
        {/* <Snackbar
          open={snacksalaryopen}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          autoHideDuration={6000}
          onClose={() => {
            setSnackSalaryOpen(false);
          }}
          message="パスワードが初期値のため変更できませんでした。"
        ></Snackbar> */}
        <Snackbar
          open={smssnackopen}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          autoHideDuration={6000}
          onClose={() => {
            setSmsSnackOpen(false);
          }}
          message="SMS認証を解除しました。"
        ></Snackbar>
        <Snackbar
          open={passsnackopen}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          autoHideDuration={10000}
          onClose={() => {
            setPassSnackOpen(false);
          }}
          message="初期化しました。人事情報が存在する場合は「誕生日8桁」に。人事情報が存在しない場合は「本部ID」に初期化しました。"
        ></Snackbar>
        <Snackbar
          open={codesnackopen}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          autoHideDuration={6000}
          onClose={() => {
            setCodeSnackOpen(false);
          }}
          message="引き換えコードを新しくしました。"
        ></Snackbar>
        <Snackbar
          open={locksnackopen}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          autoHideDuration={6000}
          onClose={() => {
            setLockSnackOpen(false);
          }}
          message="ロックを解除しました。"
        ></Snackbar>
        <Snackbar
          open={leavesnackopen}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          autoHideDuration={6000}
          onClose={() => {
            setLeaveSnackOpen(false);
          }}
          message="退職扱いにしました。"
        ></Snackbar>
        <StyledRoot>
          <div className="user-select-area">
            <div className="user-select-subarea">
              <div className="multi-select">
                <MultiSelect
                  options={options}
                  value={selectedhp}
                  onChange={(e) => {
                    setSelectedHp(e);
                    proc(e, selectedunit, selectedtemp, selectedlock, selectedos);
                  }}
                  labelledBy={"Select"}
                  overrideStrings={{
                    selectSomeItems: "選択...",
                    allItemsAreSelected: "全選択",
                    selectAll: "全選択",
                    search: "検索",
                    clearSearch: "検索クリア",
                  }}
                  filterOptions={(options, filter) => {
                    if (!filter) {
                      return options;
                    }
                    const re = new RegExp(filter, "i");
                    return options.filter(({ label }) => label && label.match(re));
                  }}
                />
              </div>
              <div className="multi-select">
                <MultiSelect
                  options={unitoptions}
                  value={selectedunit}
                  onChange={(e) => {
                    setSelectedUnit(e);
                    proc(selectedhp, e, selectedtemp, selectedlock, selectedos);
                  }}
                  labelledBy={"Select"}
                  overrideStrings={{
                    selectSomeItems: "選択...",
                    allItemsAreSelected: "全選択",
                    selectAll: "全選択",
                    search: "検索",
                    clearSearch: "検索クリア",
                  }}
                  filterOptions={(options, filter) => {
                    if (!filter) {
                      return options;
                    }
                    const re = new RegExp(filter, "i");
                    return options.filter(({ label }) => label && label.match(re));
                  }}
                />
              </div>
              <div className="multi-select-mini">
                <MultiSelect
                  options={tempoptions}
                  value={selectedtemp}
                  onChange={(e) => {
                    setSelectedTemp(e);
                    proc(selectedhp, selectedunit, e, selectedlock, selectedos);
                  }}
                  labelledBy={"Select"}
                  overrideStrings={{
                    selectSomeItems: "選択...",
                    allItemsAreSelected: "全選択",
                    selectAll: "全選択",
                    search: "検索",
                    clearSearch: "検索クリア",
                  }}
                  filterOptions={(options, filter) => {
                    if (!filter) {
                      return options;
                    }
                    const re = new RegExp(filter, "i");
                    return options.filter(({ label }) => label && label.match(re));
                  }}
                />
              </div>
              <div className="multi-select-mini">
                <MultiSelect
                  options={lockoptions}
                  value={selectedlock}
                  onChange={(e) => {
                    setSelectedLock(e);
                    proc(selectedhp, selectedunit, selectedtemp, e, selectedos);
                  }}
                  labelledBy={"Select"}
                  overrideStrings={{
                    selectSomeItems: "選択...",
                    allItemsAreSelected: "全選択",
                    selectAll: "全選択",
                    search: "検索",
                    clearSearch: "検索クリア",
                  }}
                  filterOptions={(options, filter) => {
                    if (!filter) {
                      return options;
                    }
                    const re = new RegExp(filter, "i");
                    return options.filter(({ label }) => label && label.match(re));
                  }}
                />
              </div>

              <div className="multi-select-mini">
                <MultiSelect
                  options={osoptions}
                  value={selectedos}
                  onChange={(e) => {
                    setSelectedOs(e);
                    proc(selectedhp, selectedunit, selectedtemp, selectedlock, e);
                  }}
                  labelledBy={"Select"}
                  overrideStrings={{
                    selectSomeItems: "選択...",
                    allItemsAreSelected: "全選択",
                    selectAll: "全選択",
                    search: "検索",
                    clearSearch: "検索クリア",
                  }}
                  filterOptions={(options, filter) => {
                    if (!filter) {
                      return options;
                    }
                    const re = new RegExp(filter, "i");
                    return options.filter(({ label }) => label && label.match(re));
                  }}
                />
              </div>
            </div>

            <div className="chat-search-admin">
              <div className="chat-search-icon">
                <IconButton color="primary" aria-label="send message" onClick={handleSearchClick}>
                  <Search />
                </IconButton>
              </div>
              <input
                placeholder="ユーザー検索"
                value={searchText}
                name="chatsFilter"
                onChange={handleChange}
                onKeyDown={handleKeyDown}
                className="chat-search-field"
              />
              <div className="chat-search-icon">
                <IconButton color="primary" aria-label="send message" onClick={handleCloseClick}>
                  <Close />
                </IconButton>
              </div>
            </div>
          </div>
          {selectAllClick ? (
            <div className="user-select-subarea">
              <StyledIconButton color="primary" aria-label="delete" onClick={handleSettingClick}>
                <SettingsIcon />
              </StyledIconButton>
              <Menu
                id="simple-menu"
                anchorEl={stampAnchorEl}
                keepMounted
                open={Boolean(stampAnchorEl)}
                onClose={handleStampClose}
              >
                {ADMIN_BUTTON_OPTION.map((name) => (
                  <MenuItem key={name} value={name}>
                    <StyledMenuButton
                      variant="contained"
                      key={name}
                      onClick={() => {
                        if (name == "QRコード印刷プレビュー") {
                          setHonbuids(
                            membersList
                              .filter((f) => f.ischecked)
                              .map((e) => {
                                return { honbuid: e.honbuid, unitname: e.unitname, jobname: e.jobname, name: e.name };
                              })
                          );
                          setStampAnchorEl(null);
                          setOpenPrintModal(true);
                        }
                        if (name == "ロック解除") {
                          setHonbuids(
                            membersList
                              .filter((f) => f.ischecked)
                              .map((e) => {
                                return { honbuid: e.honbuid, unitname: e.unitname, jobname: e.jobname, name: e.name };
                              })
                          );
                          setStampAnchorEl(null);
                          setOpenLockAllModal(true);
                        }
                        // if (name == "WEB給与") {
                        //   handleRun(honbuid);
                        //   setStampAnchorEl(null);
                        // }
                        // if (name == "PHR") {
                        //   handleRunSms(honbuid);
                        //   setStampAnchorEl(null);
                        // }
                      }}
                    >
                      {name}
                    </StyledMenuButton>
                  </MenuItem>
                ))}
              </Menu>
            </div>
          ) : (
            <div></div>
          )}
          {loading ? (
            <div>
              <StyledPaper>
                <TableContainer>
                  <StyledTable
                    //className={classes.table}
                    aria-labelledby="tableTitle"
                    //size={dense ? "small" : "medium"}
                    size="small"
                    aria-label="enhanced table"
                  >
                    <LoadingTableHead />
                    <TableBody>
                      {[...Array(15)].map((_, i) => {
                        const skeletonWidth = 60;
                        return (
                          <TableRow hover role="checkbox" tabIndex={-1} key={i}>
                            {/* <TableCell padding="checkbox">
                          <Checkbox checked={isItemSelected} inputProps={{ "aria-labelledby": labelId }} />
                        </TableCell> */}
                            <TableCell component="th">
                              <Skeleton variant="rect" width={skeletonWidth} />
                            </TableCell>
                            <TableCell component="th">
                              <Skeleton variant="rect" width={skeletonWidth} />
                            </TableCell>
                            <TableCell align="left">
                              <Skeleton variant="rect" width={skeletonWidth} />
                            </TableCell>
                            <TableCell align="left">
                              <Skeleton variant="rect" width={skeletonWidth} />
                            </TableCell>

                            <TableCell align="left">
                              <Skeleton variant="rect" width={skeletonWidth} />
                            </TableCell>

                            <TableCell align="left">
                              <Skeleton variant="rect" width={skeletonWidth} />
                            </TableCell>
                            <TableCell align="left">
                              <Skeleton variant="rect" width={skeletonWidth} />
                            </TableCell>
                            <TableCell align="left">
                              <Skeleton variant="rect" width={skeletonWidth} />
                            </TableCell>

                            <TableCell align="left">
                              <Skeleton variant="rect" width={skeletonWidth} />
                            </TableCell>
                            <TableCell align="left">
                              <Skeleton variant="rect" width={skeletonWidth} />
                            </TableCell>
                            <TableCell align="left">
                              <Skeleton variant="rect" width={skeletonWidth} />
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </StyledTable>
                </TableContainer>
              </StyledPaper>
            </div>
          ) : (
            <StyledPaper>
              <TableContainer>
                <StyledTable
                  //className={classes.table}
                  aria-labelledby="tableTitle"
                  //size={dense ? "small" : "medium"}
                  size="small"
                  aria-label="enhanced table"
                >
                  <EnhancedTableHead
                    //classes={classes}
                    //numSelected={selected.length}
                    order={order}
                    orderBy={orderBy}
                    selectAllClick={selectAllClick}
                    setSelectAllClick={setSelectAllClick}
                    selectAllClickCallback={selectAllClickCallback}
                    indeterminate={indeterminate}
                    setIndeterminate={setIndeterminate}
                    onRequestSort={handleRequestSort}
                    //rowCount={membersList.length}
                  />
                  <TableBody>
                    {stableSort(membersList, getComparator(order, orderBy))
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((row, index) => {
                        const labelId = `enhanced-table-checkbox-${index}`;

                        return (
                          <TableRow
                            hover
                            //onClick={(event) => handleClick(event, row.honbuid)}
                            role="checkbox"
                            tabIndex={-1}
                            key={row.honbuid}
                          >
                            {/* <TableCell padding="checkbox">
                          <Checkbox checked={isItemSelected} inputProps={{ "aria-labelledby": labelId }} />
                        </TableCell> */}

                            <TableCell padding="checkbox">
                              <Checkbox
                                color="primary"
                                checked={row.ischecked}
                                onChange={() => onSelectClick(row.honbuid)}
                              />
                            </TableCell>
                            <TableCell component="th" id={labelId}>
                              <div className="member-content">
                                <img
                                  src={
                                    STATIC_URL +
                                    `/downloadAvatar?honbuid=${row["honbuid"]}&fileno=${row["fileno"]}&size=small`
                                  }
                                  width="30"
                                  height="30"
                                  alt=""
                                  className="member-image"
                                  //style={isCurrent ? { border: "1px solid white" } : null}
                                />
                                {row.name}
                              </div>
                            </TableCell>
                            <TableCell align="left">{row.honbuid}</TableCell>
                            <TableCell align="left">{row.hpname}</TableCell>
                            {/* <TableCell align="left">{row.jobname}</TableCell> */}
                            <TableCell align="left">{row.unitname}</TableCell>

                            <TableCell align="left">
                              {row.isiod === "1" ? (
                                <StyledChip variant="outlined" color="primary" size="small" label="iOS" />
                              ) : null}
                              {row.isandroid === "1" ? (
                                <StyledChip variant="outlined" size="small" label="Android" />
                              ) : null}
                              {/* {row.isweb === "1" ? <StyledChip variant="outlined" size="small" label="Web" /> : null} */}
                            </TableCell>
                            <TableCell align="left">{row.time}</TableCell>
                            <TableCell align="left">{row.islocked === "1" ? "ロック" : null}</TableCell>
                            <TableCell align="left">
                              <ShowAdminSelect
                                ownvalue={state.isadmin}
                                initvalue={row.isadmin}
                                callBack={handleChangeAdmin}
                                honbuid={row.honbuid}
                                setUpperSnackOpen={setUpperSnackOpen}
                              />
                            </TableCell>

                            <TableCell align="left">
                              <ShowUserSelect
                                initvalue={calcInitvalue(
                                  row.honbuid,
                                  row.issalary,
                                  row.isphr,
                                  row.isloginok,
                                  row.isvip,
                                  row.isanpi,
                                  row.isnipo
                                )}
                                callBack={handleChangeUserOption}
                                honbuid={row.honbuid}
                              />
                            </TableCell>

                            <TableCell align="left">
                              <ShowButtonSelect
                                //callBack={handleChangeUserOption}
                                handleprintRun={handleprintRun}
                                handleRun={handleRun}
                                handleRunCode={handleRunCode}
                                handleRunSms={handleRunSms}
                                handleRunLeave={handleRunLeave}
                                handleRunLock={handleRunLock}
                                handleRunName={handleRunName}
                                honbuid={row.honbuid}
                                name={row.name}
                                unitname={row.unitname}
                                jobname={row.jobname}
                              ></ShowButtonSelect>

                              {/* <Button
                              variant="contained"
                              onClick={() => {
                                setDelId(row.honbuid);
                                setOpenModal(true);
                              }}
                            >
                              初期化
                            </Button> */}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </StyledTable>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10, 25, 50]}
                component="div"
                count={membersList.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </StyledPaper>
          )}
        </StyledRoot>
      </StyledDialogContent>
    </Dialog>
  );
}

export default ShowUser;
