/* eslint-disable react/prop-types */
import "./ShowUserInfo.css";
import { withStyles } from "@material-ui/core/styles";
import React, { useEffect, useState, useContext, useCallback } from "react";
import { Store } from "../Store/store";
import useReactRouter from "use-react-router";
import MuiDialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import { API_URL, custom_axios, custom_axios_post } from "../Common/Common";
import TextField from "@material-ui/core/TextField";

import SaveOutlinedIcon from "@material-ui/icons/SaveOutlined";
import Snackbar from "@material-ui/core/Snackbar";
import { useDropzone } from "react-dropzone";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import MultiSelect from "react-multi-select-component";
import styled from "styled-components";

const Dialog = withStyles(() => ({
  paperWidthSm: {
    maxWidth: "700px",
  },
}))(MuiDialog);

const StyledOpenHp = styled.div`
  display: flex;
  align-items: center;
  margin-left: 8px;
`;
const StyledDisplayflex = styled.div`
  display: flex;
  justify-content: center;
  align-items: start;
  flex-direction: column;
`;
const StyledDialogTitle = styled(DialogTitle)`
  color: ${(props) => props.theme.palette.primary.contrastText};
  background-color: ${(props) => props.theme.palette.primary.main};
  padding: 5px 10px;
`;
const StyledDialogContent = styled(DialogContent)`
  padding: 0px;
`;
function ShowCreateNews({ open, handleClose, afterSaveProc }) {
  const { history } = useReactRouter();
  const { state } = useContext(Store);
  const onDrop = useCallback((acceptedFiles) => {
    if (acceptedFiles.length > 0) {
      const src = URL.createObjectURL(acceptedFiles[0]);
      setFileUrl(src);
      setSendfiles(acceptedFiles[0]);
    }
  }, []);
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: onDrop,
    accept: "image/*",
  });

  //console.log("stateの値", state);
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");
  const [url, setUrl] = useState("");

  const [options, setOptions] = useState([]);
  const [selectedhp, setSelectedHp] = useState([]);

  const [snackopen, setSnackOpen] = useState(false);
  const [errorsnackopen, setErrorSnackOpen] = useState(false);

  const [sendfiles, setSendfiles] = useState(null);
  const [fileUrl, setFileUrl] = useState(null);

  const handleSave = async () => {
    let _selectedhp = selectedhp
      .map((e) => {
        return e.value;
      })
      .join("_");

    const params = new FormData();
    params.append("honbuid", localStorage.getItem("honbuid"));
    params.append("name", "");
    params.append("title", title);
    params.append("message", message);
    params.append("url", url);
    params.append("openhps", _selectedhp);
    params.append("file", sendfiles);

    // const body = {
    //   honbuid: localStorage.getItem("honbuid"),
    //   name: "",
    //   title: title,
    //   message: message,
    //   url: url,
    //   openhps: _selectedhp,
    //   files: sendfiles,
    // };
    const result = await custom_axios_post(API_URL + "/uploadTimelineone", params, {
      headers: {
        "content-type": "multipart/form-data",
      },
    });
    const status = result.status;

    setFileUrl(null);
    setSendfiles(null);

    if (status === 400) {
      //history.replace("/loginError");
      setErrorSnackOpen(true);
    }
    if (status === 401) {
      history.replace("/loginError");
    }
    if (result.status === 200) {
      setSnackOpen(true);
      afterSaveProc();
      handleClose();
    }
  };
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    //let ret = false;
    let okCnt = 0;
    if (title && title.length > 0) {
      okCnt++;
    }
    if (message && message.length > 0) {
      okCnt++;
    }
    if (selectedhp && selectedhp.length > 0) {
      okCnt++;
    }

    /* 必須入力箇所（3個）が入力できた場合は登録ボタンを押せるようにする */
    setIsVisible(okCnt == 3);
  }, [title, message, selectedhp]);

  useEffect(() => {
    //console.log("GetUser前", honbuid);
    const proc = async () => {
      let honbuid = localStorage.getItem("honbuid");
      //console.log("GetMultiSelect");
      if (open) {
        //Clear処理
        setTitle("");
        setMessage("");
        setUrl("");
        setFileUrl(null);
        setSendfiles(null);
        setSelectedHp([{ label: state.hpcode + ":" + state.hpname, value: state.hpcode }]);

        //const result = await custom_axios(API_URL + `/GetMultiSelect?honbuid=${honbuid}&hascode=1`);
        const result = await custom_axios(
          API_URL + `/GetAdminHpsUnits?honbuid=${honbuid}&hascode=1&hpcodes=${state.hpcode}`
        );
        const status = result.status;
        if (status === 401) {
          history.replace("/loginError");
        } else if (status === 200) {
          setOptions(
            result.data.hps.map((e) => {
              return { label: e.hpname, value: e.hpcode };
            })
          );
        }
      }
    };

    proc();
  }, [open]);

  return (
    <Dialog open={open} onClose={handleClose} maxWidth={"md"}>
      <StyledDialogTitle id="max-width-dialog-title">
        <div className="show-member-search">
          <div className="show-member-search">ニュース作成</div>
        </div>
      </StyledDialogTitle>

      <StyledDialogContent>
        <StyledDisplayflex>
          <TextField
            required
            error={title == ""}
            label="タイトル"
            id="outlined-size-normal"
            variant="outlined"
            onChange={(e) => {
              setTitle(e.target.value);
            }}
            style={{ margin: "8px", width: "300px" }}
          />
          <TextField
            required
            error={message == ""}
            label="メッセージ"
            id="outlined-size-normal"
            variant="outlined"
            multiline
            rows={4}
            onChange={(e) => {
              setMessage(e.target.value);
            }}
            style={{ margin: "8px", width: "300px" }}
          />
          <StyledOpenHp>
            公開施設
            <MultiSelect
              options={options}
              value={selectedhp}
              onChange={(e) => {
                //console.log("e.target.value", e.target.value);
                setSelectedHp(e);
              }}
              labelledBy={"Select"}
              overrideStrings={{
                selectSomeItems: "選択",
                allItemsAreSelected: "全選択",
                selectAll: "全選択",
                search: "検索",
                clearSearch: "検索クリア",
              }}
              filterOptions={(options, filter) => {
                if (!filter) {
                  return options;
                }
                const re = new RegExp(filter, "i");
                return options.filter(({ label }) => label && label.match(re));
              }}
            ></MultiSelect>
          </StyledOpenHp>
          {/* <TextField
            label="URL"
            id="outlined-size-normal"
            variant="outlined"
            onChange={(e) => {
              setUrl(e.target.value);
            }}
            style={{ margin: "8px", width: "300px" }}
          /> */}

          {fileUrl ? (
            <div className="drop-zone-image-div-wraper">
              <img src={fileUrl} style={{ objectFit: "contain", width: "300px", height: "200px", margin: "8px" }} />
            </div>
          ) : (
            <div {...getRootProps({ className: "drop-zone-div-news-wraper" })}>
              <input {...getInputProps()} />
              <CloudUploadIcon></CloudUploadIcon>
              <p>写真をドラッグアンドドロップ</p>
              <p>または、選択してください</p>
            </div>
          )}
        </StyledDisplayflex>
        <Snackbar
          open={errorsnackopen}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          autoHideDuration={6000}
          onClose={() => {
            setErrorSnackOpen(false);
          }}
          message="その本部IDは、既に登録済みです。"
        ></Snackbar>
        <Snackbar
          open={snackopen}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          autoHideDuration={6000}
          onClose={() => {
            setSnackOpen(false);
          }}
          message="登録しました"
        ></Snackbar>
      </StyledDialogContent>

      <DialogActions>
        <Button
          disabled={isVisible ? false : true}
          variant="contained"
          color="primary"
          onClick={handleSave}
          startIcon={<SaveOutlinedIcon />}
        >
          作成
        </Button>
        <Button onClick={handleClose} variant="outlined" color="secondary">
          閉じる (Esc)
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ShowCreateNews;
