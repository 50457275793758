/* eslint-disable react/prop-types */
import React from "react";
// import styled from "styled-components";
// import { API_URL, custom_axios } from "../Common/Common";
// import FormControlLabel from "@material-ui/core/FormControlLabel";
// import Checkbox from "@material-ui/core/Checkbox";
import Linkify from "react-linkify";
import styled from "styled-components";

const StyledTitle = styled.div`
  text-align: center;
  font-size: large;
  font-weight: bold;
`;

const StyledMessage = styled.div`
  margin-top: 10px;
`;

const StyledRight = styled.div`
  text-align: right;
`;

const StyledMain = styled.div`
  margin-top: 10px;
  border: 1px solid #000000;
  border-radius: 3px;
  padding: 10px;
  background: white;
`;

function ShowPopupDetail({ title, message, yyyymm }) {
  // const [ispopup, setIsPopup] = useState(false);

  // const changeBell = async () => {
  //   const honbuid = localStorage.getItem("honbuid");
  //   await custom_axios(API_URL + `/changeBellPopDetail?bellid=${bellid}&honbuid=${honbuid}`);

  // };

  return (
    <StyledMain>
      <StyledTitle>{title}</StyledTitle>
      <StyledRight>{yyyymm}</StyledRight>
      <StyledMessage>
        {message.split(/(\n)/).map((item, index) => {
          return (
            <div key={index}>
              <Linkify
                componentDecorator={(decoratedHref, decoratedText, key) => (
                  <a target={`blank${index}`} href={decoratedHref} key={key}>
                    {decoratedText}
                  </a>
                )}
              >
                {item}
              </Linkify>
            </div>
          );
        })}
      </StyledMessage>
      {/* <StyledRight>
        <FormControlLabel
          control={
            <Checkbox
              checked={ispopup}
              onChange={(e) => {
                setIsPopup(e.target.checked);
                changeBell();
              }}
              name="checkedB"
              color="primary"
            />
          }
          label="次回から表示しない"
        />
      </StyledRight> */}
    </StyledMain>
  );
}

export default ShowPopupDetail;
