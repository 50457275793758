/* eslint-disable react/prop-types */
import "./ShowUserInfo.css";
import React, { useEffect, useState, useRef } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import { API_URL, custom_axios } from "../Common/Common";
import useReactRouter from "use-react-router";
import { trackPromise } from "react-promise-tracker";
import { usePromiseTracker } from "react-promise-tracker";
import Loader from "react-loader-spinner";
import ReplyMessageOnDialog from "../MessageList/ReplyMessageOnDialog";
import styled from "styled-components";
const StyledDialogTitle = styled(DialogTitle)`
  color: ${(props) => props.theme.palette.primary.contrastText};
  background-color: ${(props) => props.theme.palette.primary.main};
`;
function ShowReply({
  open,
  handleClose,
  roomid,
  replyid,
  setInitMessageid,
  setImgOpen,
  setVideoOpen,
  setOpenReplyid,
  setOpenReply,
}) {
  const { history } = useReactRouter();
  const replyRef = useRef(null);
  const [localList, setLocalList] = useState([]);
  useEffect(() => {
    setLocalList([]);
  }, []);
  useEffect(() => {
    let honbuid = localStorage.getItem("honbuid");
    const proc = async () => {
      if (open) {
        setLocalList([]);
        trackPromise(
          custom_axios(
            API_URL + `/GetReplyConversationList?honbuid=${honbuid}&roomid=${roomid}&replyid=${replyid}`
          ).then((red) => {
            const status = red.status;
            if (status === 401) {
              history.replace("/loginError");
            }
            if (status === 200) {
              setLocalList(red.data.conversations);
            }
          })
        );
      }
    };
    proc();
  }, [replyid]);

  const { promiseInProgress } = usePromiseTracker();

  return (
    <Dialog open={open} onClose={handleClose} maxWidth={"sm"}>
      <StyledDialogTitle id="max-width-dialog-title">
        <div className="show-member-search">返信履歴</div>
      </StyledDialogTitle>

      <DialogContent>
        {promiseInProgress ? (
          <div
            style={{
              width: "100%",
              height: "100",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Loader type="ThreeDots" color="#2BAD60" height="100" width="100" />
          </div>
        ) : (
          <div className="reply-list" ref={replyRef}>
            {localList &&
              localList
                .sort(function (a, b) {
                  if (Number(a.messageid) > Number(b.messageid)) {
                    return 1;
                  }
                  if (Number(a.messageid) < Number(b.messageid)) {
                    return -1;
                  }
                  return 0;
                })
                .map((e) => {
                  return (
                    <ReplyMessageOnDialog
                      key={e.messageid}
                      conversation={e}
                      setInitMessageid={setInitMessageid}
                      setImgOpen={setImgOpen}
                      setVideoOpen={setVideoOpen}
                      setOpenReply={setOpenReply}
                      setOpenReplyid={setOpenReplyid}
                    ></ReplyMessageOnDialog>
                  );
                })}
          </div>
        )}
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose} variant="outlined" color="secondary">
          閉じる (Esc)
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ShowReply;
