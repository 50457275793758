/* eslint-disable react/prop-types */
import React from "react";
import "./Bell.css";
//import { SALARY_URL, custom_axios, API_URL } from "../Common/Common";
import Paper from "@material-ui/core/Paper";
import Chip from "@material-ui/core/Chip";
import Skeleton from "@material-ui/lab/Skeleton";
//import Icon from "@material-ui/core";
import styled from "styled-components";
import Linkify from "react-linkify";

const StyledNoticeDiv = styled.div`
  display: flex;
  flex-direction: column;
  justif-content: center;
`;
const StyledNoticeDivSub = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledPaper = styled(Paper)`
  cursor: pointer;
  margin: 2px;
  padding: 5px;
  &:hover {
    background-color: #f0f0f0;
  }
`;

const StyledChip = styled(Chip)`
  width: 100px;
`;

function Bell({ kbn, message, link, time, isskeleton }) {
  return (
    <StyledPaper
      onClick={async () => {
        if (kbn == "給与" || kbn == "賞与" || kbn == "源泉") {
          // let honbuid = localStorage.getItem("honbuid");
          // var result = await custom_axios(API_URL + `/getTempToken?honbuid=${honbuid}`);
          // const status = result.status;
          // if (status === 200) {
          //   window.open(SALARY_URL + `/Chatis/Login?id=${honbuid}&token=${result.data.token}`, "_blank");
          // }
        } else {
          if (link != "") {
            window.open(link, "_blank");
          }
        }
      }}
    >
      <StyledNoticeDiv>
        <StyledNoticeDivSub>
          {isskeleton ? (
            <Skeleton animation="wave" height={30} width="10%" />
          ) : kbn == "お知らせ" ? (
            <StyledChip variant="outlined" color="secondary" size="small" label={kbn}></StyledChip>
          ) : (
            <StyledChip variant="outlined" color="primary" size="small" label={kbn}></StyledChip>
          )}
          {isskeleton ? <Skeleton animation="wave" height={10} width="80%" /> : <div>{time}</div>}
        </StyledNoticeDivSub>
        {isskeleton ? (
          <Skeleton animation="wave" height={20} width="80%" />
        ) : (
          // eslint-disable-next-line react/prop-types
          message.split(/(\n)/).map((item, index) => {
            return (
              <div key={index}>
                <Linkify
                  componentDecorator={(decoratedHref, decoratedText, key) => (
                    <a target={`blank${index}`} href={decoratedHref} key={key}>
                      {decoratedText}
                    </a>
                  )}
                >
                  {item}
                </Linkify>
              </div>
            );
          })
        )}
      </StyledNoticeDiv>
    </StyledPaper>
  );
}

export default Bell;
