/* eslint-disable react/prop-types */
import "./ShowUserInfo.css";
import React, { useEffect, useState, useContext, useMemo } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { API_URL, STATIC_URL, custom_axios, custom_axios_post } from "../Common/Common";
import useReactRouter from "use-react-router";
import { Store } from "../Store/store";
import Search from "@material-ui/icons/Search";
import Close from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import ShowCommon from "../Common/ShowCommon";
import ShowMember from "../Common/ShowMember";
import ShowRoomMember from "../Common/ShowRoomMember";
import ShowDirectionSelect from "../Common/ShowDirectionSelect";
import Link from "@material-ui/core/Link";
import ShowRoomSwitch from "./ShowRoomSwitch";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Paper from "@material-ui/core/Paper";
import MultiSelect from "react-multi-select-component";
import Chip from "@material-ui/core/Chip";
import Skeleton from "@material-ui/lab/Skeleton";
import Slide from "@material-ui/core/Slide";
import styled from "styled-components";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="right" ref={ref} {...props} />;
});

const headCells = [
  { id: "roomid", label: "部屋ID" },
  { id: "roomname", label: "部屋名" },
  { id: "ninzu", label: "メンバー" },
  // { id: "messagecnt", label: "メッセージ数" },
  { id: "yyyymmdd", label: "最終メッセージ日" },
  { id: "direction", label: "方向" },
  { id: "enable", label: "有効" },
];
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}
function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align="left"
            padding="normal"
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <StyledSpan>{order === "desc" ? "sorted descending" : "sorted ascending"}</StyledSpan>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

function LoadingTableHead() {
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell key={headCell.id} align="left" padding="normal">
            <TableSortLabel>{headCell.label}</TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
const StyledDialogTitle = styled(DialogTitle)`
  color: ${(props) => props.theme.palette.primary.contrastText};
  background-color: ${(props) => props.theme.palette.primary.main};
  padding: 5px 10px;
`;
const StyledButton = styled(Button)`
  color: ${(props) => props.theme.palette.primary.contrastText};
  background-color: ${(props) => props.theme.palette.primary.main};
  padding: 5px 10px;
`;
const StyledDialogContent = styled(DialogContent)`
  padding: 0px;
`;
const StyledRoot = styled.div`
  width: 100%;
`;
const StyledPaper = styled(Paper)`
  width: 100%;
  margin-bottom: ${(props) => props.theme.spacing(2) + "px"};
`;
const StyledChip = styled(Chip)`
  margin-right: 5px;
  height: 15px;
  font-size: 8px;
`;
const StyledTable = styled(Table)`
  min-width: 750;
`;
const StyledFlex = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
`;
const StyledSpan = styled.span`
  border: 0;
  clip: rect(0 0 0 0);
  height: 1;
  margin: -1;
  overflow: hidden;
  padding: 0;
  position: absolute;
  top: 20;
  width: 1;
`;

function ShowRoom({ open, handleClose }) {
  const { history } = useReactRouter();
  const { state, hubConnection } = useContext(Store);

  const [openFa, setOpenFa] = useState(false);
  const handleFaClickOpen = () => {
    setOpenFa(true);
  };
  const handleFaClose = () => {
    setOpenFa(false);
  };

  const [openFaAdd, setOpenFaAdd] = useState(false);
  const handleFaAddClose = () => {
    setOpenFaAdd(false);
  };

  const [roomsList, setRoomsList] = useState([]);

  const [options, setOptions] = useState([]);
  const [selectedhp, setSelectedHp] = useState([]);
  const [kbnOptions] = useState([
    { label: "人事連携", value: "1" },
    { label: "グループ", value: "2" },
  ]);
  const [selectedKbn, setSelectedKbn] = useState([{ label: "人事連携", value: "1" }]);
  //const [memberOptions, setMemberOptions] = useState([]);
  //const [selectedMember, setSelectedMember] = useState([]);

  const [loading, setLoading] = useState(false);
  const initproc = async () => {
    setLoading(true);

    //console.log("selected procの中身", joinhps);
    let honbuid = localStorage.getItem("honbuid");

    const body = {
      honbuid: honbuid,
    };
    //console.log("test", body);
    const result = await custom_axios_post(API_URL + "/GetInitRooms", body);
    const status = result.status;
    if (status === 401) {
      history.replace("/loginError");
    }
    if (status === 200) {
      setLoading(false);
      setOptions(
        result.data.hps.map((e) => {
          return { label: e.hpname, value: e.hpcode };
        })
      );
      setSelectedHp([{ label: state.hpcode + ":" + state.hpname, value: state.hpcode }]);
      setSelectedKbn([{ label: "人事連携", value: "1" }]);
      // setMemberOptions(
      //   result.data.members.map((e) => {
      //     return { label: e.name, value: e.honbuid };
      //   })
      // );

      // setSelectedMember(
      //   result.data.members.map((e) => {
      //     return { label: e.name, value: e.honbuid };
      //   })
      // );
      setRoomsList(result.data.rooms);
    }
  };

  const proc = async (localhps, localkbn, ishpschanged, searchText) => {
    setLoading(true);
    let joinhps = localhps
      .map((e) => {
        return e.value;
      })
      .join("_");

    let joinkbn = localkbn
      .map((e) => {
        return e.value;
      })
      .join("_");

    // let joinmember = localmember
    //   .map((e) => {
    //     return e.value;
    //   })
    //   .join("_");

    if (joinhps.length == 0 || joinkbn.length == 0) {
      setRoomsList([]);
      return;
    }
    //console.log("selected procの中身", joinhps);
    let honbuid = localStorage.getItem("honbuid");

    const body = {
      honbuid: honbuid,
      hpcodes: joinhps,
      kbn: joinkbn,
      searchText: searchText,
      //member: joinmember,
    };
    //console.log("test", body);
    const result = await custom_axios_post(API_URL + "/GetRooms", body);
    const status = result.status;
    if (status === 401) {
      history.replace("/loginError");
    }
    if (status === 200) {
      setLoading(false);
      if (ishpschanged) {
        // setMemberOptions(
        //   result.data.members.map((e) => {
        //     return { label: e.name, value: e.honbuid };
        //   })
        // );
        // setSelectedMember(
        //   result.data.members.map((e) => {
        //     return { label: e.name, value: e.honbuid };
        //   })
        // );
      }
      setRoomsList(result.data.rooms);
    }
  };
  const afterAddMemberProc = () => {
    setAddMember(addmember + 1);
    proc(selectedhp, selectedKbn, false, searchText);
  };
  const afterSaveProc = async () => {
    setLoading(true);
    let joinhps = selectedhp
      .map((e) => {
        return e.value;
      })
      .join("_");

    let joinkbn = selectedKbn
      .map((e) => {
        return e.value;
      })
      .join("_");

    // let joinmember = selectedMember
    //   .map((e) => {
    //     return e.value;
    //   })
    //   .join("_");

    if (joinhps.length == 0 || joinkbn.length == 0) {
      setRoomsList([]);
      return;
    }
    //console.log("selected procの中身", joinhps);
    let honbuid = localStorage.getItem("honbuid");
    const body = {
      honbuid: honbuid,
      hpcodes: joinhps,
      kbn: joinkbn,
      searchText: searchText,
      //member: joinmember,
    };
    //console.log("test", body);
    const result = await custom_axios_post(API_URL + "/GetRooms", body);
    const status = result.status;
    if (status === 401) {
      history.replace("/loginError");
    }
    if (status === 200) {
      setLoading(false);
      setRoomsList(result.data.rooms);
    }
  };

  useEffect(() => {
    if (open) {
      initproc();
    }
  }, [open]);

  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangeUsed = async (roomid) => {
    const result = await custom_axios(API_URL + `/changeRoomUsed?roomid=${roomid}`);
    const status = result.status;
    /* 部屋の状態更新 */
    hubConnection.invoke("RoomUpdate", roomid, state.honbuid, "", "", "information");
    if (status === 401) {
      history.replace("/loginError");
    }
  };
  const handleChangeDirection = async (roomid, value) => {
    const result = await custom_axios(API_URL + `/changeDirection?roomid=${roomid}&value=${value}`);
    const status = result.status;
    /* 部屋の状態更新 */
    hubConnection.invoke("RoomUpdate", roomid, state.honbuid, "", "", "information");
    if (status === 401) {
      history.replace("/loginError");
    }
  };
  const [openMember, setOpenMember] = useState(false);
  const [roomid, setRoomid] = useState("");
  const handleMemberClose = () => {
    setOpenMember(false);
  };
  const preventDefault = (event, roomid) => {
    event.preventDefault();
    setRoomid(roomid);
    setOpenMember(true);
  };

  const [openModal, setOpenModal] = useState(false);
  const handleLeaveMemberConfirm = () => {
    setOpenModal(false);
  };
  const [leaveId, setLeaveId] = useState([]);
  const [leaveName, setLeaveName] = useState([]);
  const [leavedId, setLeavedId] = useState([]);
  const [addmember, setAddMember] = useState(0);
  const MemoRoomMember = useMemo(() => {
    return (
      <ShowRoomMember
        open={openMember}
        handleClose={handleMemberClose}
        roomid={roomid}
        setOpenModal={setOpenModal}
        setLeaveId={setLeaveId}
        setLeaveName={setLeaveName}
        leavedId={leavedId}
        setOpenFaAdd={setOpenFaAdd}
        addmember={addmember}
      />
    );
  }, [openMember, roomid, leavedId, addmember]);

  const LeaveMemberConfirm = useMemo(() => {
    return (
      <ShowCommon
        open={openModal}
        title="退室させますか？"
        handleClose={handleLeaveMemberConfirm}
        handleCallBack={async () => {
          /* 削除処理 */
          var resturl = `/delUserFromRoom?roomid=${roomid}&honbuid=${leaveId}`;
          const result = await custom_axios(API_URL + resturl);
          const status = result.status;
          if (status === 401) {
            history.replace("/loginError");
          }
          //console.log("createRoom", result);
          if (status === 200) {
            hubConnection.invoke(
              "SendMessage",
              roomid,
              leaveId,
              leaveName,
              leaveName + "が退室しました",
              "information"
            );
          }
          setLeavedId(leaveId);
          proc(selectedhp, selectedKbn, false);
          handleLeaveMemberConfirm();
        }}
      ></ShowCommon>
    );
  }, [openModal, leaveId, leaveName]);
  const MemoMember = useMemo(() => {
    return (
      <ShowMember
        open={openFa}
        handleClose={handleFaClose}
        isadduser={false}
        roomid=""
        iswithme={true}
        callback={afterSaveProc}
      />
    );
  }, [openFa]);
  const MemoAddMember = useMemo(() => {
    return (
      <ShowMember
        open={openFaAdd}
        handleClose={handleFaAddClose}
        isadduser={true}
        roomid={roomid}
        iswithme={true}
        callback={afterAddMemberProc}
      />
    );
  }, [openFaAdd]);

  const [searchText, setSearchText] = useState("");
  // useEffect(() => {
  //   if (searchText != "") {
  //     setMembersList(originalmembersList.filter((f) => String(f.name).toLowerCase().indexOf(searchText) !== -1));
  //   } else {
  //     setMembersList(originalmembersList);
  //   }
  // }, [searchText]);
  const handleChange = (e) => {
    setSearchText(e.target.value);
  };
  const handleKeyDown = (e) => {
    //e.preventDefault();
    if (e.which === 13 && !e.shiftKey) {
      e.preventDefault();
      if (searchText != "") {
        handleSearchClick();
      }
    }
  };
  const handleCloseClick = async () => {
    setSearchText("");
    proc(selectedhp, selectedKbn, false, "");
  };
  const handleSearchClick = async () => {
    proc(selectedhp, selectedKbn, false, searchText);
  };

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleClose}
      fullWidth={true}
      maxWidth={"xl"}
      TransitionComponent={Transition}
    >
      <StyledDialogTitle id="max-width-dialog-title">
        <div className="show-member-search">
          <div className="show-member-search">部屋一覧</div>
          <div className="question-title-actions">
            <StyledButton
              onClick={() => {
                handleFaClickOpen();
              }}
              //className={classes.textcolor}
              variant="outlined"
            >
              部屋作成
            </StyledButton>
            <div style={{ width: "20px" }}></div>
            <StyledButton onClick={handleClose} variant="outlined">
              閉じる (Esc)
            </StyledButton>
          </div>
        </div>
      </StyledDialogTitle>
      <StyledDialogContent>
        <StyledRoot>
          <div className="user-select-area">
            <div className="user-select-subarea">
              <div className="multi-select">
                <MultiSelect
                  options={options}
                  value={selectedhp}
                  onChange={(e) => {
                    setSelectedHp(e);
                    proc(e, selectedKbn, true, searchText);
                  }}
                  labelledBy={"Select"}
                  overrideStrings={{
                    selectSomeItems: "選択...",
                    allItemsAreSelected: "全選択",
                    selectAll: "全選択",
                    search: "検索",
                    clearSearch: "検索クリア",
                  }}
                  filterOptions={(options, filter) => {
                    if (!filter) {
                      return options;
                    }
                    const re = new RegExp(filter, "i");
                    return options.filter(({ label }) => label && label.match(re));
                  }}
                />
              </div>
              <div className="multi-select">
                <MultiSelect
                  options={kbnOptions}
                  value={selectedKbn}
                  onChange={(e) => {
                    setSelectedKbn(e);
                    proc(selectedhp, e, false, searchText);
                  }}
                  labelledBy={"Select"}
                  overrideStrings={{
                    selectSomeItems: "選択...",
                    allItemsAreSelected: "全選択",
                    selectAll: "全選択",
                    search: "検索",
                    clearSearch: "検索クリア",
                  }}
                  filterOptions={(options, filter) => {
                    if (!filter) {
                      return options;
                    }
                    const re = new RegExp(filter, "i");
                    return options.filter(({ label }) => label && label.match(re));
                  }}
                />
              </div>
              {/* <div className="multi-select">
                <MultiSelect
                  options={memberOptions}
                  value={selectedMember}
                  onChange={(e) => {
                    setSelectedMember(e);
                    proc(selectedhp, selectedKbn, e, false);
                  }}
                  labelledBy={"Select"}
                  overrideStrings={{
                    selectSomeItems: "選択...",
                    allItemsAreSelected: "全選択",
                    selectAll: "全選択",
                    search: "検索",
                    clearSearch: "検索クリア",
                  }}
                  filterOptions={(options, filter) => {
                    if (!filter) {
                      return options;
                    }
                    const re = new RegExp(filter, "i");
                    return options.filter(({ label }) => label && label.match(re));
                  }}
                />
              </div> */}
            </div>
            <div className="chat-search-admin">
              <div className="chat-search-icon">
                <IconButton color="primary" aria-label="send message" onClick={handleSearchClick}>
                  <Search />
                </IconButton>
              </div>
              <input
                placeholder="ユーザー検索"
                value={searchText}
                name="chatsFilter"
                onChange={handleChange}
                onKeyDown={handleKeyDown}
                className="chat-search-field"
              />
              <div className="chat-search-icon">
                <IconButton color="primary" aria-label="send message" onClick={handleCloseClick}>
                  <Close />
                </IconButton>
              </div>
            </div>
          </div>
          {loading ? (
            <div>
              <StyledPaper>
                <TableContainer>
                  <StyledTable
                    //className={classes.table}
                    aria-labelledby="tableTitle"
                    //size={dense ? "small" : "medium"}
                    size="small"
                    aria-label="enhanced table"
                  >
                    <LoadingTableHead />
                    <TableBody>
                      {[...Array(15)].map((_, i) => {
                        const skeletonWidth = 60;
                        return (
                          <TableRow hover role="checkbox" tabIndex={-1} key={i}>
                            {/* <TableCell padding="checkbox">
                          <Checkbox checked={isItemSelected} inputProps={{ "aria-labelledby": labelId }} />
                        </TableCell> */}
                            <TableCell component="th">
                              <Skeleton variant="rect" width={skeletonWidth} />
                            </TableCell>
                            <TableCell align="left">
                              <Skeleton variant="rect" width={skeletonWidth} />
                            </TableCell>
                            <TableCell align="left">
                              <Skeleton variant="rect" width={skeletonWidth} />
                            </TableCell>
                            <TableCell align="left">
                              <Skeleton variant="rect" width={skeletonWidth} />
                            </TableCell>
                            {/* <TableCell align="left">
                              <Skeleton variant="rect" width={skeletonWidth} />
                            </TableCell> */}
                            <TableCell align="left">
                              <Skeleton variant="rect" width={skeletonWidth} />
                            </TableCell>
                            <TableCell align="left">
                              <Skeleton variant="rect" width={skeletonWidth} />
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </StyledTable>
                </TableContainer>
              </StyledPaper>
            </div>
          ) : (
            <StyledPaper>
              <TableContainer>
                <StyledTable
                  //className={classes.table}
                  aria-labelledby="tableTitle"
                  //size={dense ? "small" : "medium"}
                  size="small"
                  aria-label="enhanced table"
                >
                  <EnhancedTableHead
                    //classes={classes}
                    //numSelected={selected.length}
                    order={order}
                    orderBy={orderBy}
                    //onSelectAllClick={handleSelectAllClick}
                    onRequestSort={handleRequestSort}
                    //rowCount={roomsList.length}
                  />
                  <TableBody>
                    {stableSort(roomsList, getComparator(order, orderBy)).map((row, index) => {
                      const labelId = `enhanced-table-checkbox-${index}`;

                      return (
                        <TableRow
                          hover
                          //onClick={(event) => handleClick(event, row.honbuid)}
                          role="checkbox"
                          tabIndex={-1}
                          key={row.roomid}
                        >
                          {/* <TableCell padding="checkbox">
                          <Checkbox checked={isItemSelected} inputProps={{ "aria-labelledby": labelId }} />
                        </TableCell> */}
                          <TableCell align="left">{row.roomid}</TableCell>
                          <TableCell component="th" id={labelId}>
                            <div className="member-content">
                              <img
                                src={STATIC_URL + `/downloadRoomAvatar?roomid=${row["roomid"]}&fileno=${row["fileno"]}`}
                                width="30"
                                height="30"
                                alt=""
                                className="member-image"
                                //style={isCurrent ? { border: "1px solid white" } : null}
                              />
                              <StyledFlex>
                                {row.isofficial == "1" && (
                                  <StyledChip
                                    variant="outlined"
                                    color="primary"
                                    size="small"
                                    label="人事連携"
                                  ></StyledChip>
                                )}
                                {row.roomname}
                              </StyledFlex>
                            </div>
                          </TableCell>

                          <TableCell align="left">
                            <Link href="#" onClick={(e) => preventDefault(e, row.roomid)}>
                              {row.ninzu}
                            </Link>
                          </TableCell>

                          {/* <TableCell align="left">{row.msgcnt}</TableCell> */}
                          <TableCell align="left">{row.yyyymmdd}</TableCell>
                          <TableCell align="left">
                            <ShowDirectionSelect
                              initvalue={row.isdirection}
                              callBack={handleChangeDirection}
                              roomid={row.roomid}
                            />
                          </TableCell>
                          <TableCell align="left">
                            <ShowRoomSwitch initvalue={row.isused} callBack={handleChangeUsed} roomid={row.roomid} />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </StyledTable>
              </TableContainer>
            </StyledPaper>
          )}
        </StyledRoot>
      </StyledDialogContent>
      {MemoRoomMember}
      {MemoMember}
      {LeaveMemberConfirm}
      {MemoAddMember}
    </Dialog>
  );
}

export default ShowRoom;
