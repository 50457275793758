import React, { useState, useContext, useEffect, useMemo } from "react";
import "./Main.css";
import { Store } from "../Store/store";
import { API_URL, LOGIN_URL, STATIC_URL, custom_axios } from "../Common/Common";
import ShowUser from "../Common/ShowUser";
import ShowRoom from "../Common/ShowRoom";
import ShowNoteMe from "../Common/ShowNoteMe";
import ShowKiyaku from "../Common/ShowKiyaku";
import Anpi from "../Anpi/Anpi";
import MainChat from "../Main/MainChat";
import { Route, Switch } from "react-router-dom";
import axios from "axios";
import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import NotificationsNoneIcon from "@material-ui/icons/NotificationsNone";
import MenuIcon from "@material-ui/icons/Menu";
import Toolbar from "@material-ui/core/Toolbar";
import ShowCommon from "../Common/ShowCommon";
import ShowPopup from "../Common/ShowPopup";
import ShowCommonDetailMessage from "../Common/ShowCommonDetailMessage";
import ShowAccount from "../Common/ShowAccount";
import ShowBellAdmin from "../Common/ShowBellAdmin";
import { ThemeProvider as ThemeProviderMu } from "@material-ui/styles";
import { ThemeProvider as ThemeProviderSc } from "styled-components";
import { createTheme } from "@material-ui/core/styles";
import useReactRouter from "use-react-router";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import PersonIcon from "@material-ui/icons/Person";
import WebIcon from "@material-ui/icons/Web";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import FaceIcon from "@material-ui/icons/Face";
import NotificationImportantIcon from "@material-ui/icons/NotificationImportant";
import TextsmsIcon from "@material-ui/icons/Textsms";
import { Tooltip } from "@material-ui/core";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MenuBookIcon from "@material-ui/icons/MenuBook";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import Note from "@material-ui/icons/Note";
// eslint-disable-next-line no-unused-vars
import Timeline from "@material-ui/icons/Timeline";
import { messaging } from "../init-fcm";
import purple from "@material-ui/core/colors/purple";
import green from "@material-ui/core/colors/green";

import teal from "@material-ui/core/colors/teal";
import indigo from "@material-ui/core/colors/indigo";
import blue from "@material-ui/core/colors/blue";
import deepPurple from "@material-ui/core/colors/deepPurple";
import pink from "@material-ui/core/colors/pink";
import red from "@material-ui/core/colors/red";
import deepOrange from "@material-ui/core/colors/deepOrange";
import orange from "@material-ui/core/colors/orange";
import yellow from "@material-ui/core/colors/yellow";
import brown from "@material-ui/core/colors/brown";

import lightGreen from "@material-ui/core/colors/lightGreen";
import cyan from "@material-ui/core/colors/cyan";
import lightBlue from "@material-ui/core/colors/lightBlue";
import amber from "@material-ui/core/colors/amber";
import lime from "@material-ui/core/colors/lime";
import blueGrey from "@material-ui/core/colors/blueGrey";
import grey from "@material-ui/core/colors/grey";

import topimage from "../image/icon.png";
import ShowBell from "../Common/ShowBell";
import ShowNews from "../Common/ShowNews";
import ShowAnpiList from "../Common/ShowAnpiList";

import Badge from "@material-ui/core/Badge";
import styled from "styled-components";
import Snackbar from "@material-ui/core/Snackbar";

const StyledList = styled.div`
  width: 330;
`;
const StyledPaddingList = styled(List)`
  padding-top: 0;
`;
const StyledIconButton = styled.div`
  margin-right: 3px;
`;
const StyledTypography = styled(Typography)`
  flex-grow: 1;
  margin-left: 5;
`;

export const retColor = (colorstr) => {
  //console.log("colorstr", colorstr);
  if (colorstr == "indigo") {
    return indigo;
  } else if (colorstr == "green") {
    return green;
  } else if (colorstr == "teal") {
    return teal;
  } else if (colorstr == "blue") {
    return blue;
  } else if (colorstr == "purple") {
    return purple;
  } else if (colorstr == "deepPurple") {
    return deepPurple;
  } else if (colorstr == "pink") {
    return pink;
  } else if (colorstr == "red") {
    return red;
  } else if (colorstr == "deepOrange") {
    return deepOrange;
  } else if (colorstr == "orange") {
    return orange;
  } else if (colorstr == "yellow") {
    return yellow;
  } else if (colorstr == "brown") {
    return brown;
  } else if (colorstr == "lightGreen") {
    return lightGreen;
  } else if (colorstr == "cyan") {
    return cyan;
  } else if (colorstr == "lightBlue") {
    return lightBlue;
  } else if (colorstr == "amber") {
    return amber;
  } else if (colorstr == "lime") {
    return lime;
  } else if (colorstr == "blueGrey") {
    return blueGrey;
  } else if (colorstr == "grey") {
    return grey;
  }
};

function Main() {
  const { history } = useReactRouter();

  const { state, dispatch, hubConnection, hubFunction } = useContext(Store);
  const honbuid = localStorage.getItem("honbuid");

  // ユーザーメニュー関連
  const [userMenu, setUserMenu] = React.useState(null);
  const openUserMenu = Boolean(userMenu);
  const handleUserMenuClick = (event) => {
    setUserMenu(event.currentTarget);
  };
  const handleUserMenuClose = () => {
    setUserMenu(null);
  };

  /* ブラウザバック制御 PR:120に詳細記載*/

  // 戻るコマンドのイベントリスナ
  useEffect(() => {
    window.history.pushState("inicialblank", null, null);
    window.addEventListener("popstate", overridePopstate, false);
    return () => window.removeEventListener("popstate", overridePopstate, false);
  }, []);

  // 戻るを禁止
  const overridePopstate = async () => {
    window.history.pushState("addblank", null, null);
    setOpenBrowserBackModal(true);
  };

  // 戻るボタンやwindow.history.backのときに発火
  window.onpopstate = (e) => {
    if (e.state === "inicialblank" || e.state === "addblank") {
      window.history.back();
    }
  };

  useEffect(() => {
    const proc = async () => {
      if (!messaging) return;
      // permission要求
      await messaging.requestPermission();

      // tokenを取得する
      const token = await messaging.getToken();
      // stateに保持
      updateToken(token);

      // tokenは定期的に更新される
      // tokenが更新されたらstateに反映する
      messaging.onTokenRefresh(() => {
        messaging
          .getToken()
          .then((token) => {
            // stateのtokenを更新
            updateToken(token);
          })
          .catch((err) => {
            console.error(err);
          });
      });

      // ブラウザがフォアグラウンド時に通知を受け取った場合は
      // onMessageイベントが発火する
      /*messaging.onMessage((payload) => {
        console.log(payload);
      });*/
    };
    proc();
  }, []);

  const updateToken = async (token) => {
    const result = await custom_axios(API_URL + `/updateWebToken?honbuid=${honbuid}&token=${token}`);
    const status = result.status;
    if (status === 401) {
      history.replace("/loginError");
    }
  };

  const updateNotice = async () => {
    //console.log("notice isnotice", state.isnotice);
    if (state.isnotice == "1") {
      const result = await custom_axios(API_URL + `/updateNotice?honbuid=${honbuid}`);
      //console.log("notice", result);
      const status = result.status;
      if (status === 401) {
        history.replace("/loginError");
      }
      dispatch({ type: "CHANGE_NOTICE", payload: "0" });
    }
  };

  const addChatConnection = (roomid) => {
    hubConnection.invoke("addGroup", roomid);
    //hubConnection.on(`ReceiveMessage${roomid}`, hubFunction);
  };

  const delChatConnection = (roomid) => {
    hubConnection.invoke("removeGroup", roomid);
    //hubConnection.off(`ReceiveMessage${roomid}`);
  };

  function focus_proc() {
    dispatch({ type: "ACTIVE", payload: true });

    /* アクティブになったら、既読数を最新にする（ブラウザは既読が取得失敗する場合があるので） */
    const getTimeOfRoom = async () => {
      const result = await custom_axios(API_URL + `/getTimeOfRoom?roomid=${localStorage.getItem("currentRoomid")}`);
      const status = result.status;
      if (status === 401) {
        history.replace("/loginError");
      }
      if (status === 200) {
        dispatch({ type: "UPDATE_READMESSAGE", payload: { ...result.data } });
      }
    };
    if (localStorage.getItem("currentRoomid") != "") {
      getTimeOfRoom();
    }
  }

  function blur_proc() {
    dispatch({ type: "INACTIVE", payload: false });
  }

  useEffect(() => {
    const fetchAddData = async (roomid) => {
      const httpstring =
        roomid !== ""
          ? `/getConversationList?honbuid=${honbuid}`
          : `/getConversationListOne?honbuid=${honbuid}&roomid=${roomid}`;
      const result = await custom_axios(API_URL + httpstring);
      const status = result.status;
      if (status === 401) {
        history.replace("/loginError");
      }
      //console.log("get結果", result);
      if (status === 200) {
        return result.data;
      }
    };

    const fetchData = async () => {
      let honbuid = localStorage.getItem("honbuid");
      const result = await custom_axios(API_URL + `/getConversationList?honbuid=${honbuid}`);
      const status = result.status;
      if (status === 401) {
        history.replace("/loginError");
      }
      if (status === 200) {
        dispatch({ type: "INIT_CONVERSASTION", payload: { ...result.data, honbuid: honbuid } });
      }
      //console.log("result.data.chatrooms", result.data.chatrooms);

      hubConnection.off(`ReceiveMessage`);
      hubConnection.on(`ReceiveMessage`, hubFunction);

      //for (let i = 0; i < result.data.chatrooms.length; i++) {
      // delChatConnection(result.data.chatrooms[i].roomid);
      // addChatConnection(result.data.chatrooms[i].roomid);
      //}
    };

    fetchData();

    /* とりあえず解除しとく */
    hubConnection.off(`ReceiveLogin${honbuid}`);

    hubConnection.on(`ReceiveInformation${honbuid}`, (type, roomid) => {
      if (type === "add") {
        /* 部屋が追加されたので、部屋受信窓口を設置 */
        addChatConnection(roomid);
        fetchAddData(roomid).then((r) => {
          dispatch({ type: "UPDATE_CONVERSASTION", payload: r });
        });
      } else if (type === "del") {
        /* 部屋を退室したので、部屋受信窓口を削除 */
        delChatConnection(roomid);
        // 部屋削除
        dispatch({ type: "DEL_ROOM", payload: roomid });

        //notifyListeners();
      }
    });

    window.addEventListener("blur", blur_proc);
    window.addEventListener("focus", focus_proc);

    return () => {
      hubConnection.off(`ReceiveInformation${honbuid}`);
      hubConnection.off(`ReceiveMessage`);
      window.removeEventListener("blur", blur_proc);
      window.removeEventListener("focus", focus_proc);
    };
  }, []);
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const [openmyroom, setOpenMyRoom] = useState(false);
  const [openbell, setOpenBell] = useState(false);
  const handleBellClose = () => {
    updateNotice();
    setOpenBell(false);
  };
  const [opennews, setOpenNews] = useState(false);
  const handleNewsClose = () => {
    setOpenNews(false);
  };
  const [openBrowserBackModal, setOpenBrowserBackModal] = useState(false);
  const handleBrowserBackModalClose = () => {
    setOpenBrowserBackModal(false);
  };
  const handleBrowserBack = () => {
    window.history.back();
    window.history.back();
  };
  // eslint-disable-next-line no-unused-vars
  const clickMyRoom = () => {
    if (state.chatrooms.filter((f) => f.ismyroom == "1").length > 0) {
      var toroomid = state.chatrooms.filter((f) => f.ismyroom == "1")[0].roomid;
      dispatch({ type: "CHANGE_ROOM", payload: { to: toroomid, from: state.currentRoomid } });
      localStorage.setItem("currentRoomid", toroomid);
    } else {
      setOpenMyRoom(true);
    }
  };
  const handleLogoff = async () => {
    //console.log("hubConnection", hubConnection);

    hubConnection.methods = [];
    /* for (let i = 0; i < Object.keys(hubConnection.methods).length; i++) {
      if (Object.keys(hubConnection.methods)[i]) {
        hubConnection.off(Object.keys(hubConnection.methods)[i]);
        console.log("hubConnection", Object.keys(hubConnection.methods)[i]);
      }
    } */
    //console.log("hubConnection", hubConnection);

    let honbuid = localStorage.getItem("honbuid");
    await axios(LOGIN_URL + `/deleteWebToken?honbuid=${honbuid}`);

    dispatch({ type: "LOGOUT" });
    localStorage.removeItem("login");
    history.replace("/login");
  };

  const [drawerOpen, setDrawerOpen] = useState(false);
  const toggleDrawer = (open) => (event) => {
    if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
      return;
    }

    setDrawerOpen(open);
  };

  const [noteOpen, setNoteOpen] = useState(false);
  const handleNoteClose = () => {
    setNoteOpen(false);
  };

  const [userOpen, setUserOpen] = useState(false);
  const handleUserClose = () => {
    setUserOpen(false);
  };

  const [anpiOpen, setAnpiOpen] = useState(false);
  const handleAnpiClose = () => {
    setAnpiOpen(false);
  };

  const [bellAdminOpen, setBellAdminOpen] = useState(false);
  const handleBellAdminClose = () => {
    setBellAdminOpen(false);
  };

  const [roomOpen, setRoomOpen] = useState(false);
  const handleRoomClose = () => {
    setRoomOpen(false);
  };

  const [accountOpen, setAccountOpen] = useState(false);
  const handleAccountClose = () => {
    setAccountOpen(false);
  };

  const [kiyakuOpen, setKiyakuOpen] = useState(false);
  const handleKiyakuClose = () => {
    setKiyakuOpen(false);
  };

  const [ispopup, setIsPopUp] = useState(false);
  const handlePopupClose = () => {
    setIsPopUp(false);
  };
  // const gotoOT = () => {
  //   window.open(`https://www.notion.so/Chatis-09acefc80913485ea7e425fed226b9ec`, "_blank");
  // };

  ////console.log("Main.js", state);

  const theme = useMemo(() => {
    return createTheme({
      palette: {
        primary: retColor(state.color),
      },
    });
  }, [state.color]);

  useEffect(() => {
    if (state.ispopup == "1" && ispopup == false) setIsPopUp(true);
  }, [state.ispopup]);

  const MemoShowPopup = useMemo(() => {
    return <ShowPopup open={ispopup} honbuid={state.honbuid} handleClose={handlePopupClose}></ShowPopup>;
  }, [ispopup]);

  const MemoShowCommon = useMemo(() => {
    return (
      <ShowCommon
        open={open}
        title="ログオフしますか？"
        handleClose={handleClose}
        handleCallBack={handleLogoff}
      ></ShowCommon>
    );
  }, [open]);
  const MemoShowCommonDetailMessage = useMemo(() => {
    return (
      <ShowCommonDetailMessage
        open={openBrowserBackModal}
        title="前のページに戻りますか？"
        detailMessage="Chatisでブラウザの「戻る」は推奨されていません。@「進む」で帰ってくると動作がおかしくなります。"
        handleClose={handleBrowserBackModalClose}
        handleCallBack={handleBrowserBack}
      ></ShowCommonDetailMessage>
    );
  }, [openBrowserBackModal]);
  const MemoShowOpenMyRoom = useMemo(() => {
    return (
      <Snackbar
        open={openmyroom}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        autoHideDuration={6000}
        onClose={() => {
          setOpenMyRoom(false);
        }}
        message="マイルームがありません。"
      ></Snackbar>
    );
  }, [openmyroom]);

  const MemoShowKiyaku = useMemo(() => {
    return <ShowKiyaku open={kiyakuOpen} handleClose={handleKiyakuClose}></ShowKiyaku>;
  }, [kiyakuOpen]);
  const MemoShowBellAdmin = useMemo(() => {
    return <ShowBellAdmin open={bellAdminOpen} handleClose={handleBellAdminClose}></ShowBellAdmin>;
  }, [bellAdminOpen]);
  const MemoShowAccount = useMemo(() => {
    return <ShowAccount open={accountOpen} handleClose={handleAccountClose}></ShowAccount>;
  }, [accountOpen]);
  const MemoShowUser = useMemo(() => {
    return <ShowUser open={userOpen} handleClose={handleUserClose}></ShowUser>;
  }, [userOpen]);
  const MemoShowRoom = useMemo(() => {
    return <ShowRoom open={roomOpen} handleClose={handleRoomClose}></ShowRoom>;
  }, [roomOpen]);
  const MemoShowBell = useMemo(() => {
    return <ShowBell open={openbell} handleClose={handleBellClose}></ShowBell>;
  }, [openbell]);
  const MemoShowAnpiList = useMemo(() => {
    return <ShowAnpiList open={anpiOpen} handleClose={handleAnpiClose} createOk="true"></ShowAnpiList>;
  }, [anpiOpen]);
  const MemoShowNews = useMemo(() => {
    return <ShowNews open={opennews} handleClose={handleNewsClose} hpcode={state.hpcode}></ShowNews>;
  }, [opennews]);
  const MemoShowNoteMe = useMemo(() => {
    return <ShowNoteMe open={noteOpen} handleClose={handleNoteClose}></ShowNoteMe>;
  }, [noteOpen]);

  return (
    <ThemeProviderMu theme={theme}>
      <ThemeProviderSc theme={theme}>
        <CssBaseline />
        <AppBar position="relative">
          <Toolbar>
            {/* <IconButton
            onClick={toggleDrawer(true)}
            edge="start"

            color="inherit"
            aria-label="menu"
          > */}

            <StyledIconButton>
              <IconButton onClick={toggleDrawer(true)} edge="start" color="inherit" aria-label="menu">
                <MenuIcon />
              </IconButton>
            </StyledIconButton>
            {/* </IconButton> */}
            <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer(false)}>
              <StyledList
                //className={classes.list}
                role="presentation"
                onClick={toggleDrawer(false)}
                onKeyDown={toggleDrawer(false)}
              >
                <StyledPaddingList>
                  <div className="show-user-wrap">
                    <div className="show-user-image-back">
                      <img
                        src={STATIC_URL + `/downloadAvatar?honbuid=${state.honbuid}&fileno=${state.fileno}`}
                        alt=""
                        className="show-user-image-back-image"
                        //style={isCurrent ? { border: "1px solid white" } : null}
                      />
                    </div>

                    <div className="show-user-image-wraper">
                      <img
                        src={STATIC_URL + `/downloadAvatar?honbuid=${state.honbuid}&fileno=${state.fileno}`}
                        width="130"
                        height="130"
                        alt=""
                        className="show-user-image"
                        //style={isCurrent ? { border: "1px solid white" } : null}
                      />
                    </div>
                    <div className="show-user-information">{state.user_name}</div>
                  </div>

                  <ListItem button onClick={() => clickMyRoom()}>
                    <ListItemIcon>
                      <Timeline />
                    </ListItemIcon>
                    <ListItemText primary="マイルーム" />
                  </ListItem>
                  <Divider />
                  {["1", "2"].includes(state.isadmin) && (
                    <ListItem button onClick={() => setBellAdminOpen(true)}>
                      <ListItemIcon>
                        <NotificationImportantIcon />
                      </ListItemIcon>
                      <ListItemText primary="お知らせ管理" />
                    </ListItem>
                  )}
                  {["1", "2"].includes(state.isadmin) && <Divider />}

                  {(["1", "2"].includes(state.isadmin) || state.isanpi == "1") && (
                    <ListItem button onClick={() => setAnpiOpen(true)}>
                      <ListItemIcon>
                        <FaceIcon />
                      </ListItemIcon>
                      <ListItemText primary="安否確認" />
                    </ListItem>
                  )}
                  {(["1", "2"].includes(state.isadmin) || state.isanpi == "1") && <Divider />}

                  {["1", "2", "4", "5"].includes(state.isadmin) && (
                    <ListItem button onClick={() => setUserOpen(true)}>
                      <ListItemIcon>
                        <SupervisorAccountIcon />
                      </ListItemIcon>
                      <ListItemText primary="ユーザー一覧" />
                    </ListItem>
                  )}
                  {["1", "2", "4", "5"].includes(state.isadmin) && <Divider />}
                  {["1", "2", "3", "5", "6"].includes(state.isadmin) && (
                    <ListItem button onClick={() => setRoomOpen(true)}>
                      <ListItemIcon>
                        <TextsmsIcon />
                      </ListItemIcon>
                      <ListItemText primary="部屋一覧" />
                    </ListItem>
                  )}
                  {["1", "2", "3", "5", "6"].includes(state.isadmin) && <Divider />}
                  <ListItem button onClick={() => setAccountOpen(true)}>
                    <ListItemIcon>
                      <PersonIcon />
                    </ListItemIcon>
                    <ListItemText primary="プロフィール変更" />
                  </ListItem>
                  <Divider />
                  <ListItem button onClick={() => setKiyakuOpen(true)}>
                    <ListItemIcon>
                      <WebIcon />
                    </ListItemIcon>
                    <ListItemText primary="利用規約" />
                  </ListItem>
                  <Divider />
                  <ListItem button onClick={() => window.open("https://chat-tis.github.io/mkdocs/", "_blank")}>
                    <ListItemIcon>
                      <MenuBookIcon />
                    </ListItemIcon>
                    <ListItemText primary="マニュアル" />
                  </ListItem>
                  <Divider />

                  <ListItem button onClick={() => setOpen(true)}>
                    <ListItemIcon>
                      <ExitToAppIcon />
                    </ListItemIcon>
                    <ListItemText primary="ログオフ" />
                  </ListItem>
                  <Divider />
                </StyledPaddingList>
              </StyledList>
            </Drawer>
            <img src={topimage} height="30" alt="アイコン" />
            <StyledTypography variant="h6">Chatis(4.2)</StyledTypography>
            <Tooltip title="個人ノート">
              <div onClick={() => setNoteOpen(true)} className="logoff-button">
                <Note />
              </div>
            </Tooltip>
            <Tooltip title="お知らせ">
              <div
                onClick={() => {
                  setOpenBell(true);
                }}
                className="logoff-button"
              >
                <Badge
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  variant="dot"
                  color="secondary"
                  badgeContent=""
                  invisible={state.isnotice == "0"}
                >
                  <NotificationsNoneIcon></NotificationsNoneIcon>
                </Badge>
              </div>
            </Tooltip>

            <div
              id="basic-button"
              aria-controls="basic-menu"
              aria-haspopup="true"
              aria-expanded={openUserMenu ? "true" : undefined}
              className="member-content"
              onClick={handleUserMenuClick}
            >
              {state.honbuid && (
                <img
                  src={STATIC_URL + `/downloadAvatar?honbuid=${state.honbuid}&fileno=${state.fileno}&size=small`}
                  width="45"
                  height="45"
                  alt=""
                  className="member-image"
                  //style={isCurrent ? { border: "1px solid white" } : null}
                />
              )}
              <div className="member-text-wrapper">
                <div className="member-name-wrapper">
                  <div className="">{state.user_name}</div>
                </div>
              </div>
            </div>
            <Menu id="basic-menu" anchorEl={userMenu} open={openUserMenu} onClose={handleUserMenuClose}>
              <MenuItem
                onClick={() => {
                  handleUserMenuClose();
                  setAccountOpen(true);
                }}
              >
                <ListItemIcon>
                  <PersonIcon />
                </ListItemIcon>
                プロフィール変更
              </MenuItem>
              <MenuItem
                divider
                onClick={() => {
                  handleUserMenuClose();
                  setNoteOpen(true);
                }}
              >
                <ListItemIcon>
                  <Note />
                </ListItemIcon>
                個人ノート
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleUserMenuClose();
                  setOpen(true);
                }}
              >
                <ListItemIcon>
                  <ExitToAppIcon />
                </ListItemIcon>
                ログオフ
              </MenuItem>
            </Menu>

            {MemoShowCommon}
            {MemoShowCommonDetailMessage}
            {MemoShowOpenMyRoom}
            {MemoShowKiyaku}
            {MemoShowAccount}
            {MemoShowBellAdmin}
            {MemoShowUser}
            {MemoShowRoom}
            {MemoShowBell}
            {MemoShowAnpiList}
            {MemoShowNews}
            {MemoShowNoteMe}
            {MemoShowPopup}
          </Toolbar>
        </AppBar>
        <Switch>
          {/* <Route path="/user" component={User}></Route> */}
          <Route path="/anpi" component={Anpi}></Route>
          <Route path="/" component={MainChat}></Route>
        </Switch>
        {/* {adminOpen ? <User /> : <MainChat />} */}
      </ThemeProviderSc>
    </ThemeProviderMu>
  );
}

export default Main;
