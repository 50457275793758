import axios from "axios";
import DateFnsUtils from "@date-io/date-fns";
import format from "date-fns/format";

//ViewAir
//const MAIN_URL = "https://chatis.info";
//GCP
const MAIN_URL = "https://chat-tis.com";

//const MAIN_URL = "https://localhost:44311";

export const API_URL = MAIN_URL + "/Home";
export const LOGIN_URL = MAIN_URL + "/Login";
export const STATIC_URL = MAIN_URL + "/Static";
export const TOKEN_URL = MAIN_URL + "/AccessToken";
export const HUB_URL = MAIN_URL + "/chatHub";
export const STAMP_URL = "https://storage.googleapis.com/chatis_bucket";
export const APP_ID = "bdaecca703874552900f75c30ab071e0";

export const SALARY_URL = "https://tk-salary.azurewebsites.net";
export const USER_SELECT_OPTION = ["WEB給与", "PHR", "ログイン可", "VIP", "安否", "日報"];
export const USER_BUTTON_OPTION = [
  "パスワード初期化",
  "QRコード印刷",
  "ロック解除",
  "SMS認証解除",
  "引換コード変更",
  "名前変更",
  "退職",
];
export const ADMIN_BUTTON_OPTION = ["QRコード印刷プレビュー", "ロック解除"];
export const USER_BELL_BUTTON_OPTION = ["参照", "削除"];

export class ExtendDateFnsUtils extends DateFnsUtils {
  getCalendarHeaderText(date) {
    return format(date, "yyyy MMM", { locale: this.locale });
  }

  getDatePickerHeaderText(date) {
    return format(date, "MMMd日", { locale: this.locale });
  }
}
export const custom_axios = async (urlstr) => {
  try {
    const result = await axios(urlstr, {
      headers: {
        honbuid: localStorage.getItem("honbuid"),
        apikey: localStorage.getItem("apikey"),
      },
    });
    return result;
  } catch (error) {
    const { status } = error.response;

    return { data: [], status: status };
  }
};

export const custom_axios_flutter2react = async (urlstr, honbuid, apikey) => {
  try {
    const result = await axios(urlstr, {
      headers: {
        honbuid: honbuid,
        apikey: apikey,
      },
    });
    return result;
  } catch (error) {
    const { status } = error.response;

    return { data: [], status: status };
  }
};

export const custom_axios_post = async (urlstr, body, headers = "") => {
  try {
    if (headers == "") {
      headers = {
        headers: {
          honbuid: localStorage.getItem("honbuid"),
          apikey: localStorage.getItem("apikey"),
        },
      };
    } else {
      headers.headers.honbuid = localStorage.getItem("honbuid");
      headers.headers.apikey = localStorage.getItem("apikey");
    }
    //console.log("headers", headers);
    const result = await axios.post(urlstr, body, headers);
    return result;
  } catch (error) {
    const { status } = error.response;

    return { data: [], status: status };
  }
};

export const custom_axios_post_flutter2react = async (urlstr, body, headers) => {
  try {
    //console.log("headers", headers);
    const result = await axios.post(urlstr, body, headers);
    return result;
  } catch (error) {
    const { status } = error.response;

    return { data: [], status: status };
  }
};

export const unescapeHTML = (str) => {
  return str
    .replace(/&lt;/g, "<")
    .replace(/&gt;/g, ">")
    .replace(/&amp;/g, "&")
    .replace(/&nbsp;/g, "");
};
