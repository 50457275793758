/* eslint-disable react/prop-types */
import React, { useState, useEffect, useMemo } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
// import ShowAnpiUserViewRow from "../Common/ShowAnpiUserViewRow";
import styled from "styled-components";
import { API_URL, custom_axios } from "../Common/Common";
import Button from "@material-ui/core/Button";
import Loader from "react-loader-spinner";
// import ShowCommon from "../Common/ShowCommon";
import Typography from "@material-ui/core/Typography";
import { PieChart, Pie, Cell, Tooltip } from "recharts";
import purple from "@material-ui/core/colors/purple";
import green from "@material-ui/core/colors/green";
import teal from "@material-ui/core/colors/teal";
import indigo from "@material-ui/core/colors/indigo";
import blue from "@material-ui/core/colors/blue";
import deepPurple from "@material-ui/core/colors/deepPurple";
import pink from "@material-ui/core/colors/pink";
import red from "@material-ui/core/colors/red";
import deepOrange from "@material-ui/core/colors/deepOrange";
import orange from "@material-ui/core/colors/orange";
import ShowAnpiUserView from "../Common/ShowAnpiUserView";

const StyledDialogTitle = styled(DialogTitle)`
  color: ${(props) => props.theme.palette.primary.contrastText};
  background-color: ${(props) => props.theme.palette.primary.main};
  padding: 5px 10px;
`;
const StyledDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
const StyledChartMain = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const StyledCenter = styled.div`
  text-align: center;
`;
const TopDiv = styled.div`
  margin-top: 5px;
`;
const StyledChartSub = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

// eslint-disable-next-line
function ShowAnpiChart({ openChartObj, handleClose}) {
  const [votesumarry, setVoteSumarry] = useState([]);
  const [textsumarry, setTextSumarry] = useState([]);
  const [textgroup, setTextGroup] = useState([]);
  const [isload, setIsLoad] = useState(false);
  const mainproc = async () => {
    setIsLoad(true);
    var apistring = "";
    if (openChartObj.kbn == "hp") {
      apistring =
        API_URL +
        `/GetAllAnpiChart?anpiid=${openChartObj.anpiid}&hpcode=${openChartObj.hpcode}&vote=${openChartObj.vote}`;
    } else {
      apistring =
        API_URL +
        `/GetAnpiChart?anpiid=${openChartObj.anpiid}&hpcode=${openChartObj.hpcode}&unitcode=${openChartObj.unitcode}&vote=${openChartObj.vote}`;
    }

    const result = await custom_axios(apistring);
    const status = result.status;
    // if (status === 401) {
    //   history.replace("/loginError");
    // }
    if (status === 200) {
      setVoteSumarry(result.data.votesumarry);
      setTextSumarry(result.data.textsumarry);
      var textgroup = result.data.textsumarry.reduce(function (result, current) {
        var element = result.find(function (p) {
          return p.seq === current.seq;
        });
        if (element) {
          element.count++;
        } else {
          result.push({
            seq: current.seq,
            title: current.title,
            count: 1,
          });
        }
        return result;
      }, []);
      setTextGroup(textgroup);
    }
    setIsLoad(false);
  };

  useEffect(() => {
    if (openChartObj.isopen) {
      mainproc();
    } else {
      setVoteSumarry([]);
      setTextSumarry([]);
    }
  }, [openChartObj.isopen]);

  // const [open, setOpen] = useState(false);
  // const handleOpenClose = () => {
  //   setOpen(false);
  // };

  const TOPCOLORS = [blue[300], pink[300]];
  const COLORS = [
    indigo[300],
    green[300],
    teal[300],
    blue[300],
    deepPurple[300],
    pink[300],
    red[300],
    purple[300],
    deepOrange[300],
    orange[300],
    // yellow[300],
    // brown[300],
    // lightGreen[300],
    // cyan[300],
    // lightBlue[300],
    // amber[300],
    // lime[300],
    // blueGrey[300],
    // grey[300],
  ];
  const onPieMainClick = (e) => {
    console.log(e);
    setOpenViewObj({
      kbn: openChartObj.kbn,
      hpcode: openChartObj.hpcode,
      anpiid: openChartObj.anpiid,
      unitcode: openChartObj.unitcode,
      isopen: true,
      vote: e.voteno,
      seq: "",
      no: "",
      name: e.name,
    });
  };
  const onPieClick = (e) => {
    console.log(e);
    setOpenViewObj({
      kbn: openChartObj.kbn,
      hpcode: openChartObj.hpcode,
      anpiid: openChartObj.anpiid,
      unitcode: openChartObj.unitcode,
      isopen: true,
      vote: "",
      seq: e.seq,
      no: e.no,
      name: e.name,
    });
  };
  const [openViewObj, setOpenViewObj] = React.useState({
    anpiid: 0,
    unitcode: "",
    isopen: false,
    vote: "",
    seq: "",
    no: "",
  });
  const handleAnpiUserViewClose = () => {
    setOpenViewObj({ ...openViewObj, isopen: false });
  };
  const MemoAnpiUserView = useMemo(() => {
    return <ShowAnpiUserView openViewObj={openViewObj} handleClose={handleAnpiUserViewClose} />;
  }, [openViewObj]);
  return (
    <Dialog open={openChartObj.isopen} onClose={handleClose} maxWidth={"xl"} fullWidth>
      <StyledDialogTitle id="max-width-dialog-title">
        <div className="show-member-search">
          <div>{openChartObj.title}</div>
          {/* <StyledButton
            onClick={() => {
              setOpen(true);
            }}
            variant="outlined"
          >
            再通知
          </StyledButton> */}
        </div>
      </StyledDialogTitle>

      <DialogContent>
        <div>
          {MemoAnpiUserView}
          {isload == false ? (
            <StyledChartMain>
              <StyledCenter>
                <TopDiv>
                  <Typography variant="h5">
                    {"報告状況 (" +
                      votesumarry.reduce(function (sum, element) {
                        return sum + element.cnt;
                      }, 0) +
                      "人)"}
                  </Typography>
                </TopDiv>
                <PieChart width={600} height={300}>
                  <Tooltip></Tooltip>
                  <Pie
                    data={votesumarry}
                    startAngle={-270}
                    isAnimationActive={false}
                    onClick={onPieMainClick}
                    dataKey="cnt"
                    nameKey="vote"
                    cx="50%"
                    cy="50%"
                    outerRadius={120}
                    label={({ vote, cnt }) => `${vote}:${cnt}人`}
                  >
                    {votesumarry.map((entry, index) => (
                      <Cell cursor="pointer" key={index} fill={entry.vote == "済" ? TOPCOLORS[0] : TOPCOLORS[1]} />
                    ))}
                  </Pie>
                </PieChart>
              </StyledCenter>
              <StyledChartSub>
                {textgroup.map((e) => {
                  var textfilter = textsumarry.filter((f) => e.seq == f.seq);
                  return (
                    <StyledCenter key={e.seq}>
                      <div>
                        {e.title +
                          " (" +
                          textfilter.reduce(function (sum, element) {
                            return sum + element.cnt;
                          }, 0) +
                          "人)"}
                      </div>
                      <PieChart width={300} height={200}>
                        <Tooltip></Tooltip>
                        <Pie
                          data={textfilter}
                          startAngle={-270}
                          isAnimationActive={false}
                          onClick={onPieClick}
                          dataKey="cnt"
                          nameKey="text"
                          cx="50%"
                          cy="50%"
                          outerRadius={50}
                          label={({ text, cnt }) => `${text}:${cnt}人`}
                        >
                          {textfilter.map((entry, index) => (
                            <Cell cursor="pointer" key={index} fill={COLORS[index % COLORS.length]} />
                          ))}
                        </Pie>
                      </PieChart>
                    </StyledCenter>
                  );
                })}
              </StyledChartSub>
            </StyledChartMain>
          ) : (
            <StyledDiv>
              <Loader type="ThreeDots" height="100" width="100" />
            </StyledDiv>
          )}
        </div>
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose} variant="outlined" color="secondary">
          閉じる (Esc)
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ShowAnpiChart;
