import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { registerServiceWorker } from "./register-sw";

var userAgent = window.navigator.userAgent.toLowerCase();

if (userAgent.indexOf("msie") != -1) {
  console.log("ブラウザはInternet Explorerです");
  //unregisterServiceWorker();
  registerServiceWorker();
} else if (userAgent.indexOf("edge") != -1) {
  console.log("ブラウザはEdgeです");
  //unregisterServiceWorker();
  registerServiceWorker();
} else if (userAgent.indexOf("chrome") != -1) {
  console.log("ブラウザはChromeです");
  //unregisterServiceWorker();
  registerServiceWorker();
} else if (userAgent.indexOf("safari") != -1) {
  console.log("ブラウザはSafariです");
} else if (userAgent.indexOf("firefox") != -1) {
  console.log("ブラウザはFireFoxです");
  //unregisterServiceWorker();
  registerServiceWorker();
}

ReactDOM.render(
  // <React.StrictMode>
  <App />,
  // </React.StrictMode>,
  document.getElementById("root")
);
